import React, { Component } from 'react';
import { Card, CardHeader, Container, Row,  Button, Table,
    DropdownMenu,DropdownItem, UncontrolledDropdown, DropdownToggle,
    Modal, ModalBody, ModalHeader, ModalFooter, 
    Form, Label, FormGroup, Input, Col
} from "reactstrap";
import AdminHeader from "../../Shared/Headers/AdminHeader";
import { CircleSpinner } from 'react-spinners-kit';
import './../../../assets/css/dashboard/dashboard.css';
import { getModels, createModels, updateModels } from '../../../api/models';
import { devicesTypes } from './../../../api/devices'

// TOAST
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

const toastOptions = {
    positionClass: 'toast-top-center',
    hideDuration: 300,
    timeOut: 3000,
    progressBar: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
}

class Models extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            submit: false,
            Models: [],

            DevicesType: [],

            item: null,

            modalNoData: false,
            modalNew: false,
            modalEdit:false,
            modalDelete: false,

            model: '',
            part_number: '',
            id_status: '',
            brand: '',
            id: '',
            id_device_type: 0
        };

        this.closeModal = this.closeModal.bind(this);
        
        this.addNewItem = this.addNewItem.bind(this);
        this.handleAddItem = this.handleAddItem.bind(this);
        this.submitAddItem = this.submitAddItem.bind(this);

        this.editItem = this.editItem.bind(this);
        this.submitUpdateData = this.submitUpdateData.bind(this);
        this.handleUpdateItem = this.handleUpdateItem.bind(this);

        this.deleteItem = this.deleteItem.bind(this);
        this.submitDeleteData = this.submitDeleteData.bind(this);
        this.deleteItemData = this.deleteItemData.bind(this);
    }

    async fetchData() {
        const resultDevicesType = await devicesTypes();
        if(resultDevicesType.response.status === 200) {
            this.setState({DevicesType: resultDevicesType.response.devices_type})
        }
        const result = await getModels();
        if(result.response.status === 500) {
            alert('Error en el servidor');
            this.setState({ Models: [], loading: false})
        }
        if(result.response.status === 404) {
            this.setState({ Models: result.response.Models, loading: false, modalNoData: true})
        }
        else {
            let modalNoData = false;
            let length = result.response.Models ? result.response.Models.length : 0;
            if(length === 0) modalNoData = true;
            this.setState({ Models: result.response.Models, loading: false, modalNoData: modalNoData})
        }        
    }
    
    componentDidMount() {
        this.fetchData();  
    }

    closeModal() {
        this.setState({
            modalNoData: false,
            modalNew: false,
            modalEdit:false,
            modalDelete: false,
            submit: false,

            item: null,

            model: '',
            part_number: '',
            brand: '',
            id: '',
            id_device_type: 0
        })
    }

    addNewItem() {
        this.setState({modalNew: true})
    }

    handleAddItem(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    submitAddItem(e) {
        e.preventDefault();
        this.setState({ submit: true})
        createModels({
            model: this.state.model, part_number: this.state.part_number, 
            id_device_type: this.state.id_device_type, brand: this.state.brand 
        })
        .then( res => {
            if(res.response.status !== 200) {
                alert(res.response.message);
            }
            else {
                this.NewItem(res.response.model);
                this.toastCreateItem(res.response.model.model);
                this.closeModal();
            }
        })
        .catch( err =>{
            alert(err)
        });
    }

    toastCreateItem(name) {
        toastr.options = toastOptions;
        toastr.clear()
        setTimeout(() => toastr.success(`Modelo ${name ? name :''} creado`), 300)
    }

    async NewItem(item) {
        if(!this.state.Models) this.state.Models = [];
        let newItem = this.appendObjTo(this.state.Models, item);
        this.setState({ Models: newItem })
    }


    editItem(item) {
        this.setState({
            modalEdit: true, id: item.id_model, 
            item,model: item.model, part_number: item.part_number,
            brand: item.brand, id_device_type: item.id_device_type
        })
    }

    handleUpdateItem(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    submitUpdateData(e) {
        e.preventDefault();
        this.setState({ submit: true})
        const { id, model, part_number, brand, id_device_type  } = this.state;   
        updateModels(id, { model, part_number, brand, id_device_type })
            .then( res => {
                if(res.response.status !== 200) alert(res.response.message);
                else {
                    this.updateItemData(res.response.model);
                    this.toastUpdateItem(res.response.model.model)
                    this.closeModal();
                }
            })
            .catch( err =>{
                alert(err)
            });
    }

    toastUpdateItem(name) {
        toastr.options = toastOptions;
        toastr.clear()
        setTimeout(() => toastr.success(`Modelo ${name ? name :''} actualizado`), 300)
    }

    async updateItemData(data) {
        var id = data.id_model;
        let { Models } = this.state; 
        try {
            let index = parseInt(Models.findIndex(item => item.id_model === id));
            if(index !== -1){
                Models[index].model = data.model;
                Models[index].part_number = data.part_number;
                Models[index].brand = data.brand;
                Models[index].id_device_type = data.id_device_type;
                Models[index].type = data.type;
                this.setState({ Models })
            }
            else this.setState({ Models })
        } catch (error) {
            window.location.reload();
        }
    }

    deleteItem(item) {
        this.setState({modalDelete: true, item})
    }

    submitDeleteData(e) {
        e.preventDefault();
        this.setState({ submit: true})
        const { item } = this.state;  
        let data = {
            id_status: 2
        }
        updateModels(item.id_model, data)
            .then( res => {
                if(res.response.status !== 200) {
                    alert(res.response.message);
                }
                else {
                    this.toastDeleteItem(res.response.model.model);
                    this.deleteItemData(res.response.model);
                    this.closeModal();
                }
            })
            .catch( err =>{
                alert(err)
            });
    }

    async deleteItemData(data) {
        var id = data.id_model;
        let { Models } = this.state;
        try {
            let index = Models.findIndex(fail => fail.id_model === id);
            if(index !== -1) Models.splice(index, 1);
            else this.setState({ Models })
        } catch (error) {
            window.location.reload();
        }
    }

    toastDeleteItem(name) {
        toastr.options = toastOptions;
        toastr.clear()
        setTimeout(() => toastr.success(`Modelo ${name ? name: ''} eliminado`), 300)
    }

    appendObjTo = (thatArray, newObj) => {
        const frozenObj = Object.freeze(newObj);
        return Object.freeze(thatArray.concat(frozenObj));
    }

    render() {
        const { Models, DevicesType } = this.state;
        return (
            <>
                <AdminHeader {...this.props}/>
                <Container className="mt--7" fluid> 
                    <div>
                        <Row>
                            <div className="col">
                                <Card className="shadow">
                                    <CardHeader className="border-0">
                                        <Row>
                                            <div className="col-6">
                                                <h3 className="mb-0 d-inline">Modelos</h3>
                                            </div>
                                            <div className="col-6">
                                                <Button type="button" onClick={() => this.addNewItem()} style={{ padding: '0.3rem 0.7rem' }} className="float-right btn btn-outline-success btn-circle">
                                                    <i className="fa fa-plus"></i>
                                                </Button>
                                            </div>
                                        </Row>
                                    </CardHeader>
                                    {
                                        this.state.loading ? 
                                        <div className='spinner-table'><CircleSpinner
                                                size={30}
                                                color="#11cdef"
                                                loading={this.state.loading}
                                            /></div>
                                        :
                                        <Table className="align-items-center table-flush" responsive>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th className='itemCenter' scope="col">Modelo</th>
                                                    <th className='itemCenter' scope="col">Marca</th>
                                                    <th className='itemCenter' scope="col">N&uacute;mero de parte</th>
                                                    <th className='itemCenter' scope="col">Tipo dispositivo</th>
                                                    <th scope="col" />
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    Models ? 
                                                    Models.map((item, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td className='itemCenter'>{item.model ? item.model: <p>-</p>}</td>
                                                                <td className='itemCenter'>{item.brand ? item.brand: <p>-</p>}</td>
                                                                <td className='itemCenter'>{item.part_number ? item.part_number: <p>-</p>}</td>
                                                                <td className='itemCenter'>{item.type ? item.type: <p>-</p>}</td>
                                                                <td className="text-right">
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle
                                                                            className="btn-icon-only text-light"
                                                                            href="#"
                                                                            role="button"
                                                                            size="sm"
                                                                            color=""
                                                                            onClick={e => e.preventDefault()}>
                                                                            <i className="fas fa-ellipsis-v" />
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu-arrow" right>
                                                                            <DropdownItem
                                                                                onClick={() => this.editItem(item)}
                                                                            >
                                                                                <i className="fas fa-edit" />
                                                                                Editar
                                                                            </DropdownItem>
                                                                            <DropdownItem
                                                                                onClick={() => this.deleteItem(item)}
                                                                            >
                                                                                <i className="fas fa-trash" />
                                                                                Eliminar
                                                                            </DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </Table>
                                    }
                                    </Card>
                                </div>
                            </Row>
                    </div>
                </Container>

                {/* MODAL NO DATA */}
                <div>
                <Modal isOpen={this.state.modalNoData} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>NO DATA</ModalHeader>
                    <ModalBody>
                        No hay modelos registrados
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                    </ModalFooter>
                </Modal>
                </div>

                {/* MODAL NEW */}
                <div>
                <Modal isOpen={this.state.modalNew} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>Agregar un nuevo modelo</ModalHeader>
                    <Form onSubmit={this.submitAddItem}>
                    <ModalBody>
                        <FormGroup row>
                            <Label for="modelo" sm={4}>Nombre modelo</Label>
                            <Col sm={8}>
                            <Input 
                                type="text"
                                name="model"
                                id="model" 
                                placeholder="Ingresa el nombre del modelo"
                                onChange={this.handleAddItem}
                                value={this.state.model}
                                required />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="modelo" sm={4}>Marca modelo</Label>
                            <Col sm={8}>
                            <Input 
                                type="text"
                                name="brand"
                                id="brand" 
                                placeholder="Ingresa la marca del modelo"
                                onChange={this.handleAddItem}
                                value={this.state.brand}
                                required />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="modelo" sm={4}>N&uacute;mero de parte</Label>
                            <Col sm={8}>
                            <Input 
                                type="text"
                                name="part_number"
                                id="part_number" 
                                placeholder="Ingresa el número de parte"
                                onChange={this.handleAddItem}
                                value={this.state.part_number}
                                required />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="id_model" sm={4}>Tipo dispositivo</Label>
                            <Col sm={8}>
                                <Input
                                    type="select"
                                    name="id_device_type"
                                    id="id_device_type"
                                    onChange={this.handleAddItem}
                                    value={this.state.id_device_type}
                                    required>
                                    {
                                        DevicesType === [] ? <option>1</option> :
                                            DevicesType.map((item, key) => {
                                                return (
                                                    <option key={key} value={item.id_device_type}>{item.type}</option>
                                                )
                                            })
                                    }
                                </Input>
                            </Col>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                        <Button color="success" type="submit" disabled={this.state.submit}>Crear</Button>
                    </ModalFooter>
                    </Form>
                </Modal>
                </div>
                
                {/* MODAL UPDATE ITEM */}
                <div>
                <Modal isOpen={this.state.modalEdit} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>
                        Editar Modelo {this.state.item ? this.state.item.model : ''}
                    </ModalHeader>
                    <Form onSubmit={this.submitUpdateData}>
                    <ModalBody>
                        <FormGroup row>
                            <Label for="model" sm={4}>Nombre Modelo</Label>
                            <Col sm={8}>
                            <Input 
                                type="text"
                                name="model"
                                id="model" 
                                placeholder="Ingresa el nombre del modelo"
                                onChange={this.handleUpdateItem}
                                value={this.state.model}
                                required />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="modelo" sm={4}>Marca modelo</Label>
                            <Col sm={8}>
                            <Input 
                                type="text"
                                name="brand"
                                id="brand" 
                                placeholder="Ingresa la marca del modelo"
                                onChange={this.handleUpdateItem}
                                value={this.state.brand}
                                required />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="modelo" sm={4}>N&uacute;mero de parte</Label>
                            <Col sm={8}>
                            <Input 
                                type="text"
                                name="part_number"
                                id="part_number" 
                                placeholder="Ingresa el número de parte"
                                onChange={this.handleUpdateItem}
                                value={this.state.part_number}
                                required />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="id_model" sm={4}>Tipo dispositivo</Label>
                            <Col sm={8}>
                                <Input
                                    type="select"
                                    name="id_device_type"
                                    id="id_device_type"
                                    onChange={this.handleUpdateItem}
                                    value={this.state.id_device_type}
                                    required>
                                    {
                                        DevicesType === [] ? <option>1</option> :
                                            DevicesType.map((item, key) => {
                                                return (
                                                    <option key={key} value={item.id_device_type}>{item.type}</option>
                                                )
                                            })
                                    }
                                </Input>
                            </Col>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                        <Button color="success" type="submit" disabled={this.state.submit}>Actualizar</Button>
                    </ModalFooter>
                    </Form>
                </Modal>
                </div>

                <div>
                {/* MODAL DELETE */}
                <Modal isOpen={this.state.modalDelete} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>
                        Eliminar {this.state.item ? this.state.item.model : 'Falla'}
                    </ModalHeader>
                    <ModalBody>
                        ¿Deseas eliminar esta falla?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                        <Button color="danger" onClick={this.submitDeleteData} disabled={this.state.submit}>Eliminar</Button>
                    </ModalFooter>
                </Modal>
                </div>
            </>
        );
    }
}

export default Models;