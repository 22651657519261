import React, { Component } from 'react';
import { Card, CardHeader, Container, Row,  Button, Table,
    DropdownMenu,DropdownItem, UncontrolledDropdown, DropdownToggle,
    Modal, ModalBody, ModalHeader, ModalFooter, 
    Form, Label, FormGroup, Input, Col
} from "reactstrap";
import AdminHeader from "./../../Shared/Headers/AdminHeader";
import './../../../assets/css/dashboard/dashboard.css';
import { CircleSpinner } from 'react-spinners-kit';
import { getClients, createClients, updateClients, getPricingPlans } from './../../../api/clients';
import { getPricesList } from './../../../api/pricesList';

// TOAST
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

const toastOptions = {
    positionClass: 'toast-top-center',
    hideDuration: 300,
    timeOut: 3000,
    progressBar: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
}

const Countries = [
    {
        country: "Colombia"
    },
    {
        country: "Ecuador"
    },
    {
        country: "EE.UU"
    },
    {
        country: "Brasil"
    },
    {
        country: "Chile"
    },
    {
        country: "Chile"
    }
]

class Clients extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            submit: false,
            Clients: null,
            item: null,

            modalNoData: false,
            modalNew: false,
            modalEdit:false,
            modalDelete: false,

            modalClients: false,
            
            id: '',
            name: '',
            nit: '',
            phone: '',
            address: '',
            country: 'Colombia',
            city: '',
            id_price_list: '',

            PricesList: [],
            PricingPlan: [],

        };

        this.closeModal = this.closeModal.bind(this);

        this.addNewItem = this.addNewItem.bind(this);
        this.handleAddItem = this.handleAddItem.bind(this);
        this.submitAddItem = this.submitAddItem.bind(this);
        this.NewItem = this.NewItem.bind(this);

        this.editItem = this.editItem.bind(this);
        this.submitUpdateData = this.submitUpdateData.bind(this);
        this.handleUpdateItem = this.handleUpdateItem.bind(this);

        this.deleteItem = this.deleteItem.bind(this);
        this.submitDeleteData = this.submitDeleteData.bind(this);
        this.deleteItemData = this.deleteItemData.bind(this);

        this.goComponent = this.goComponent.bind(this);
    }

    async fetchData() {
        const requestPricesList = await getPricesList();
        if(requestPricesList.response.status === 200) {
            if(requestPricesList.response.Price_list.length === 0) {
                this.setState({ modalClients: true,  loading: false })
                return;
            }
            else {
                this.setState({ 
                    PricesList: requestPricesList.response.Price_list, 
                    id_price_list: requestPricesList.response.Price_list[0].id_price_list
                })
            }
        }
        const requestPricesPlan = await getPricingPlans();
        if(requestPricesPlan.response.status === 200) {
            this.setState({ PricingPlan: requestPricesPlan.response.Pricing_plans})
        }
        const result = await getClients();
        if(result.response.status === 500) {
            alert('Error en el servidor');
            this.setState({ Clients: [], loading: false})
        }
        if(result.response.status === 404) {
            this.setState({ Clients: result.response.Clients, loading: false, modalNoData: true})
        }
        else {
            let modalNoData = false;
            let length = result.response.Clients ? result.response.Clients.length : 0;
            if(length === 0) modalNoData = true;
            this.setState({ Clients: result.response.Clients, loading: false, modalNoData: modalNoData})
        }        
    }
    
    componentDidMount() {
        this.fetchData();  
    }

    closeModal() {
        this.setState({
            modalNoData: false,
            modalNew: false,
            modalEdit:false,
            modalDelete: false,

            modalClients: false,

            submit: false,
            hide: true,
            
            id: '',
            name: '',
            nit: '',
            phone: '',
            address: '',
            country: '',
            city: '',
            id_pricing_plan: '',
            id_price_list: ''
        })
    }

    addNewItem() {
        this.setState({modalNew: true})
    }

    handleAddItem(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    submitAddItem(e) {
        e.preventDefault();
        this.setState({ submit: true})
        const { PricesList } = this.state;
        let id_price_list
        if(!this.state.id_price_list) {
            id_price_list = PricesList[0].id_price_list
        }
        else {
            id_price_list = this.state.id_price_list
        }
        let data = {
            name: this.state.name,
            nit: this.state.nit,
            phone: this.state.phone,
            address: this.state.address,
            country: this.state.country,
            city: this.state.city,
            id_price_list: id_price_list,
        }
        createClients(data)
        .then( res => {
            if(res.response.status !== 200) {
                alert(res.response.message);
            }
            else {
                this.NewItem(res.response.client);
                this.toastCreateItem(res.response.client.name);
                this.closeModal();
            }
        })
        .catch( err =>{
            alert(err)
        });
    }

    toastCreateItem(name) {
        toastr.options = toastOptions;
        toastr.clear()
        setTimeout(() => toastr.success(`Cliente ${name ? name : ''} creado`), 300)
    }

    async NewItem(item) {
        if(!this.state.Clients) this.state.Clients = [];
        let newItem = this.appendObjTo(this.state.Clients, item);
        this.setState({ Clients: newItem })
    }

    editItem(item) {
        this.setState({
            modalEdit: true,
            id: item.id_client,
            name: item.name,
            nit: item.nit,
            phone: item.phone,
            address: item.address,
            country: item.country,
            city: item.city,
            id_price_list: item.id_price_list
        })
    }

    handleUpdateItem(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    submitUpdateData(e) {
        e.preventDefault();
        this.setState({ submit: true })
        const { id, name, nit, phone, address, country, city, id_price_list } = this.state;  
        updateClients(id, { name, nit, phone, address, country, city, id_price_list })
            .then( res => {
                if(res.response.status !== 200) {
                    alert(res.response.message);
                }
                else {
                    this.toastUpdateItem(res.response.client.name)
                    this.updateItemData(res.response.client);
                    this.closeModal();
                }
            })
            .catch( err =>{
                alert(err)
            });
    }

    toastUpdateItem(name) {
        toastr.options = toastOptions;
        toastr.clear()
        setTimeout(() => toastr.success(`Cliente ${name ? name : ''} actualizado `), 300)
    }

    async updateItemData(data) {
        try {
            var id = data.id_client;
            let { Clients } = this.state; 
            let index = Clients.findIndex(item => item.id_client === id);
            if(index !== -1){
                Clients[index].name = data.name;
                Clients[index].phone = data.phone;
                Clients[index].nit = data.nit;
                Clients[index].address = data.address;
                Clients[index].country = data.country;
                Clients[index].city = data.city;
                Clients[index].id_price_list = data.id_price_list;
                Clients[index].pricing_plan = data.pricing_plan;
                Clients[index].price_list = data.price_list;
                Clients[index].workforce = data.workforce;
                Clients[index].status = data.status;
                this.setState({ Clients })
            }
            else this.setState({ Clients })
        } catch (error) {
            window.location.reload();
        }
    }

    deleteItem(item) {
        this.setState({modalDelete: true, item})
    }

    submitDeleteData(e) {
        e.preventDefault();
        const { item } = this.state;  
        let data = {
            id_status: 2
        }
        updateClients(item.id_client, data)
            .then( res => {
                if(res.response.status !== 200) {
                    alert(res.response.message);
                }
                else {
                    this.toastDeleteItem();
                    this.deleteItemData(res.response.client);
                    this.closeModal();
                }
            })
            .catch( err =>{
                alert(err)
            });
    }

    async deleteItemData(data) {
        try {
            var id = data.id_client;      
            let { Clients } = this.state;
            let index = Clients.findIndex(item => item.id_client === id);
            if(index !== -1) Clients.splice(index, 1);
            else this.setState({ Clients })
        }
        catch {
            window.location.reload();
        }
    }

    toastDeleteItem(name) {
        toastr.options = toastOptions;
        toastr.clear()
        setTimeout(() => toastr.success(`Cliente ${name ? name: ''} eliminado`), 300)
    }

    appendObjTo = (thatArray, newObj) => {
        const frozenObj = Object.freeze(newObj);
        return Object.freeze(thatArray.concat(frozenObj));
    }

    goComponent(component) {
        this.props.history.push(`/admin/${component}`);  
    }

    render() {
        const { Clients, PricesList, submit } = this.state;
        return (
            <>
                <AdminHeader {...this.props}/>
                <Container className="mt--7" fluid> 
                    <div>
                        <Row>
                            <div className="col">
                                <Card className="shadow">
                                    <CardHeader className="border-0">
                                        <Row>
                                            <div className="col-6">
                                                <h3 className="mb-0 d-inline">Clientes</h3>
                                            </div>
                                            <div className="col-6">
                                                <Button type="button" onClick={() => this.addNewItem()} style={{ padding: '0.3rem 0.7rem' }} className="float-right btn btn-outline-success btn-circle">
                                                    <i className="fa fa-plus"></i>
                                                </Button>
                                            </div>
                                        </Row>
                                    </CardHeader>
                                    {
                                        this.state.loading ? 
                                        <div className='spinner-table'><CircleSpinner
                                                size={30}
                                                color="#11cdef"
                                                loading={this.state.loading}
                                            /></div>
                                        :
                                        <Table className="align-items-center table-flush" responsive>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th scope="col" className='itemCenter'>cliente</th>
                                                    <th scope="col" className='itemCenter'>Identificación Tributaria</th>
                                                    <th scope="col" className='itemCenter'>Tel&eacute;fono</th>
                                                    <th scope="col" className='itemCenter'>Direcci&oacute;n</th>
                                                    <th scope="col" className='itemCenter'>Pa&iacute;s</th>
                                                    <th scope="col" className='itemCenter'>Ciudad</th>
                                                    <th scope="col" className='itemCenter'>Plan</th>
                                                    <th scope="col" className='itemCenter'>Lista de precio</th>
                                                    {/* <th scope="col" className='itemCenter'>Estado</th> */}
                                                    <th scope="col" />
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    Clients ? 
                                                    Clients.map((item, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td className='itemCenter'>{item.name ? item.name: <p>-</p>}</td>
                                                                <td className='itemCenter'>{item.nit ? item.nit : <p>-</p>}</td>
                                                                <td className='itemCenter'>{item.phone ? item.phone : <p>-</p>}</td>
                                                                <td className='itemCenter'>{item.address ? item.address : <p>-</p>}</td>
                                                                <td className='itemCenter'>{item.country ? item.country : <p>-</p>}</td>
                                                                <td className='itemCenter'>{item.city ? item.city : <p>-</p>}</td>
                                                                <td className='itemCenter'>{item.pricing_plan ? item.pricing_plan : <p>-</p>}</td>
                                                                <td className='itemCenter'>{item.price_list ? item.price_list : <p>-</p>}</td>
                                                                <td className="text-right">
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle
                                                                            className="btn-icon-only text-light"
                                                                            href="#"
                                                                            role="button"
                                                                            size="sm"
                                                                            color=""
                                                                            onClick={e => e.preventDefault()}>
                                                                            <i className="fas fa-ellipsis-v" />
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu-arrow" right>
                                                                            <DropdownItem
                                                                                onClick={() => this.editItem(item)}
                                                                            >
                                                                                <i className="fas fa-edit" />
                                                                                Editar
                                                                            </DropdownItem>
                                                                            <DropdownItem
                                                                                onClick={() => this.deleteItem(item)}
                                                                            >
                                                                                <i className="fas fa-trash" />
                                                                                Eliminar
                                                                            </DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </Table>
                                    }
                                    </Card>
                                </div>
                            </Row>
                    </div>
                </Container>

                {/* MODAL NO DATA */}
                <div>
                <Modal isOpen={this.state.modalNoData} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>NO DATA</ModalHeader>
                    <ModalBody>
                        No hay clientes registrados
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                    </ModalFooter>
                </Modal>
                </div>

                {/* MODAL NEW */}
                <div>
                <Modal isOpen={this.state.modalNew} toggle={this.closeModal} className={this.props.className} size="lg">
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>Agregar una nuevo cliente</ModalHeader>
                    <Form onSubmit={this.submitAddItem}>
                    <ModalBody>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="name">Nombre cliente</Label>
                                    <Input 
                                        type="text"
                                        name="name"
                                        id="name" 
                                        placeholder="Ingresa el nombre del cliente"
                                        onChange={this.handleAddItem}
                                        value={this.state.name}
                                        required />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="nit">Identificación tributaria del cliente</Label>
                                    <Input 
                                        type="text"
                                        name="nit"
                                        id="nit"
                                        placeholder="Ingrese la Identificación tributaria del cliente"
                                        onChange={this.handleAddItem}
                                        value={this.state.nit}
                                        required />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="phone">Tel&eacute;fono del cliente</Label>
                                    <Input 
                                        type="text"
                                        name="phone"
                                        id="phone"
                                        placeholder="Ingrese el número del cliente"
                                        onChange={this.handleAddItem}
                                        value={this.state.phone}
                                        required />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="address">Direcci&oacute;n del cliente</Label>
                                    <Input 
                                        type="text"
                                        name="address"
                                        id="address"
                                        placeholder="Ingrese la dirección del cliente"
                                        onChange={this.handleAddItem}
                                        value={this.state.address}
                                        required />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            {/* <Col md={6}>
                                <FormGroup>
                                    <Label for="id_price_list">País</Label>
                                    <Input 
                                        type="select"
                                        name="country"
                                        id="country"
                                        onChange={this.handleAddItem}
                                        value={this.state.country}>
                                        {
                                            Countries === [] ? <option value="Colombia">Colombia</option> :
                                            Countries.map((item, key) => {
                                                return(
                                                    <option key={key} value={item.country}>{item.country}</option>
                                                )
                                            })
                                        }
                                    </Input>
                                </FormGroup>
                            </Col> */}
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="country">País del cliente</Label>
                                    <Input 
                                        type="text"
                                        name="country"
                                        id="country"
                                        placeholder="Ingrese el país del cliente"
                                        onChange={this.handleAddItem}
                                        value={this.state.country}
                                        required />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="city">Ciudad del cliente</Label>
                                    <Input 
                                        type="text"
                                        name="city"
                                        id="city"
                                        placeholder="Ingrese la ciudad del cliente"
                                        onChange={this.handleAddItem}
                                        value={this.state.city}
                                        required />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="id_price_list">Lista de precios</Label>
                                    <Input 
                                        type="select"
                                        name="id_price_list"
                                        id="id_price_list"
                                        onChange={this.handleAddItem}
                                        value={this.state.id_price_list}>
                                        {
                                            PricesList === [] ? <option>1</option> :
                                            PricesList.map((item, key) => {
                                                return(
                                                    <option key={key} value={item.id_price_list}>{item.name}</option>
                                                )
                                            })
                                        }
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                        <Button color="success" type="submit" disabled={submit}>Crear</Button>
                    </ModalFooter>
                    </Form>
                </Modal>
                </div>

                {/* MODAL UPDATE ITEM */}
                <div>
                <Modal size='lg' isOpen={this.state.modalEdit} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>
                        Editar cliente {this.state.item ? this.state.item.name : ''}
                    </ModalHeader>
                    <Form onSubmit={this.submitUpdateData}>
                    <ModalBody>
                    <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="name">Nombre cliente</Label>
                                    <Input 
                                        type="text"
                                        name="name"
                                        id="name" 
                                        placeholder="Ingresa el nombre del cliente"
                                        onChange={this.handleAddItem}
                                        value={this.state.name}
                                        required />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="nit">Identificación tributaria del cliente</Label>
                                    <Input 
                                        type="text"
                                        name="nit"
                                        id="nit"
                                        placeholder="Ingrese el Identificación tributaria del cliente"
                                        onChange={this.handleAddItem}
                                        value={this.state.nit}
                                        required />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="phone">Tel&eacute;fono del cliente</Label>
                                    <Input 
                                        type="text"
                                        name="phone"
                                        id="phone"
                                        placeholder="Ingrese el número del cliente"
                                        onChange={this.handleAddItem}
                                        value={this.state.phone}
                                        required />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="address">Direcci&oacute;n del cliente</Label>
                                    <Input 
                                        type="text"
                                        name="address"
                                        id="address"
                                        placeholder="Ingrese la dirección del cliente"
                                        onChange={this.handleAddItem}
                                        value={this.state.address}
                                        required />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row form>
                        {/* <Col md={6}>
                                <FormGroup>
                                    <Label for="id_price_list">País</Label>
                                    <Input 
                                        type="select"
                                        name="country"
                                        id="country"
                                        onChange={this.handleAddItem}
                                        value={this.state.country}>
                                        {
                                            Countries === [] ? <option value="Colombia">Colombia</option> :
                                            Countries.map((item, key) => {
                                                return(
                                                    <option key={key} value={item.country}>{item.country}</option>
                                                )
                                            })
                                        }
                                    </Input>
                                </FormGroup>
                            </Col> */}
                             <Col md={6}>
                                <FormGroup>
                                    <Label for="country">País del cliente</Label>
                                    <Input 
                                        type="text"
                                        name="country"
                                        id="country"
                                        placeholder="Ingrese el país del cliente"
                                        onChange={this.handleAddItem}
                                        value={this.state.country}
                                        required />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="city">Ciudad del cliente</Label>
                                    <Input 
                                        type="text"
                                        name="city"
                                        id="city"
                                        placeholder="Ingrese la ciudad del cliente"
                                        onChange={this.handleAddItem}
                                        value={this.state.city}
                                        required />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="id_price_list">Lista de precios</Label>
                                    <Input 
                                        type="select"
                                        name="id_price_list"
                                        id="id_price_list"
                                        onChange={this.handleAddItem}
                                        value={this.state.id_price_list}>
                                        {
                                            PricesList === [] ? <option>1</option> :
                                            PricesList.map((item, key) => {
                                                return(
                                                    <option key={key} value={item.id_price_list}>{item.name}</option>
                                                )
                                            })
                                        }
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                        <Button color="success" type="submit" disabled={this.state.submit}>Actualizar</Button>
                    </ModalFooter>
                    </Form>
                </Modal>
                </div>

                <div>
                {/* MODAL DELETE */}
                <Modal isOpen={this.state.modalDelete} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>
                        Eliminar {this.state.item ? this.state.item.name : 'cliente'}
                    </ModalHeader>
                    <ModalBody>
                        ¿Deseas eliminar este cliente?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                        <Button color="danger" onClick={this.submitDeleteData} >Eliminar</Button>
                    </ModalFooter>
                </Modal>
                </div>

                <div>
                {/* MODAL NO CLIENTS */}
                <Modal isOpen={this.state.modalClients} toggle={() => this.goComponent('prices_lists')} className={this.props.className}>
                    <ModalHeader toggle={() => this.goComponent('prices_lists')} className='ModalHeaderTitle'>
                       No hay lista de precios creadas
                    </ModalHeader>
                    <ModalBody>
                        Ir a crear lista de precios
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.goComponent('prices_lists')}>Crear</Button>
                    </ModalFooter>
                </Modal>
                </div>
            </>
        );
    }
}

export default Clients;