import React, { Component } from 'react';
import { Card, CardHeader, Container, Row,  Button, Table,
    DropdownMenu,DropdownItem, UncontrolledDropdown, DropdownToggle,
    Modal, ModalBody, ModalHeader, ModalFooter, 
    Form, Label, FormGroup, Input, Col
} from "reactstrap";
import AdminHeader from "../../Shared/Headers/AdminHeader";
import { CircleSpinner } from 'react-spinners-kit';
import { MultiSelect } from './../../Shared/UI_Elements/Multiselect';
import './../../../assets/css/dashboard/dashboard.css';
import { getFailures, createFailure, updateFailure } from '../../../api/failures';
import { getModels } from './../../../api/models';

// TOAST
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

const toastOptions = {
    positionClass: 'toast-top-center',
    hideDuration: 300,
    timeOut: 3000,
    progressBar: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
}


class Failures extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            submit: false,
            Failures: [],

            item: null,

            modalNoData: false,
            modalNew: false,
            modalChangePassword: false,
            modalEdit:false,
            modalDelete: false,

            modalModels: false,
            Models: [],

            newModels: [],
            oldModels: [],

            failure: '',
            description: '',
            id: ''
        };

        this.closeModal = this.closeModal.bind(this);
        
        this.addNewItem = this.addNewItem.bind(this);
        this.handleAddItem = this.handleAddItem.bind(this);
        this.submitAddItem = this.submitAddItem.bind(this);

        this.editItem = this.editItem.bind(this);
        this.submitUpdateData = this.submitUpdateData.bind(this);
        this.handleUpdateItem = this.handleUpdateItem.bind(this);

        this.deleteItem = this.deleteItem.bind(this);
        this.submitDeleteData = this.submitDeleteData.bind(this);
        this.deleteItemData = this.deleteItemData.bind(this);
        this.goModels = this.goModels.bind(this);
        this.callbackFunction = this.callbackFunction.bind(this);
    }

    async fetchData() {
        const modelsResult = await getModels()
        if(modelsResult.response.status === 200 ) {
            if( modelsResult.response.Models.length <= 0) {
                this.setState({ modalModels: true, loading: false})
                return;
            }

        }
        const result = await getFailures();
        if(result.response.status === 500) {
            alert('Error en el servidor');
            this.setState({ Failures: [], loading: false})
        }
        if(result.response.status === 404) {
            this.setState({ Failures: result.response.Failures, loading: false, modalNoData: true})
        }
        else {
            let modalNoData = false;
            let length = result.response.Failures ? result.response.Failures.length : 0;
            if(length === 0) modalNoData = true;
            this.setState({ 
                Failures: result.response.Failures, 
                loading: false, modalNoData: modalNoData,
                Models: modelsResult.response.Models,
                modalModels: false
            })
        }        
    }
    
    componentDidMount() {
        this.fetchData();  
    }

    closeModal() {
        this.setState({
            modalNoData: false,
            modalNew: false,
            modalChangePassword: false,
            modalEdit:false,
            modalDelete: false,
            submit: false,

            item: null,

            failure: '',
            description: '',
            id: '',
            hide: true,
            newModels: []
        })
    }

    goModels() {
        this.props.history.push('/admin/models');  
    }

    addNewItem() {
        this.setState({modalNew: true})
    }

    handleAddItem(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    submitAddItem(e) {
        e.preventDefault();
        let data = {
            failure: this.state.failure,
            models: this.state.newModels,
            description: this.state.description,
        }
        if(data.models.length <= 0) {
            alert('Agrega modelos compatibles')
            return;
        }
        this.setState({ submit: true})
        createFailure(data)
        .then( res => {
            if(res.response.status !== 200) {
                alert(res.response.message);
            }
            else {
                this.NewItem(res.response.failure);
                this.toastCreateItem();
                this.closeModal();
            }
        })
        .catch( err =>{
            alert(err)
        });
    }

    toastCreateItem() {
        toastr.options = toastOptions;
        toastr.clear()
        setTimeout(() => toastr.success(`Falla creada`), 300)
    }

    async NewItem(item) {
        if(!this.state.Failures) this.state.Failures = [];
        let newItem = this.appendObjTo(this.state.Failures, item);
        this.setState({ Failures: newItem })
    }


    editItem(item) {
        this.setState({modalEdit: true, id: item.id_failure, 
            failure: item.failure, description: item.description,
            oldModels: item.models })
    }

    handleUpdateItem(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    submitUpdateData(e) {
        e.preventDefault();
        this.setState({ submit: true})
        const { id, description, failure, newModels } = this.state;  
        let data = {
            failure:  failure,
            description: description,
            id: id,
            models: newModels
        }
        updateFailure(id, data)
            .then( res => {
                if(res.response.status !== 200) {
                    alert(res.response.message);
                }
                else {
                    this.toastUpdateFailure()
                    this.updateFailureData(res.response.failure);
                    this.closeModal();
                }
            })
            .catch( err =>{
                alert(err)
            });
    }

    toastUpdateFailure() {
        toastr.options = toastOptions;
        toastr.clear()
        setTimeout(() => toastr.success(`Falla actualizada`), 300)
    }

    async updateFailureData(data) {
        var id = data.id_failure;
        let { Failures } = this.state; 
        let index = Failures.findIndex(fail => fail.id_failure === id);
        if(index !== -1){
            Failures[index].failure = data.failure;
            Failures[index].description = data.description;
            Failures[index].models = data.models;
            this.setState({ Failures })
        }
        else{
            this.setState({ Failures })
        }
    }

    deleteItem(item) {
        this.setState({modalDelete: true, item})
    }

    submitDeleteData(e) {
        e.preventDefault();
        this.setState({ submit: true })
        const { item } = this.state;  
        let data = {
            id_status: 2
        }
        updateFailure(item.id_failure, data)
            .then( res => {
                if(res.response.status !== 200) {
                    alert(res.response.message);
                }
                else {
                    this.toastDeleteItem();
                    this.deleteItemData(res.response.failure);
                    this.closeModal();
                }
            })
            .catch( err =>{
                alert(err)
            });
    }

    async deleteItemData(data) {
        try {
            var id = data.id_failure;
            let { Failures } = this.state;
            let index = parseInt(Failures.findIndex(fail => fail.id_failure === id));
            if(index !== -1) Failures.splice(index, 1);
            else this.setState({ Failures })
        } catch (error) {
            window.location.reload();
        }
    }

    toastDeleteItem(name) {
        toastr.options = toastOptions;
        toastr.clear()
        setTimeout(() => toastr.success(`Falla ${name ? name: ''} eliminada`), 300)
    }

    appendObjTo = (thatArray, newObj) => {
        const frozenObj = Object.freeze(newObj);
        return Object.freeze(thatArray.concat(frozenObj));
    }

    callbackFunction = (childData) => {
        this.setState({ newModels: childData.Models})
    }

    render() {
        const { Failures, Models, oldModels } = this.state;
        return (
            <>
                <AdminHeader {...this.props}/>
                <Container className="mt--7" fluid> 
                    <div>
                        <Row>
                            <div className="col">
                                <Card className="shadow">
                                    <CardHeader className="border-0">
                                        <Row>
                                            <div className="col-6">
                                                <h3 className="mb-0 d-inline">Fallas</h3>
                                            </div>
                                            <div className="col-6">
                                                <Button type="button" onClick={() => this.addNewItem()} style={{ padding: '0.3rem 0.7rem' }} className="float-right btn btn-outline-success btn-circle">
                                                    <i className="fa fa-plus"></i>
                                                </Button>
                                            </div>
                                        </Row>
                                    </CardHeader>
                                    
                                    {
                                        this.state.loading ? 
                                        <div className='spinner-table'><CircleSpinner
                                                size={30}
                                                color="#11cdef"
                                                loading={this.state.loading}
                                            /></div>
                                        :
                                        <Table className="align-items-center table-flush" responsive>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th className='itemCenter' scope="col">Falla</th>
                                                    <th className='itemCenter' scope="col">Descripci&oacute;n</th>
                                                    <th className='itemCenter' scope="col" />
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    Failures ? 
                                                    Failures.map((item, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td className='itemCenter'>{item.failure ? item.failure: <p>-</p>}</td>
                                                                <td className='itemCenter'>{item.description ? item.description: <p>-</p>}</td>
                                                                <td className="text-right">
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle
                                                                            className="btn-icon-only text-light"
                                                                            href="#"
                                                                            role="button"
                                                                            size="sm"
                                                                            color=""
                                                                            onClick={e => e.preventDefault()}>
                                                                            <i className="fas fa-ellipsis-v" />
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu-arrow" right>
                                                                            <DropdownItem
                                                                                onClick={() => this.editItem(item)}
                                                                            >
                                                                                <i className="fas fa-edit" />
                                                                                Editar
                                                                            </DropdownItem>
                                                                            <DropdownItem
                                                                                onClick={() => this.deleteItem(item)}
                                                                            >
                                                                                <i className="fas fa-trash" />
                                                                                Eliminar
                                                                            </DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </Table>
                                    }
                                    </Card>
                                </div>
                            </Row>
                    </div>
                </Container>

                {/* MODAL NO DATA */}
                <div>
                <Modal isOpen={this.state.modalNoData} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>NO DATA</ModalHeader>
                    <ModalBody>
                        No hay fallas registradas
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                    </ModalFooter>
                </Modal>
                </div>

                {/* MODAL NEW */}
                <div>
                <Modal isOpen={this.state.modalNew} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>Agregar una nueva falla</ModalHeader>
                    <Form onSubmit={this.submitAddItem}>
                    <ModalBody>
                        <FormGroup row>
                            <Label for="failure" sm={4}>Nombre falla</Label>
                            <Col sm={8}>
                            <Input 
                                type="text"
                                name="failure"
                                id="failure" 
                                placeholder="Ingresa el nombre de la falla"
                                onChange={this.handleAddItem}
                                value={this.state.failure}
                                required />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="description" sm={4}>Modelos Compatibles</Label>
                            <Col sm={8}>
                                <MultiSelect 
                                    Models={Models} 
                                    parentCallback = {this.callbackFunction}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="description" sm={4}>Descripci&oacute;n de la falla</Label>
                            <Col sm={8}>
                            <Input 
                                type="textarea"
                                name="description"
                                id="description" 
                                placeholder="Ingrese la descripción de la falla"
                                onChange={this.handleAddItem}
                                value={this.state.description}
                                required />
                            </Col>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                        <Button color="success" type="submit" disabled={this.state.submit}>Crear</Button>
                    </ModalFooter>
                    </Form>
                </Modal>
                </div>
                
                {/* MODAL UPDATE ITEM */}
                <div>
                <Modal isOpen={this.state.modalEdit} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>
                        Editar Falla {this.state.item ? this.state.item.failure : ''}
                    </ModalHeader>
                    <Form onSubmit={this.submitUpdateData}>
                    <ModalBody>
                        <FormGroup row>
                            <Label for="failure" sm={4}>Nombre falla</Label>
                            <Col sm={8}>
                            <Input 
                                type="text"
                                name="failure"
                                id="failure" 
                                placeholder="Ingresa el nombre de la falla"
                                onChange={this.handleUpdateItem}
                                value={this.state.failure}
                                required />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="description" sm={4}>Modelos Compatibles</Label>
                            <Col sm={8}>
                                <MultiSelect 
                                    Models={Models} 
                                    parentCallback = {this.callbackFunction}
                                    oldModels = { oldModels }
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="description" sm={4}>Descripci&oacute;n de la falla</Label>
                            <Col sm={8}>
                            <Input 
                                type="textarea"
                                name="description"
                                id="description" 
                                placeholder="Ingrese la descripción de la falla"
                                onChange={this.handleUpdateItem}
                                value={this.state.description}
                                required />
                            </Col>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                        <Button color="success" type="submit" disabled={this.state.submit}>Actualizar</Button>
                    </ModalFooter>
                    </Form>
                </Modal>
                </div>

                <div>
                {/* MODAL DELETE */}
                <Modal isOpen={this.state.modalDelete} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>
                        Eliminar {this.state.item ? this.state.item.failure : 'Falla'}
                    </ModalHeader>
                    <ModalBody>
                        ¿Deseas eliminar esta falla?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                        <Button color="danger" onClick={this.submitDeleteData}>Eliminar</Button>
                    </ModalFooter>
                </Modal>
                </div>

                
                <div>
                {/* MODAL NO MODELS */}
                <Modal isOpen={this.state.modalModels} toggle={this.goModels} className={this.props.className}>
                    <ModalHeader toggle={this.goModels} className='ModalHeaderTitle'>
                       No hay modelos creados
                    </ModalHeader>
                    <ModalBody>
                        Ir a crear modelo
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.goModels}>Crear</Button>
                    </ModalFooter>
                </Modal>
                </div>
            </>
        );
    }
}

export default Failures;