import React, { Component } from 'react';
import { Card, CardHeader, Container, Row,  Button, 
    // Table,DropdownMenu,DropdownItem, UncontrolledDropdown, DropdownToggle,
    Modal, ModalBody, ModalHeader, ModalFooter, Label,
    Form, FormGroup, Input, Col, Progress} from "reactstrap";
import AdminHeader from "../../Shared/Headers/AdminHeader";
import { CircleSpinner } from 'react-spinners-kit';
import axios from 'axios';
import { CSVLink } from "react-csv";
import DataTable from 'react-data-table-component';
import '../../../assets/css/dashboard/dashboard.css';
import { getBatches } from './../../../api/batches';
import { getWarehouse } from './../../../api/warehouses';
import { getClients } from './../../../api/clients';
import { getServiceCenter } from './../../../api/serviceCenters'
import auth from "./../../../api/auth.js";

// TOAST
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

const toastOptions = {
    positionClass: 'toast-top-center',
    hideDuration: 300,
    timeOut: 3000,
    progressBar: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
}

const columns = [
  {
    name: 'LOTE',
    selector: 'name',
    sortable: true,
    center:true
  },
  {
    name: 'CENTRO DE SERVICIO',
    selector: 'service_center',
    sortable: true,
    center:true
  },
  {
    name: 'USUARIO',
    selector: 'user',
    sortable: true,
    center:true
  },
  {
    name: 'ESTADO',
    selector: 'status',
    sortable: true,
    center:true
  },
  {
    name: '',
    cell: row => <button className='btn btn-primary btn-sm' onClick={() => clickHandler(row.id_batch)}>Remisión</button>,
  },
  {
    name: '',
    cell: row => <button className='btn btn-info btn-sm' onClick={() => downloadCVS(row.id_batch, row.name)}>Informe</button>,
  },
];

const customStyles = {
    rows: {
      style: {
        verticalAlign: 'middle',
      }
    },
    headCells: {
      style: {
        paddingLeft: '1.5rem', // override the cell padding for head cells
        paddingRight: ' 1.5rem',
        backgroundColor: '#f6f9fc',
        color: '#8898aa',
        verticalAlign: 'middle',
        paddingTop: '0.75rem',
        paddingBottom: '0.75rem',
        fontSize: '0.65rem',
        textTransform: 'uppercase',
        letterSpacing: '1px',
        fontWeight: '600'
      },
    },
    cells: {
      style: {
        paddingLeft: '1.5rem', // override the cell padding for head cells
        paddingRight: ' 1.5rem',
        verticalAlign: 'middle',
        fontSize: '0.8125rem',
        whiteSpace: 'nowrap',
        textAlign: 'center',
        fontWeight: 400,
        lineheight: 1.5,
        color: '#525f7f'
      },
    },
};

const clickHandler = async id  => {
    let authToken = auth.getToken()
    axios({
        url: `${process.env.REACT_APP_API_URL}/reports/remision/${id}`, //your url
        method: 'GET',
        responseType: 'blob',
        headers: {
            'dash-authorization': authToken,
            'Content-Type': 'application/json'
        },
    }).then(response => {
        if (response.status === 204 || response.status === 401) {
            auth.removeUser();
            auth.removeToken();
            window.location.reload();
            alert('NO AUTORIZADO')
            return response
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Reporte.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();
    })
    .catch(error => {
        alert(error.message ? error.message : 'error en el servidor')
    })
}

const downloadCVS = (id, name) => {
    let authToken = auth.getToken()
    axios({
        url: `${process.env.REACT_APP_API_URL}/reports/report/${id}`, //your url
        // url: `https://test.gprog.co/api/reports/report/${id}`, //your url
        method: 'GET',
        responseType: 'blob',
        headers: {
            'dash-authorization': authToken,
            'Content-Type': 'application/json'
        },
    }).then(response => {
        if (response.status === 204 || response.status === 401) {
            auth.removeUser();
            auth.removeToken();
            window.location.reload();
            alert('NO AUTORIZADO')
            return response
        }
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Informe_${name}.csv`); //or any other extension
        document.body.appendChild(link);
        link.click();
    })
    .catch(error => {
        alert(error.message ? error.message : 'error en el servidor')
    })
}

class Batches extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            submit: false,
            Batches: [],
            oldBatches: [],
            price: null,
            selectedFile: null,

            modalNoData: false,
            modalNew: false,
            modalEdit:false,
            modalDelete: false,
            modelCreate: false,
            modalFacturada: false,
            modalWarehouse: false,
            modalServiceCenter: false,
            modalClients: false,

            loaded:0,

            display: false,


            Warehouses: [],
            id_warehouse: '',

            Clients: [],
            id_client: '',

            Services_center: [],
            id_service_center: '',
            q:'',

            user: auth.getUser()
        };

        this.closeModal = this.closeModal.bind(this);

        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.addMassiveBatch = this.addMassiveBatch.bind(this);
        this.submitAddBatch = this.submitAddBatch.bind(this);

        this.editBatch = this.editBatch.bind(this);

        this.handleChangeForm = this.handleChangeForm.bind(this);

        this.deleteBatch = this.deleteBatch.bind(this);
        this.deleteBatchData = this.deleteBatchData.bind(this);

        this.onChange = this.onChange.bind(this);
        this.filterData = this.filterData.bind(this);

        this.goComponent = this.goComponent.bind(this);
    }


    async fetchData() {
        const resultWarehouse = await getWarehouse()
        if(resultWarehouse.response.status === 200 ) {
            if(resultWarehouse.response.Warehouses.length === 0) {
                this.setState({ modalWarehouse: true })
                return;
            }
            else {
                this.setState({ 
                    Warehouses: resultWarehouse.response.Warehouses,
                    id_warehouse: resultWarehouse.response.Warehouses[0].id_warehouse
                })
            }
        }
        
        const resultCliens= await getClients()
        if(resultCliens.response.status === 200 ) {
            if(resultCliens.response.Clients.length === 0) {
                this.setState({ modalClients: true })
                return;
            }
            else {
                this.setState({ 
                   Clients: resultCliens.response.Clients,
                   id_client: resultCliens.response.Clients[0].id_client
               })
            }
        }
        const resultService = await getServiceCenter()
        if(resultService.response.status === 200 ) {
            if(resultService.response.Services_center.length === 0) {
                this.setState({ modalServiceCenter: true })
                return;
            }
            else {
                this.setState({ 
                    Services_center: resultService.response.Services_center,
                    id_service_center: resultService.response.Services_center[0].id_service_center
                })
            }
        }
        const result = await getBatches();
        if(result.response.status === 500) {
            alert('Error en el servidor');
            this.setState({ Batches: [], loading: false})
        }
        if(result.response.status === 404) {
            this.setState({ Batches: result.response.Batches, loading: false, modalNoData: true})
        }
        else {
            let modalNoData = false;
            if(result.response.Batches.length <= 0) modalNoData = true;
            this.setState({ Batches: result.response.Batches,oldBatches: result.response.Batches,
                 loading: false, modalNoData, user: auth.getUser()})
        }        
    }
    
    componentDidMount() {
        this.fetchData();  
    }

    addMassiveBatch() {
        this.setState({ modalNew: true })
    }

    checkMimeType = (event) => {
        //getting file object
        let files = event.target.files
        //define message container
        let err = ''
        // list allow mime type
        const types = [
            'text/csv', 'text/xls', 'text/xlsx', 'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
        // loop access array
        for (var i = 0; i < files.length; i++) {
            // compare file type find doesn't matach
            if (types.every(type => files[i].type !== type)) {
                // create error message and assign to container   
                err += files[i].type + ' is not a supported format\n';
            }
        };

        if (err !== '') { // if message not same old that mean has error 
            event.target.value = null // discard selected file
            this.toastWrongExtension()
            return false;
        }
        return true;

    }

    onChangeHandler=event=>{
        if( this.checkMimeType(event) ){ 
            this.setState({
                selectedFile: event.target.files[0],
                loaded: 0,
            })
        }
    }

    submitAddBatch(e) {
        e.preventDefault();
        let file = new FormData();
        file.append('devices', this.state.selectedFile)
        this.setState({ submit: true, display: true})
        let authToken = auth.getToken()
        // UPLOAD CSV
        axios.post(`${process.env.REACT_APP_API_URL}/devices/create/csv`, file, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'dash-authorization': authToken,
            },
            onUploadProgress: ProgressEvent => {
                this.setState({
                    loaded: (ProgressEvent.loaded / ProgressEvent.total * 100)
                })
            },
            params: {
                id_warehouse: this.state.id_warehouse,
                id_user: this.state.user.id_user,
                id_client: this.state.id_client,
                id_service_center: this.state.id_service_center
            }
        })
        .then(async res => { 
            if (res.status === 204 || res.status === 401) {
                auth.removeUser();
                auth.removeToken();
                window.location.reload();
                alert('NO AUTORIZADO')
                return res
            }
            if(res.status !== 200 ) alert(res.message)
            else {
                this.setState({ submit: false, selectedFile: null})
                await this.sleep(2000)
                this.setState({ modalCreate: true, modalNew: false, display: false})
            }
        })
        .catch(error => {
            alert(error)
        })
        
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async closeModal() {
        this.setState({
            modalNoData: false,
            modalNew: false,

            modalEdit:false,
            modalDelete: false,
            modalCreate: false,
            modalFacturada: false,
            submit: false,
            modalWarehouse: false,
            
            name: '',
            email: '',
            phone: '',
            Batchname: '',
            id_status: 1,
            id_role: 1,
            loaded:0
        })
    }

    toastCreateBatch() {
        toastr.options = toastOptions;
        toastr.clear()
        setTimeout(() => toastr.success(`Lista de precio creado`), 300)
    }

    async NewBatch(Batches) {
        if(!this.state.Batches) this.state.Batches = [];
        let newBatch = this.appendObjTo(this.state.Batches, Batches);
        this.setState({ Batches: newBatch })
    }

    appendObjTo = (thatArray, newObj) => {
        const frozenObj = Object.freeze(newObj);
        return Object.freeze(thatArray.concat(frozenObj));
    }

    toastWrongExtension() {
        toastr.options = toastOptions;
        toastr.clear()
        setTimeout(() => toastr.error(`Formato no soportado solo archivos CSV`), 300)
    }

    editBatch(Batches) {
        this.setState({modalEdit: true, Batches: Batches, name: Batches.name, email: Batches.email, 
            Batchname: Batches.Batchname, phone: Batches.phone, id_status: Batches.id_status, 
            id_role: Batches.id_role })
    }

    toastUpdateBatch() {
        toastr.options = toastOptions;
        toastr.clear()
        setTimeout(() => toastr.success(`Lista de precio actualizado`), 300)
    }

    handleChangeForm(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    async updateBatchData(data) {
        try {
            var id = data.id_Batch;
            let { Batches } = this.state; 
            let index = Batches.findIndex(Batches => Batches.id_Batch === id);
            if(index !== -1){
                Batches[index].name = data.name;
                Batches[index].email = data.email;
                Batches[index].phone = data.phone;
                Batches[index].Batchname = data.Batchname;
                Batches[index].password = data.password;
                Batches[index].id_status = data.id_status;
                Batches[index].role = data.role;
                Batches[index].status = data.status;
                Batches[index].id_role = data.id_role;
                this.setState({ Batches })
            }
            else{
                this.setState({ Batches })
            }
            
        } catch (error) {
            window.location.reload();
        }
    }

    deleteBatch(Batches) {
        this.setState({ modalDelete: true, Batches: Batches})
    }

    async deleteBatchData(data) {
        var id = data.id_Batch;
        let { Batches } = this.state;
        let index = Batches.findIndex(Batches => Batches.id_Batch === id);
        if(index !== -1) Batches.splice(index, 1);
        else this.setState({ Batches })
    }

    toastDeleteBatch(name) {
        toastr.options = toastOptions;
        toastr.clear()
        setTimeout(() => toastr.success(`Lista de precio ${name ? name: ''} eliminado`), 300)
    }

 
    onChange(event) {
        const q = event.target.value.toLowerCase();
        this.setState({ q }, () => this.filterData());
    }

    filterData() {
        let { oldBatches, q } = this.state;

        const FilterBatches = oldBatches.filter(function(item) {
          return item.name.toLowerCase().indexOf(q) != -1; // returns true or false
        });
        if(FilterBatches.length === 0) this.setState({ Batches: oldBatches })
        else this.setState({ Batches: FilterBatches })
    }

    goComponent(component) {
        this.props.history.push(`/admin/${component}`);  
    }
    
    render() {
        const { Batches, Warehouses, Clients, Services_center } = this.state;
        return (
            <>
                <AdminHeader {...this.props}/>
                <Container className="mt--7" fluid> 
                    <div>
                        <Row>
                            <div className="col">
                                <Card className="shadow">
                                    <CardHeader className="border-0">
                                        <Row>
                                            <div className="col-6">
                                                <h3 className="mb-0 d-inline">Lotes</h3>
                                            </div>
                                            {/* <div className="col-6">
                                                <Button type="button" onClick={() => this.addMassiveBatch()} style={{ padding: '0.3rem 0.7rem' }} className="float-right btn btn-outline-success btn-circle">
                                                    <i className="fa fa-plus"></i>
                                                </Button>
                                            </div> */}
                                        </Row>
                                    </CardHeader>
                                    {
                                        this.state.loading ? 
                                        <div className='spinner-table'><CircleSpinner
                                                size={30}
                                                color="#11cdef"
                                                loading={this.state.loading}
                                            /></div>
                                        :
                                        <>
                                        <Row>
                                            <div className="col-3">
                                                <Button type="button" onClick={() => this.addMassiveBatch()} style={{ padding: '0.3rem 0.7rem' }} className="float-right btn btn-outline-success btn-circle">
                                                   Importar <i className="fa fa-plus"></i>
                                                </Button>
                                            </div>
                                            <div className="col-3">
                                                <CSVLink style={{ padding: '0.3rem 0.7rem', zIndex: '1000' }} 
                                                    data={Batches} 
                                                    filename={"lotes.csv"} 
                                                    className="float-right btn btn-outline-primary btn-circle">
                                                    Exportar <i className="fa fa-download"></i>
                                                </CSVLink>
                                            </div>
                                            <div className="col-5">
                                                <Input
                                                    style={{height: '2rem', zIndex: '1000'}}
                                                    type="text"
                                                    placeholder="Buscar"
                                                    value={this.state.q}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </Row>
                                        <br></br>
                                        <br></br>
                                        <DataTable style={{marginTop: '1%'}}
                                            title="LOTES"
                                            noHeader={true}
                                            columns={columns}
                                            data={Batches}
                                            customStyles={customStyles}
                                            fixedHeader
                                            fixedHeaderScrollHeight="300px"
                                            pagination
                                        />
                                        </>
                                    }
                                    </Card>
                                </div>
                            </Row>
                    </div>
                </Container>
                <div>
                {/* MODAL NO DATA */}
                <Modal isOpen={this.state.modalNoData} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>NO DATA</ModalHeader>
                    <ModalBody>
                        No hay listas de precios registrados
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                    </ModalFooter>
                </Modal>
                </div>

                <div>
                {/* MODAL NEW */}
                <Modal isOpen={this.state.modalNew} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>Carga Masiva Lotes</ModalHeader>
                    <Form onSubmit={this.submitAddBatch}>
                    <ModalBody>
                        <FormGroup row>
                            <Col sm={8}>
                                <Input type="file" name="file" onChange={this.onChangeHandler} required/>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="id_model" sm={4}>Centros de servicio</Label>
                            <Col sm={8}>
                                <Input
                                    type="select"
                                    name="id_service_center"
                                    id="id_service_center"
                                    onChange={this.handleChangeForm}
                                    value={this.state.id_service_center}
                                    required>
                                    {
                                        Services_center === [] ? <option>1</option> :
                                            Services_center.map((item, key) => {
                                                return (
                                                    <option key={key} value={item.id_service_center}>{item.name}</option>
                                                )
                                            })
                                    }
                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="id_model" sm={4}>Bodegas</Label>
                            <Col sm={8}>
                                <Input
                                    type="select"
                                    name="id_warehouse"
                                    id="id_warehouse"
                                    onChange={this.handleChangeForm}
                                    value={this.state.id_warehouse}
                                    required>
                                    {
                                        Warehouses === [] ? <option>1</option> :
                                            Warehouses.map((item, key) => {
                                                return (
                                                    <option key={key} value={item.id_warehouse}>{item.name}</option>
                                                )
                                            })
                                    }
                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="id_model" sm={4}>Clientes</Label>
                            <Col sm={8}>
                                <Input
                                    type="select"
                                    name="id_client"
                                    id="id_client"
                                    onChange={this.handleChangeForm}
                                    value={this.state.id_client}
                                    required>
                                    {
                                        Clients === [] ? <option>1</option> :
                                            Clients.map((item, key) => {
                                                return (
                                                    <option key={key} value={item.id_client}>{item.name}</option>
                                                )
                                            })
                                    }
                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup> 
                            <Progress 
                                max="100" 
                                color="success" 
                                value={this.state.loaded} 
                                style={{height: '20px', display: !this.state.display ? 'none' : ''}}>
                                    {Math.round(this.state.loaded,2) }%
                            </Progress>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                        <Button color="success" type="submit" disabled={this.state.submit}>Crear</Button>
                    </ModalFooter>
                    </Form>
                </Modal>
                </div>
                <div>
                {/* MODAL DELETE */}
                <Modal isOpen={this.state.modalDelete} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>
                        Eliminar {Batches ? Batches.name : 'Lista de precio'}
                    </ModalHeader>
                    <ModalBody>
                        ¿Deseas eliminar este Lote?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                        <Button color="danger" onClick={this.submitDeleteItem}>Eliminar</Button>
                    </ModalFooter>
                </Modal>
                </div>

                <div>
                {/* MODAL SUCESS CREATE */}
                <Modal isOpen={this.state.modalCreate} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>Éxito</ModalHeader>
                    <ModalBody>
                        Lote subido correctamente
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={ () => { this.closeModal() }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>
                </div>

                <div>
                {/* MODAL FACTURADA */}
                <Modal isOpen={this.state.modalFacturada} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>
                        {this.state.item ? this.state.item.name : 'Lote'}
                    </ModalHeader>
                    <ModalBody>
                        ¿Desea marcar este lote como facturado?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                        <Button color="success" onClick={this.submitfacturado} disabled={this.state.submit}>Actualizar</Button>
                    </ModalFooter>
                </Modal>
                </div>

                <div>
                {/* MODAL NO WEAREHOUSES */}
                <Modal isOpen={this.state.modalWarehouse} toggle={() => this.goComponent('warehouse')} className={this.props.className}>
                    <ModalHeader toggle={() => this.goComponent('warehouse')} className='ModalHeaderTitle'>
                       No hay bodegas creadas
                    </ModalHeader>
                    <ModalBody>
                        Ir a crear bodega
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.goComponent('warehouse')}>Crear</Button>
                    </ModalFooter>
                </Modal>
                </div>


                
                <div>
                {/* MODAL NO SERVICE CENTER */}
                <Modal isOpen={this.state.modalServiceCenter} toggle={() => this.goComponent('services_centers')} className={this.props.className}>
                    <ModalHeader toggle={() => this.goComponent('services_centers')} className='ModalHeaderTitle'>
                       No hay centros de servicios creados
                    </ModalHeader>
                    <ModalBody>
                        Ir a crear centro de servicio
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.goComponent('services_centers')}>Crear</Button>
                    </ModalFooter>
                </Modal>
                </div>

                <div>
                {/* MODAL NO CLIENTS */}
                <Modal isOpen={this.state.modalClients} toggle={() => this.goComponent('clients')} className={this.props.className}>
                    <ModalHeader toggle={() => this.goComponent('clients')} className='ModalHeaderTitle'>
                       No hay clientes creados
                    </ModalHeader>
                    <ModalBody>
                        Ir a crear cliente
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.goComponent('clients')}>Crear</Button>
                    </ModalFooter>
                </Modal>
                </div>
            </>
        );
    }
}

export default Batches;