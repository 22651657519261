// export default 'https://api.icee.gprog.co/api';

// let api_url;
// if (process.env.NODE_ENV === 'development'){
// api_url = 'https://test.gprog.co/api';
// }
// else if (process.env.NODE_ENV === 'production'){
// api_url = 'https://api.icee.gprog.co/api';
// } 
// console.log(process.env);

export default process.env.REACT_APP_API_URL

// export default 'https://test.gprog.co/api'

// export default 'https://api.icee.gprog.co/api'

// export default 'http://localhost:3000/api'