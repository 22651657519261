import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Nav, Media } from "reactstrap";
import auth from '../../../api/auth';

const handlerLogout = async () => {
    await auth.removeUser();
    await auth.removeToken();
}

export class ProfileNavbar extends Component {
    constructor(props){
        super(props);
        this.state = {
            user: auth.getUser()
        }
    }
    render() {
        const { user } = this.state;
        return (
            <div>
                <Nav className="align-items-center d-none d-md-flex" navbar>
                    {/* <UncontrolledDropdown nav>
                        <DropdownToggle nav className="nav-link-icon">
                            <i className="ni ni-bell-55" style={{color: '#FFF'}}/>
                        </DropdownToggle>
                        <DropdownMenu
                            aria-labelledby="navbar-default_dropdown_1"
                            className="dropdown-menu-arrow"
                            right
                        >
                            <DropdownItem>Notificaci&oacute;n</DropdownItem>
                            <DropdownItem>Mi acción</DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem>Something else here</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown> */}
                    <UncontrolledDropdown nav>
                        <DropdownToggle className="pr-0" nav>
                            <Media className="align-items-center">
                                <span className="avatar avatar-sm rounded-circle">
                                    {
                                        user
                                        ? <img alt="Imagen usuario perfil" src={require('./../../../assets/img/dashboard/icon_user.png')} />
                                        : <img alt="Imagen usuario perfil" src={require('./../../../assets/img/dashboard/icon_user.png')} />
                                    }
                                </span>
                                <Media className="ml-2 d-none d-lg-block">
                                    {
                                        user
                                        ? <span className="mb-0 text-sm font-weight-bold">{user.name}</span>
                                        : <span className="mb-0 text-sm font-weight-bold">Usuario</span>
                                    }
                                </Media>
                            </Media>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem className="noti-title" header tag="div">
                                <h6 className="text-overflow m-0">Bienvenido</h6>
                            </DropdownItem>
                            {/* <DropdownItem to="/admin" tag={Link}>
                                <i className="ni ni-single-02" />
                                <span>Mi perfil</span>
                            </DropdownItem>
                            <DropdownItem to="/admin" tag={Link}>
                                <i className="ni ni-settings-gear-65" />
                                <span>Mis ajustes</span>
                            </DropdownItem> */}
                            <DropdownItem divider />
                            <DropdownItem href="#pablo" onClick={handlerLogout}>
                                <i className="fas fa-sign-out-alt" />
                                <span>Salir</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Nav>
            </div>
        );
    }
}

export class ProfileNavbarSidebar extends Component {
    constructor(props){
        super(props);
        this.state = {
            user: auth.getUser()
        }
    }
    render() {
        const {user} = this.state;
        return(
        <div>
             <Nav className="align-items-center d-md-none">
            <UncontrolledDropdown nav>
              <DropdownToggle nav className="nav-link-icon">
                <i className="ni ni-bell-55" />
              </DropdownToggle>
              <DropdownMenu
                aria-labelledby="navbar-default_dropdown_1"
                className="dropdown-menu-arrow"
                right
              >
                <DropdownItem>Action</DropdownItem>
                <DropdownItem>Another action</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Something else here</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav>
              <DropdownToggle nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    {
                        user
                        ? <img alt="Imagen usuario perfil" src={require('./../../../assets/img/dashboard/icon_user.png')} />
                        : <img alt="Imagen usuario perfil" src={require('./../../../assets/img/dashboard/icon_user.png')} />
                    }
                  </span>
                </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Bienvenido</h6>
                </DropdownItem>
                {/* <DropdownItem to="/admin" tag={Link}>
                    <i className="ni ni-single-02" />
                    <span>Mi perfil</span>
                </DropdownItem>
                <DropdownItem to="/admin" tag={Link}>
                    <i className="ni ni-settings-gear-65" />
                    <span>Mis ajustes</span>
                </DropdownItem> */}
                <DropdownItem divider />
                <DropdownItem href="#pablo" onClick={handlerLogout}>
                    <i className="fas fa-sign-out-alt" />
                    <span>Salir</span>
                </DropdownItem>
            </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </div>);
    }
}