import { SET_USER } from '../actions/user';

export const user = (state = {}, action) => {
    switch (action.type) {
        case SET_USER:
            const user = action.value;
            return {...state, user}
        default:
            return state;
    }
};