import { appFetch } from './fetch';
import host from './host';
import auth from './auth';

export const createTransfer = async data => {
    const url = `${host}/transfer/create`;
    return await appFetch({ url, auth: auth.getToken(), body: JSON.stringify(data), method: 'POST' })
}

export const getTransfers = async () => { 
    const url = `${host}/transfers/get_all?dashboard=true`;
    return await appFetch({ url, auth: auth.getToken(), method: 'GET' });
}