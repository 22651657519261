import { appFetch } from './fetch';
import host from './host';
import auth from './auth';

export const createInventory = async data => {
    const url = `${host}/inventory/create`;
    return await appFetch({ url, auth: auth.getToken(), body: JSON.stringify(data), method: 'POST' })
}

export const getInventory = async (type) => {
    const url = `${host}/inventory/get/${type}`
    return await appFetch({ url, auth: auth.getToken(), method: 'GET' });
}