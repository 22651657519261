import React, { Component } from 'react';
import { Card, CardHeader, Container, Row,  Button, Table,
    DropdownMenu,DropdownItem, UncontrolledDropdown, DropdownToggle,
    Modal, ModalBody, ModalHeader, ModalFooter, 
    Form, Label, FormGroup, Input, Col,} from "reactstrap";
import AdminHeader from "../../Shared/Headers/AdminHeader";
import { CircleSpinner } from 'react-spinners-kit';
import '../../../assets/css/dashboard/dashboard.css';
import { getUsers, createUser, updateUser, getUsersInfo } from './../../../api/users';
// TOAST
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import auth from "./../../../api/auth";

const toastOptions = {
    positionClass: 'toast-top-center',
    hideDuration: 300,
    timeOut: 3000,
    progressBar: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
}

class Users extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            submit: true,
            Users: null,
            user: null,

            modalNoData: false,
            modalNew: false,
            modalChangePassword: false,
            modalEdit:false,
            modalDelete: false,

            hide: true,

            name: '',
            email: '',
            phone: '',
            username: '',
            password: '',
            password2: '',
            id_role: 1,
            id_status: 1,

            Roles: [],
            Status: [],

            sessionUser: null
        };

        this.closeModal = this.closeModal.bind(this);

        this.addUser = this.addUser.bind(this);
        this.handleAddUser = this.handleAddUser.bind(this);
        this.submitAddUser = this.submitAddUser.bind(this);

        this.handleClickPass = this.handleClickPass.bind(this);

        this.changePassword = this.changePassword.bind(this);
        this.handleUpdateUser = this.handleUpdateUser.bind(this);

        this.submitUpdateUser = this.submitUpdateUser.bind(this);
        this.submitChangePasswordUser = this.submitChangePasswordUser.bind(this);

        this.editUser = this.editUser.bind(this);

        this.deleteUser = this.deleteUser.bind(this);
        this.submitDeleteUsuarioMobile = this.submitDeleteUsuarioMobile.bind(this);
        this.deleteUserData = this.deleteUserData.bind(this);
    }

    

    async fetchData() {
        const info = await getUsersInfo();
        if(info.response.status === 200) {
            this.setState({ Roles: info.response.Roles, Status: info.response.Status})
        }
        const result = await getUsers();
        if(result.response.status === 500) {
            alert('Error en el servidor');
            this.setState({ Users: [], loading: false})
        }
        if(result.response.status === 404) {
            this.setState({ Users: result.response.Users, loading: false, modalNoData: true})
        }
        else {
            let sessionUser = auth.getUser();
            this.setState({ Users: result.response.Users, loading: false, sessionUser})
        }        
    }
    
    componentDidMount() {
        this.fetchData();  
    }

    addUser() {
        this.setState({modalNew: true})
    }

    handleAddUser(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
        if(this.state.password === this.state.password2 ) {
            this.setState({submit: false })
        }
    }

    submitAddUser(e) {
        e.preventDefault();
        if(this.state.password !== this.state.password2) {
            alert('Passwords no coinciden')
        }
        else {
            let data = {
                name: this.state.name,
                email: this.state.email,
                phone: this.state.phone,
                username: this.state.username,
                password: this.state.password,
                id_role: parseInt(this.state.id_role)
            }
            createUser(data)
            .then( res => {
                if(res.response.status !== 201) {
                    alert(res.response.message);
                }
                else {
                    this.NewUser(res.response.user);
                    this.toastCreateUser();
                    this.closeModal();
                }
            })
            .catch( err =>{
                alert(err)
            });
        }
    }

    handleClickPass() {
        this.setState({
            hide: !this.state.hide
        })
    }

    handleUpdateUser(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    closeModal() {
        this.setState({
            modalNoData: false,
            modalNew: false,
            modalChangePassword: false,
            modalEdit:false,
            modalDelete: false,
            submit: true,
            name: '',
            email: '',
            phone: '',
            username: '',
            password: '',
            password2: '',
            id_status: 1,
            id_role: 1,
            user: null,
            hide: true
        })
    }

    toastCreateUser() {
        toastr.options = toastOptions;
        toastr.clear()
        setTimeout(() => toastr.success(`Usuario creado`), 300)
    }

    async NewUser(user) {
        if(!this.state.Users) this.state.Users = [];
        let newUser = this.appendObjTo(this.state.Users, user);
        this.setState({ Users: newUser })
    }

    appendObjTo = (thatArray, newObj) => {
        const frozenObj = Object.freeze(newObj);
        return Object.freeze(thatArray.concat(frozenObj));
    }

    changePassword(user) {
        this.setState({modalChangePassword: true, user: user})
    }

    editUser(user) {
        this.setState({modalEdit: true, user: user, name: user.name, email: user.email, 
            username: user.username, phone: user.phone, id_status: user.id_status, 
            id_role: user.id_role })
    }

    submitUpdateUser(e) {
        e.preventDefault();
        const { name, email, phone, username, user, id_status, id_role} = this.state;  
        updateUser(user.id_user, {name, email, phone, username, id_role, id_status})
            .then( res => {
                if(res.response.status !== 200) {
                    alert(res.response.message);
                }
                else {
                    this.toastUpdateUser()
                    this.updateUserData(res.response.user);
                    this.closeModal();
                }
            })
            .catch( err =>{
                alert(err)
            });
    }

    submitChangePasswordUser(e) {
        e.preventDefault();
        if(this.state.password !== this.state.password2) {
            alert('Passwords no coinciden')
        }
        else {
            let data = {
                old_password: this.state.user.password,
                password: this.state.password
            }
            updateUser(this.state.user.id_user, data)
            .then( res => {
                if(res.response.status !== 200) {
                    alert(res.response.message);
                }
                else {
                    this.toastChangePasswordUser();
                    this.updateUserData(res.response.user);
                    this.closeModal();
                }
            })
            .catch( err =>{
                alert(err)
            });
        }
    }

    toastChangePasswordUser() {
        toastr.options = toastOptions;
        toastr.clear()
        setTimeout(() => toastr.success(`Password actualizado`), 300)
    }

    toastUpdateUser() {
        toastr.options = toastOptions;
        toastr.clear()
        setTimeout(() => toastr.success(`Usuario actualizado`), 300)
    }

    async updateUserData(data) {
        var id = data.id_user;
        let { Users } = this.state; 
        let index = Users.findIndex(user => user.id_user === id);
        if(index !== -1){
            Users[index].name = data.name;
            Users[index].email = data.email;
            Users[index].phone = data.phone;
            Users[index].username = data.username;
            Users[index].password = data.password;
            Users[index].id_status = data.id_status;
            Users[index].role = data.role;
            Users[index].status = data.status;
            Users[index].id_role = data.id_role;
            this.setState({ Users })
        }
        else{
            this.setState({ Users })
        }
    }

    deleteUser(user) {
        this.setState({ modalDelete: true, user: user})
    }

    submitDeleteUsuarioMobile(e) {
        e.preventDefault();
        let { user } = this.state;
        
        let data = {
            id_status: 3
        }
        updateUser(user.id_user,data)
        .then( res => {
            if(res.response.status !== 200) {
                alert(res.response.message);
            }
            else {
                this.deleteUserData(user);
                this.toastDeleteUser(user.name)
                this.closeModal();
            }
        })
        .catch( err =>{
            alert(err)
        });
    }

    async deleteUserData(data) {
        var id = data.id_user;
        let { Users } = this.state;
        let index = Users.findIndex(user => user.id_user === id);
        if(index !== -1) Users.splice(index, 1);
        else this.setState({ Users })
    }

    toastDeleteUser(name) {
        toastr.options = toastOptions;
        toastr.clear()
        setTimeout(() => toastr.success(`Usuario ${name ? name: ''} eliminado`), 300)
    }

    render() {    
        const { Users, user, Roles, Status, sessionUser } = this.state;
        return (
            <>
                <AdminHeader {...this.props}/>
                <Container className="mt--7" fluid> 
                    <div>
                        <Row>
                            <div className="col">
                                <Card className="shadow">
                                    <CardHeader className="border-0">
                                        <Row>
                                            <div className="col-6">
                                                <h3 className="mb-0 d-inline">Usuarios</h3>
                                            </div>
                                            <div className="col-6">
                                                <Button type="button" onClick={() => this.addUser()} style={{ padding: '0.3rem 0.7rem' }} className="float-right btn btn-outline-success btn-circle">
                                                    <i className="fa fa-plus"></i>
                                                </Button>
                                            </div>
                                        </Row>
                                    </CardHeader>
                                    {
                                        this.state.loading ? 
                                        <div className='spinner-table'><CircleSpinner
                                                size={30}
                                                color="#11cdef"
                                                loading={this.state.loading}
                                            /></div>
                                        :
                                        <Table className="align-items-center table-flush" responsive>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th className='itemCenter' scope="col">Nombre</th>
                                                    <th className='itemCenter' scope="col">Email</th>
                                                    <th className='itemCenter' scope="col">T&eacute;lefono</th>
                                                    <th className='itemCenter' scope="col">Usuario</th>
                                                    {/* <th className='itemCenter' scope="col">Estado</th> */}
                                                    <th className='itemCenter' scope="col">Rol</th>
                                                    <th className='itemCenter' scope="col" />
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    Users ? 
                                                    Users.map((item, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td className='itemCenter'>{item.name ? item.name: <p>-</p>}</td>
                                                                <td className='itemCenter'>{item.email ? item.email: <p>-</p>}</td>
                                                                <td className='itemCenter'>{item.phone ? item.phone: <p>-</p>}</td>
                                                                <td className='itemCenter'>{item.username ? item.username: <p>-</p>}</td>
                                                                {/* <td className='itemCenter'>{item.status ? item.status: <p>-</p>}</td> */}
                                                                <td className='itemCenter'>{item.role ? item.role: <p>-</p>}</td>
                                                                <td className="text-right">
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle
                                                                            className="btn-icon-only text-light"
                                                                            href="#"
                                                                            role="button"
                                                                            size="sm"
                                                                            color=""
                                                                            onClick={e => e.preventDefault()}>
                                                                            <i className="fas fa-ellipsis-v" />
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu-arrow" right>
                                                                            <DropdownItem
                                                                                onClick={() => this.editUser(item)}
                                                                            >
                                                                                <i className="fas fa-edit" />
                                                                                Editar
                                                                            </DropdownItem>
                                                                            <DropdownItem
                                                                                onClick={() => this.changePassword(item)}
                                                                            >
                                                                                <i className="fas fa-key" />
                                                                                Cambiar password
                                                                            </DropdownItem>
                                                                            { 
                                                                                sessionUser.id_user === item.id_user ? null :
                                                                                <DropdownItem
                                                                                    onClick={() => this.deleteUser(item)}
                                                                                >
                                                                                    <i className="fas fa-trash" />
                                                                                    Eliminar
                                                                                </DropdownItem>
                                                                            }
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </Table>
                                    }
                                    </Card>
                                </div>
                            </Row>
                    </div>
                </Container>
                <div>
                {/* MODAL NO DATA */}
                <Modal isOpen={this.state.modalNoData} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>NO DATA</ModalHeader>
                    <ModalBody>
                        No hay usuarios m&oacute;viles registrados
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                    </ModalFooter>
                </Modal>
                </div>
                <div>
                {/* MODAL NEW */}
                <Modal isOpen={this.state.modalNew} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>Agregar Nuevo Usuario</ModalHeader>
                    <Form onSubmit={this.submitAddUser}>
                    <ModalBody>
                        <FormGroup row>
                            <Label for="name" sm={4}>Nombre Usuario</Label>
                            <Col sm={8}>
                            <Input 
                                type="text"
                                name="name"
                                id="name" 
                                placeholder="Ingresa el nombre del usuario"
                                onChange={this.handleAddUser}
                                value={this.state.name}
                                required />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="email" sm={4}>Email Usuario</Label>
                            <Col sm={8}>
                            <Input 
                                type="email"
                                name="email"
                                id="email" 
                                placeholder="Ingresa el email del usuario"
                                onChange={this.handleAddUser}
                                value={this.state.email}
                                required />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="phone" sm={4}>T&eacute;lefono Usuario</Label>
                            <Col sm={8}>
                            <Input 
                                type="text"
                                name="phone"
                                id="phone" 
                                placeholder="Ingresa el telefono del usuario"
                                onChange={this.handleAddUser}
                                value={this.state.phone}
                                required />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="name" sm={4}>Alias Usuario</Label>
                            <Col sm={8}>
                            <Input 
                                type="text"
                                name="username"
                                id="username" 
                                placeholder="Ingresa el alias del usuario"
                                onChange={this.handleAddUser}
                                value={this.state.username}
                                required />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="name" sm={4}>Rol Usuario</Label>
                            <Col sm={8}>
                            <Input 
                                type="select"
                                name="id_role"
                                id="id_role"
                                onChange={this.handleAddUser}
                                value={this.state.id_role}>
                                {
                                    Roles === [] ? <option>1</option> :
                                    Roles.map((item, key) => {
                                        return(
                                            <option key={key} value={item.id_role}>{item.role}</option>
                                        )
                                    })
                                }
                            </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="name" sm={4}>Password Usuario</Label>
                            <Col sm={7}>
                            <Input 
                                type={this.state.hide ? 'password' : 'text'}
                                name="password"
                                id="password" 
                                placeholder="Ingresa el password del usuario"
                                minLength='8'
                                onChange={this.handleAddUser}
                                value={this.state.password}
                                required />
                            </Col>
                            <span className={this.state.hide ? "fas fa-eye" : 'fas fa-eye-slash'}
                                onClick={() => this.handleClickPass()}></span>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="name" sm={4}>Confirmar Password</Label>
                            <Col sm={7}>
                            <Input 
                                type={this.state.hide ? 'password' : 'text'}
                                name="password2"
                                id="password2" 
                                placeholder="Confirme el password del usuario"
                                onChange={this.handleAddUser}
                                value={this.state.password2}
                                required />
                            </Col>
                            <span className={this.state.hide ? "fas fa-eye" : 'fas fa-eye-slash'}
                                onClick={() => this.handleClickPass()}></span>
                        </FormGroup> 
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                        <Button color="success" type="submit" disabled={this.state.submit}>Crear</Button>
                    </ModalFooter>
                    </Form>
                </Modal>
                </div>
                <div>
                {/* MODAL CHANGE PASSWORD */}
                <Modal isOpen={this.state.modalChangePassword} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>Cambiar Password {user ? user.name : ''}</ModalHeader>
                    <Form onSubmit={this.submitChangePasswordUser}>
                    <ModalBody>
                        <FormGroup row>
                            <Label for="name" sm={4}>Nuevo Password</Label>
                            <Col sm={7}>
                            <Input 
                                type={this.state.hide ? 'password' : 'text'}
                                name="password"
                                id="password" 
                                placeholder="Ingresa el password del usuario"
                                minLength='8'
                                onChange={this.handleUpdateUser}
                                value={this.state.password}
                                required />
                            </Col>
                            <span className={this.state.hide ? "fas fa-eye" : 'fas fa-eye-slash'}
                                onClick={() => this.handleClickPass()}></span>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="name" sm={4}>Confirmar Password</Label>
                            <Col sm={7}>
                            <Input 
                                type={this.state.hide ? 'password' : 'text'}
                                name="password2"
                                id="password2" 
                                placeholder="Confirme el password del usuario"
                                onChange={this.handleUpdateUser}
                                value={this.state.password2}
                                required />
                            </Col>
                            <span className={this.state.hide ? "fas fa-eye" : 'fas fa-eye-slash'}
                                onClick={() => this.handleClickPass()}></span>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                        <Button color="success" type="submit">Cambiar</Button>
                    </ModalFooter>
                    </Form>
                </Modal>
                </div>
                <div>
                {/* MODAL UPDATE USER */}
                <Modal isOpen={this.state.modalEdit} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>Editar usuario {user ? user.name : ''}</ModalHeader>
                    <Form onSubmit={this.submitUpdateUser}>
                    <ModalBody>
                        <FormGroup row>
                            <Label for="name" sm={4}>Nombre Usuario</Label>
                            <Col sm={8}>
                            <Input 
                                type="text"
                                name="name"
                                id="name" 
                                placeholder="Ingresa el nombre del usuario"
                                onChange={this.handleAddUser}
                                value={this.state.name}
                                required />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="email" sm={4}>Email Usuario</Label>
                            <Col sm={8}>
                            <Input 
                                type="text"
                                name="email"
                                id="email" 
                                placeholder="Ingresa el email del usuario"
                                onChange={this.handleAddUser}
                                value={this.state.email}
                                required />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="phone" sm={4}>T&eacute;lefono Usuario</Label>
                            <Col sm={8}>
                            <Input 
                                type="text"
                                name="phone"
                                id="phone" 
                                placeholder="Ingresa el telefono del usuario"
                                onChange={this.handleAddUser}
                                value={this.state.phone}
                                required />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="name" sm={4}>Alias Usuario</Label>
                            <Col sm={8}>
                            <Input 
                                type="text"
                                name="username"
                                id="username" 
                                placeholder="Ingresa el alis del usuario"
                                onChange={this.handleAddUser}
                                value={this.state.username}
                                required />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="name" sm={4}>Rol Usuario</Label>
                            <Col sm={8}>
                            <Input 
                                type="select"
                                name="id_role"
                                id="id_role"
                                onChange={this.handleAddUser}
                                value={this.state.id_role}>
                                {
                                    Roles === [] ? <option>1</option> :
                                    Roles.map((item, key) => {
                                        return(
                                            <option key={key} value={item.id_role}>{item.role}</option>
                                        )
                                    })
                                }
                            </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="name" sm={4}>Estado Usuario</Label>
                            <Col sm={8}>
                            <Input 
                                type="select"
                                name="id_status"
                                id="id_status"
                                onChange={this.handleAddUser}
                                value={this.state.id_status}>
                                {
                                    Status === [] ? <option>1</option> :
                                    Status.map((item, key) => {
                                        return(
                                            <option key={key} value={item.id_status}>{item.status}</option>
                                        )
                                    })
                                }
                            </Input>
                            </Col>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                        <Button color="success" type="submit">Actualizar</Button>
                    </ModalFooter>
                    </Form>
                </Modal>
                </div>
                <div>
                {/* MODAL DELETE */}
                <Modal isOpen={this.state.modalDelete} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>
                        Eliminar {user ? user.name : 'Usuario'}
                    </ModalHeader>
                    <ModalBody>
                        ¿Deseas eliminar este usuario?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                        <Button color="danger" onClick={this.submitDeleteUsuarioMobile}>Eliminar</Button>
                    </ModalFooter>
                </Modal>
                </div>
            </>
        );
    }
}

export default Users;