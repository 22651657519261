import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import { searchSparePart } from './../../../api/spareParts'

let Spareparts = []
let choosenValue = {};

function escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function getSuggestions(value) {
    const escapedValue = escapeRegexCharacters(value.trim());
    if (escapedValue === '') {
        return [];
    }
    const regex = new RegExp('^' + escapedValue, 'i');
    return Spareparts.filter(item => regex.test(item.spare_part));
}

function renderSuggestion(suggestion) {
    return (
        <span>{suggestion.spare_part}</span>
    );
}

class SearchSparePart extends Component {
    constructor() {
        super();

        this.state = {
            value: '',
            suggestions: []
        };
        this.getSuggestionValue = this.getSuggestionValue.bind(this)
    }

    getSuggestionValue(suggestion) {
        choosenValue = suggestion;
        this.props.parentCallback({ choosenValue, idx: this.props.inputProps})
        return suggestion.spare_part;
    }

    onChange = (event, { newValue, method }) => {
        this.setState({
            value: newValue
        });
    };

    onSuggestionsFetchRequested = async ({ value }) => {
        let res = await searchSparePart(value)
        Spareparts = res.response.Spareparts;
        this.setState({
            suggestions: getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    render() {
        const { value, suggestions } = this.state;
        const inputProps = {
            placeholder: "Busca los respuestos",
            value,
            onChange: this.onChange
        };

        return (
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps} 
                highlightFirstSuggestion={true}/>
        );
    }
}

export { SearchSparePart };