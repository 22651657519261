import Cookies from 'universal-cookie';
const cookies = new Cookies();

var auth = ( () =>  {
    // SET TOKEN
    function setToken(token) {
        return cookies.set('token',token, { path: '/', expires: new Date(Date.now()+259200000)} );
    };
    // GET TOKEN
    function getToken() {
        return cookies.get('token');
    }
    // REMOVE TOKEN
    function removeToken() {
        return cookies.remove('token')
    }
    // SET USER
    function setUser(user) {
        return cookies.set('user', user, { path: '/', expires: new Date(Date.now()+259200000)});
    }
    // GET USER
    function getUser() {
        return cookies.get('user');
    }
    // REMOVE USER
    function removeUser() {
        return cookies.remove('user', { path: '/'})
    }
    function getAuthToken() {
        return JSON.parse(localStorage.getItem('Token'))
    };

    return {
        getAuthToken,
        setToken,
        getToken,
        removeToken,
        setUser,
        getUser,
        removeUser
    };
})();

export default auth;