import React from "react";

// reactstrap components
import { Container } from "reactstrap";
 
class AdminHeader extends React.Component {
    render() {
        return (
            <>
                <div className="header bg-gradient-success pb-6 pt-5 pt-md-8">
                    <Container fluid>
                    </Container>
                </div>
            </>
        );
    }
}

export default AdminHeader;