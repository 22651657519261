// import { envDash, envLogin } from './../environment';
// import { logoutUser } from './LoginApi';
import auth from './auth'

// const headerLogin = envLogin;
// const headerDash  = envDash;

export const parseResponse = (mode) => async (response) => {  
  if (response.status === 204 || response.status === 401) {
    auth.removeUser();
    auth.removeToken();
    window.location.reload();
    alert('NO AUTORIZADO')
    return response
  }
  if (response.status >= 200 && response.status <= 500) {
    return mode === 'blob' ? response.blob() : response.json();
  }
  const error = new Error(response.statusText);
  throw error;
};

export const parseResponseCos = (mode) => (response) => {
  return mode === 'blob' ? response.blob() : response.json();
};

export const appFetchLogin = (options) =>
  fetch(options.url, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: options.body,
    method: options.method
  })
    .then(parseResponse('json'))
    .then((response) => ({ response }))
    .catch((error) => {
      return error;
    });

export const appFetch = (options) =>
    fetch(options.url, {
        headers: {
            'dash-authorization': options.auth,
            'Content-Type': 'application/json'
        },
        body: options.body,
        method: options.method
    })
        .then(parseResponse('json'))
        .then((response) => ({response }))
        .catch(error => {
            return error;
        });
