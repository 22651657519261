import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch  } from 'react-router-dom';
import  { PrivateRoute } from './helpers/PrivateRoute';

import './assets/vendor/nucleo/css/nucleo.css';
import './assets/vendor/@fortawesome/fontawesome-free/css/all.min.css';
import './assets/scss/argon-dashboard-react.scss';

// Import Containers
import LoginContainer from './container/loginContainer.jsx';
import AdminContainer from './container/AdminContainer.jsx';


class App extends Component {
  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route exact path='/' render={props => <LoginContainer {...props }/>} />
            <PrivateRoute path='/admin' component={AdminContainer}/>} />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
