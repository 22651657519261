import React, { Component } from 'react';
import { Card, CardHeader, Container, Row,  Button, Table,
    DropdownMenu,DropdownItem, UncontrolledDropdown, DropdownToggle,
    Modal, ModalBody, ModalHeader, ModalFooter, 
    Form, Label, FormGroup, Input, Col
} from "reactstrap";
import AdminHeader from "./../../Shared/Headers/AdminHeader";
import './../../../assets/css/dashboard/dashboard.css';
import { CircleSpinner } from 'react-spinners-kit';
import { getServiceCenter, createServiceCenter, updateServiceCenter } from './../../../api/serviceCenters.js';
import { getUsers } from './../../../api/users';

// TOAST
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

const toastOptions = {
    positionClass: 'toast-top-center',
    hideDuration: 300,
    timeOut: 3000,
    progressBar: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
}

class ServiceCenters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            submit: false,
            ServiceCenters: null,
            user: null,

            modalNoData: false,
            modalNew: false,
            modalEdit:false,
            modalDelete: false,

            name: '',
            phone: '',
            address:'',
            id_user: '',
            id: '',


            Users: []
        };

        this.closeModal = this.closeModal.bind(this);

        this.addNewItem = this.addNewItem.bind(this);
        this.handleAddItem = this.handleAddItem.bind(this);
        this.submitAddItem = this.submitAddItem.bind(this);
        this.NewItem = this.NewItem.bind(this);

        this.editItem = this.editItem.bind(this);
        this.submitUpdateData = this.submitUpdateData.bind(this);
        this.handleUpdateItem = this.handleUpdateItem.bind(this);

        this.deleteItem = this.deleteItem.bind(this);
        this.submitDeleteData = this.submitDeleteData.bind(this);
        this.deleteItemData = this.deleteItemData.bind(this);
    }

    async fetchData() {
        const resultUsers = await getUsers()
        if(resultUsers.response.status === 200 ) {
            this.setState({ Users: resultUsers.response.Users,
            id_user: resultUsers.response.Users[0].id_user})
        }
        const result = await getServiceCenter();
        if(result.response.status === 500) {
            alert('Error en el servidor');
            this.setState({ ServiceCenters: [], loading: false})
        }
        if(result.response.status === 404) {
            this.setState({ ServiceCenters: result.response.Services_center, loading: false, modalNoData: true})
        }
        else {
            let modalNoData = false;
            let length = result.response.Services_center ? result.response.Services_center.length : 0;
            if(length === 0) modalNoData = true;
            this.setState({ ServiceCenters: result.response.Services_center, loading: false, modalNoData: modalNoData})
        }        
    }
    
    componentDidMount() {
        this.fetchData();  
    }

    closeModal() {
        this.setState({
            modalNoData: false,
            modalNew: false,
            modalEdit:false,
            modalDelete: false,
            submit: false,
            
            id: '',
            name: '',
            phone: '',
            address: ''
        })
    }

    addNewItem() {
        this.setState({modalNew: true})
    }

    handleAddItem(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    submitAddItem(e) {
        e.preventDefault();
        this.setState({ submit: true})
        let data = {
            name: this.state.name,
            phone: this.state.phone,
            address: this.state.address,
            // id_user: this.state.id_user ? this.state.id_user : '5b35df80-6e14-11ea-84e5-ddc10e87329d',
        }
        createServiceCenter(data)
        .then( res => {
            if(res.response.status !== 200) {
                alert(res.response.message);
            }
            else {
                this.NewItem(res.response.service_center);
                this.toastCreateItem(res.response.service_center.name);
                this.closeModal();
            }
        })
        .catch( err =>{
            alert(err)
        });
    }

    toastCreateItem() {
        toastr.options = toastOptions;
        toastr.clear()
        setTimeout(() => toastr.success(`centro de servicio creada`), 300)
    }

    async NewItem(item) {
        if(!this.state.ServiceCenters) this.state.ServiceCenters = [];
        let newItem = this.appendObjTo(this.state.ServiceCenters, item);
        this.setState({ ServiceCenters: newItem })
    }

    editItem(item) {
        this.setState({modalEdit: true, 
            id: item.id_service_center, 
            name: item.name,
            phone: item.phone,
            address: item.address,
            id_user: item.id_user
        })
    }

    handleUpdateItem(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    submitUpdateData(e) {
        e.preventDefault();
        this.setState({ submit: true })
        const { id, name, address, phone } = this.state;  
        // let data = { name, address, phone, id_user } // Sin id_user
        let data = { name, address, phone }
        updateServiceCenter(id, data)
            .then( res => {
                if(res.response.status !== 200) {
                    alert(res.response.message);
                }
                else {
                    this.toastUpdateItem(res.response.service_center.name)
                    this.updateItemData(res.response.service_center);
                    this.closeModal();
                }
            })
            .catch( err =>{
                alert(err)
            });
    }

    toastUpdateItem(name) {
        toastr.options = toastOptions;
        toastr.clear()
        setTimeout(() => toastr.success(`Centro de servicio ${name ? name: ''} actualizado`), 300)
    }

    async updateItemData(data) {
        try {
            var id = data.id_service_center;
            let { ServiceCenters } = this.state; 
            let index = ServiceCenters.findIndex(item => item.id_service_center === id);
            if(index !== -1){
                ServiceCenters[index].name = data.name;
                ServiceCenters[index].phone = data.phone;
                ServiceCenters[index].address = data.address;
                // ServiceCenters[index].id_user = data.id_user;
                // ServiceCenters[index].user = data.user;
                this.setState({ ServiceCenters })
            }
            else this.setState({ ServiceCenters })
        } catch (error) {
            window.location.reload();
        }
        
    }

    deleteItem(item) {
        this.setState({modalDelete: true, item})
    }

    submitDeleteData(e) {
        e.preventDefault();
        this.setState({submit: true})
        const { item } = this.state;  
        updateServiceCenter(item.id_service_center, {id_status: 2})
            .then( res => {
                if(res.response.status !== 200) {
                    alert(res.response.message);
                }
                else {
                    this.toastDeleteItem();
                    this.deleteItemData(res.response.service_center);
                    this.closeModal();
                }
            })
            .catch( err =>{
                alert(err)
            });
    }

    async deleteItemData(data) {
        var id = data.id_service_center;      
        let { ServiceCenters } = this.state;
        try {
            let index = ServiceCenters.findIndex(item => item.id_service_center === id);
            if(index !== -1) ServiceCenters.splice(index, 1);
            else this.setState({ ServiceCenters })
        }
        catch {
            window.location.reload();
        }
    }

    toastDeleteItem(name) {
        toastr.options = toastOptions;
        toastr.clear()
        setTimeout(() => toastr.success(`Centro de servicio ${name ? name: ''} eliminado`), 300)
    }

    appendObjTo = (thatArray, newObj) => {
        const frozenObj = Object.freeze(newObj);
        return Object.freeze(thatArray.concat(frozenObj));
    }

    render() {
        const { ServiceCenters } = this.state;
        return (
            <>
                <AdminHeader {...this.props}/>
                <Container className="mt--7" fluid> 
                    <div>
                        <Row>
                            <div className="col">
                                <Card className="shadow">
                                    <CardHeader className="border-0">
                                        <Row>
                                            <div className="col-6">
                                                <h3 className="mb-0 d-inline">Centros de servicios</h3>
                                            </div>
                                            <div className="col-6">
                                                <Button type="button" onClick={() => this.addNewItem()} style={{ padding: '0.3rem 0.7rem' }} className="float-right btn btn-outline-success btn-circle">
                                                    <i className="fa fa-plus"></i>
                                                </Button>
                                            </div>
                                        </Row>
                                    </CardHeader>
                                    {
                                        this.state.loading ? 
                                        <div className='spinner-table'><CircleSpinner
                                                size={30}
                                                color="#11cdef"
                                                loading={this.state.loading}
                                            /></div>
                                        :
                                        <Table className="align-items-center table-flush" responsive>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th scope="col" className='itemCenter'>centro de servicio</th>
                                                    <th scope="col" className='itemCenter'>Tel&eacute;fono</th>
                                                    <th scope="col" className='itemCenter'>Direcci&oacute;n</th>
                                                    {/* <th scope="col" className='itemCenter'>Usuario</th> */}
                                                    {/* <th scope="col" className='itemCenter'>Estado</th> */}
                                                    <th scope="col"  />
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    ServiceCenters ? 
                                                    ServiceCenters.map((item, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td className='itemCenter'>{item.name ? item.name: <p>-</p>}</td>
                                                                <td className='itemCenter'>{item.phone ? item.phone: <p>-</p>}</td>
                                                                <td className='itemCenter'>{item.address ? item.address: <p>-</p>}</td>
                                                                {/* <td className='itemCenter'>{item.user ? item.user: <p>-</p>}</td> */}
                                                                {/* <td className='itemCenter'>{item.status ? item.status: <p>-</p>}</td> */}
                                                                <td className="text-right">
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle
                                                                            className="btn-icon-only text-light"
                                                                            href="#"
                                                                            role="button"
                                                                            size="sm"
                                                                            color=""
                                                                            onClick={e => e.preventDefault()}>
                                                                            <i className="fas fa-ellipsis-v" />
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu-arrow" right>
                                                                            <DropdownItem
                                                                                onClick={() => this.editItem(item)}
                                                                            >
                                                                                <i className="fas fa-edit" />
                                                                                Editar
                                                                            </DropdownItem>
                                                                            <DropdownItem
                                                                                onClick={() => this.deleteItem(item)}
                                                                            >
                                                                                <i className="fas fa-trash" />
                                                                                Eliminar
                                                                            </DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </Table>
                                    }
                                    </Card>
                                </div>
                            </Row>
                    </div>
                </Container>

                {/* MODAL NO DATA */}
                <div>
                <Modal isOpen={this.state.modalNoData} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>NO DATA</ModalHeader>
                    <ModalBody>
                        No hay centro de servicios registrados
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                    </ModalFooter>
                </Modal>
                </div>

                {/* MODAL NEW */}
                <div>
                <Modal isOpen={this.state.modalNew} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>Agregar una nueva centro de servicio</ModalHeader>
                    <Form onSubmit={this.submitAddItem}>
                    <ModalBody>
                        <FormGroup row>
                            <Label for="name" sm={4}>Nombre del centro de servicio</Label>
                            <Col sm={8}>
                            <Input 
                                type="text"
                                name="name"
                                id="name" 
                                placeholder="Ingresa el nombre del centro de servicio"
                                onChange={this.handleAddItem}
                                value={this.state.name}
                                required />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="name" sm={4}>Dirección del centro de servicio</Label>
                            <Col sm={8}>
                            <Input 
                                type="text"
                                name="address"
                                id="address" 
                                placeholder="Ingresa la dirección del centro de servicio"
                                onChange={this.handleAddItem}
                                value={this.state.address}
                                required />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="phone" sm={4}>Teléfono del centro de servicio</Label>
                            <Col sm={8}>
                            <Input 
                                type="text"
                                name="phone"
                                id="phone" 
                                placeholder="Ingresa el teléfono del centro de servicio"
                                onChange={this.handleAddItem}
                                value={this.state.phone}
                                maxLength={12}
                                required />
                            </Col>
                        </FormGroup>
                        {/* <FormGroup row>
                            <Label for="id_model" sm={4}>Usuario</Label>
                            <Col sm={8}>
                                <Input
                                    type="select"
                                    name="id_user"
                                    id="id_user"
                                    onChange={this.handleAddItem}
                                    value={this.state.id_user}>
                                    {
                                        Users === [] ? <option>1</option> :
                                            Users.map((item, key) => {
                                                return (
                                                    <option key={key} value={item.id_user}>{item.name}</option>
                                                )
                                            })
                                    }
                                </Input>
                            </Col>
                        </FormGroup> */}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                        <Button color="success" type="submit" disabled={this.state.submit} >Crear</Button>
                    </ModalFooter>
                    </Form>
                </Modal>
                </div>

                {/* MODAL UPDATE ITEM */}
                <div>
                <Modal isOpen={this.state.modalEdit} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>
                        Editar centro de servicio {this.state.item ? this.state.item.name : ''}
                    </ModalHeader>
                    <Form onSubmit={this.submitUpdateData}>
                    <ModalBody>
                    <FormGroup row>
                            <Label for="name" sm={4}>Nombre del centro de servicio</Label>
                            <Col sm={8}>
                            <Input 
                                type="text"
                                name="name"
                                id="name" 
                                placeholder="Ingresa el nombre del centro de servicio"
                                onChange={this.handleAddItem}
                                value={this.state.name}
                                required />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="name" sm={4}>Dirección del centro de servicio</Label>
                            <Col sm={8}>
                            <Input 
                                type="text"
                                name="address"
                                id="address" 
                                placeholder="Ingresa la dirección del centro de servicio"
                                onChange={this.handleAddItem}
                                value={this.state.address}
                                required />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="phone" sm={4}>Teléfono del centro de servicio</Label>
                            <Col sm={8}>
                            <Input 
                                type="text"
                                name="phone"
                                id="phone" 
                                placeholder="Ingresa el teléfono del centro de servicio"
                                onChange={this.handleAddItem}
                                value={this.state.phone}
                                maxLength={12}
                                required />
                            </Col>
                        </FormGroup>
                        {/* <FormGroup row>
                            <Label for="id_model" sm={4}>Usuario</Label>
                            <Col sm={8}>
                                <Input
                                    type="select"
                                    name="id_user"
                                    id="id_user"
                                    onChange={this.handleAddItem}
                                    value={this.state.id_user}>
                                    {
                                        Users === [] ? <option>1</option> :
                                            Users.map((item, key) => {
                                                return (
                                                    <option key={key} value={item.id_user}>{item.name}</option>
                                                )
                                            })
                                    }
                                </Input>
                            </Col>
                        </FormGroup> */}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                        <Button color="success" type="submit" disabled={this.state.submit}>Actualizar</Button>
                    </ModalFooter>
                    </Form>
                </Modal>
                </div>

                <div>
                {/* MODAL DELETE */}
                <Modal isOpen={this.state.modalDelete} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>
                        Eliminar {this.state.item ? this.state.item.name : 'centro de servicio'}
                    </ModalHeader>
                    <ModalBody>
                        ¿Deseas eliminar este centro de servicio?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                        <Button color="danger" onClick={this.submitDeleteData} disabled={this.state.submit}>Eliminar</Button>
                    </ModalFooter>
                </Modal>
                </div>
            </>
        );
    }
}

export default ServiceCenters;