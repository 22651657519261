import React, { Component } from 'react';
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
import { Route, withRouter} from 'react-router-dom';
import { Container, Row, Button, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import LoginNavbar from '../components/Login/LoginNavbar.jsx';
import LoginForm from '../components/Login/LoginForm.jsx';
import { loginUser } from '../api/login';
import { set_user } from '../actions/user';
// Import Login css
import '../assets/css/login/login.css';
import auth from './../api/auth';

class LoginContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
          modal: false,
        };  
        this.toggle = this.toggle.bind(this);
      }

    componentDidMount() {
        document.body.classList.add("bg-default");
        document.title = "Login ICEE";
        if(auth.getUser('user')) {
            let user = auth.getUser();
            this.props.history.push(`/admin/`, {user})
        }
    }
    componentWillUnmount() {
        document.body.classList.remove("bg-default");
    }

    handleSubmit = async values => {
        await loginUser(values.username, values.password)
        .then( response => {
            this.props.set_user(response.response);
        })
        .catch( (err) => {
            alert(err);
        })
    }

    handleSubmitSuccess = async () => {
        let user = await this.props.user;
        if(!user) {
            alert('El servidor presenta fallos en este momento')
            return;
        }
        if(user.status === 404 ){
            this.setState({
                modal: true
            })
        }
        else if(user.status === 200) {
            if(user.user.id_role === 4) {
                alert('Este usuario no tiene privilegios de acceso');
                return;
            }
            // Set info in the cookies
            auth.setUser(user.user);
            auth.setToken(user['dash-auth']);
            this.props.history.push(`/admin/`, {user});
        }
        else {
            alert('Error en el servidor')
        }
    }

    toggle() {
        this.setState(prevState => ({
          modal: !prevState.modal
        }));
    }
    
    render() {
        return (
            <>
                <div className="main-content">
                    <LoginNavbar />
                    <div className="header bg-gradient-success1 py-7 py-lg-8">
                        <Container>
                            <div className="header-body text-center mb-7">
                                <Row className="justify-content-center">
                                    <Col lg="5" md="6">
                                        <h1 className="text-white title-login">¡Bienvenido a ICEE!</h1>
                                        <p className="text-login-container text-lead text-light">
                                            Ingresa tu usuario y contrase&ntilde;a para poder ingresar a la plataforma
                                            administrativa de ICEE
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                        <div className="separator separator-bottom separator-skew zindex-100">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                preserveAspectRatio="none"
                                version="1.1"
                                viewBox="0 0 2560 100"
                                x="0"
                                y="0"
                            >
                                <polygon
                                    className="fill-default"
                                    points="2560 0 2560 100 0 100"
                                />
                            </svg>
                        </div>
                    </div>
                    <Container className="login-container">
                        <Row className="justify-content-center">
                            <Route render={(props) => 
                                <LoginForm {...props} 
                                    onSubmit={this.handleSubmit} 
                                    onSubmitSuccess={this.handleSubmitSuccess}/>}>
                            </Route>
                        </Row>
                    </Container>
                </div>
                <div>
                    <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                        <ModalHeader toggle={this.toggle} >Credenciales erroneas</ModalHeader>
                            <ModalBody>Ha ingresado un usuario &oacute; contrase&ntilde;a de forma incorrecta</ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={this.toggle}>Cerrar</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {user: state.user.user}
}

export default withRouter(connect(mapStateToProps, {set_user})(LoginContainer));