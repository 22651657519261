import React, { Component } from 'react';
import { Card, CardHeader, Container, Row,  Button, Table,
    DropdownMenu,DropdownItem, UncontrolledDropdown, DropdownToggle,
    Modal, ModalBody, ModalHeader, ModalFooter, ListGroup, ListGroupItem,
    Form, Label, FormGroup, Input, Col
} from "reactstrap";
import AdminHeader from "../../Shared/Headers/AdminHeader";
import { MultiSelect } from './../../Shared/UI_Elements/Multiselect';
import { CircleSpinner } from 'react-spinners-kit';
import { getFixes, createFix, updateFix, getInfoFixes } from '../../../api/fixes';
import { getModels } from './../../../api/models';

// TOAST
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

const toastOptions = {
    positionClass: 'toast-top-center',
    hideDuration: 300,
    timeOut: 3000,
    progressBar: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
}


class Fixes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            submit: false,
            Fixes: [],

            Fixes_types: [],

            item: null,

            modalNoData: false,
            modalNew: false,
            modalEdit:false,
            modalDelete: false,
            modalCompatible: false,

            modalModels: false,
            Models: [],

            newModels: [],
            oldModels: [],

            fix: '',
            description: '',
            id: '',
            id_fix_type: 1
        };

        this.closeModal = this.closeModal.bind(this);
        this.closeModalCompatibility = this.closeModalCompatibility.bind(this);
        this.seeModels = this.seeModels.bind(this);
        
        this.addNewItem = this.addNewItem.bind(this);
        this.handleAddItem = this.handleAddItem.bind(this);
        this.submitAddItem = this.submitAddItem.bind(this);
        this.NewItem = this.NewItem.bind(this);

        this.editItem = this.editItem.bind(this);
        this.submitUpdateData = this.submitUpdateData.bind(this);
        this.handleUpdateItem = this.handleUpdateItem.bind(this);

        this.deleteItem = this.deleteItem.bind(this);
        this.submitDeleteData = this.submitDeleteData.bind(this);
        this.goModels = this.goModels.bind(this);
        this.callbackFunction = this.callbackFunction.bind(this);
    }

    async fetchData() {
        const modelsResult = await getModels()
        if(modelsResult.response.status === 200 ) {
            if( modelsResult.response.Models.length <= 0) {
                this.setState({ modalModels: true, loading: false})
                return;
            }

        }
        const requestFixesTypes = await getInfoFixes();
        if(requestFixesTypes.response.status === 200) {
            this.setState({ Fixes_types: requestFixesTypes.response.Fixes_types})
        }
        const result = await getFixes();
        if(result.response.status === 500) {
            alert('Error en el servidor');
            this.setState({ Fixes: [], loading: false})
        }
        if(result.response.status === 404) {
            this.setState({ Fixes: result.response.Fixes, loading: false, modalNoData: true})
        }
        else {
            let modalNoData = false;
            let length = result.response.Fixes ? result.response.Fixes.length : 0;
            if(length === 0) modalNoData = true;
            this.setState({ 
                Fixes: result.response.Fixes, 
                loading: false, modalNoData: modalNoData,
                Models: modelsResult.response.Models,
                modalModels: false
            })
        }        
    }
    
    componentDidMount() {
        this.fetchData();  
    }

    closeModal() {
        this.setState({
            modalNoData: false,
            modalNew: false,
            modalEdit:false,
            modalDelete: false,
            submit: false,

            item: null,

            fix: '',
            description: '',
            id_fix_type: 1,
            id: '',
            hide: true,

            newModels: [],
        })
    }

    closeModalCompatibility() {
        this.setState({ modalCompatible: false})
    }

    goModels() {
        this.props.history.push('/admin/models');  
    }

    addNewItem() {
        this.setState({modalNew: true})
    }

    seeModels(item) {
        this.setState({ modalCompatible: true, item:item})
    }

    handleAddItem(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    submitAddItem(e) {
        e.preventDefault();
        let data = {
            fix: this.state.fix,
            description: this.state.description,
            id_fix_type: this.state.id_fix_type,
            models: this.state.newModels,
        }
        if(data.models.length <= 0) {
            alert('Agrega modelos compatibles')
            return;
        }
        this.setState({ submit: true})
        createFix(data)
        .then( res => {
            if(res.response.status !== 200) {
                alert(res.response.message);
            }
            else {
                this.NewItem(res.response.fix);
                this.toastCreateItem();
                this.closeModal();
            }
        })
        .catch( err =>{
            alert(err)
        });
    }

    toastCreateItem() {
        toastr.options = toastOptions;
        toastr.clear()
        setTimeout(() => toastr.success(`Solución creada`), 300)
    }

    async NewItem(item) {
        if(!this.state.Fixes) this.state.Fixes = [];
        let newItem = this.appendObjTo(this.state.Fixes, item);
        this.setState({ Fixes: newItem })
    }

    editItem(item) {
        this.setState({modalEdit: true, id: item.id_fix, 
            fix: item.fix, description: item.description, id_fix_type: item.id_fix_type,
            oldModels: item.models
        })
    }

    handleUpdateItem(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    submitUpdateData(e) {
        e.preventDefault();
        this.setState({ submit: true})
        const { id, description, fix, id_fix_type, newModels } = this.state; 
        let data = { fix, description, id, id_fix_type, models: newModels }
        updateFix(id, data)
            .then( res => {
                if(res.response.status !== 200) {
                    alert(res.response.message);
                    this.setState({ submit: false})
                }
                else {               
                    this.toastUpdateFix()
                    this.updateFixData(res.response.fix);
                    this.closeModal();
                }
            })
            .catch( err =>{
                alert(err)
            });
    }

    toastUpdateFix() {
        toastr.options = toastOptions;
        toastr.clear()
        setTimeout(() => toastr.success(`Solución actualizada`), 300)
    }

    async updateFixData(data) {
        var id = data.id_fix;
        let { Fixes } = this.state; 
        let index = parseInt(Fixes.findIndex(item => item.id_fix === id));
        if(index !== -1){
            Fixes[index].fix = data.fix;
            Fixes[index].description = data.description;
            Fixes[index].type = data.type;
            Fixes[index].id_fix_type = data.id_fix_type;
            Fixes[index].models = data.models;
            this.setState({ Fixes })
        }
        else{
            this.setState({ Fixes })
        }
    }

    deleteItem(item) {
        this.setState({modalDelete: true, item})
    }

    submitDeleteData(e) {
        e.preventDefault();
        this.setState({ submit: true})
        const { item } = this.state;
        let data = {
            id_status: 2
        }
        updateFix(item.id_fix, data)
        .then( res => {
            if(res.response.status !== 200) {
                alert(res.response.message);
            }
            else {
                this.toastDeleteItem();
                this.deleteItemData(res.response.fix);
                this.closeModal();
            }
        })
        .catch( err =>{
            alert(err)
        });
    }

    async deleteItemData(data) {
        var id = data.id_fix;
        let { Fixes } = this.state;
        let index = parseInt(Fixes.findIndex(item => item.id_fix === id));
        if(index !== -1) Fixes.splice(index, 1);
        else this.setState({ Fixes })
    }

    toastDeleteItem(name) {
        toastr.options = toastOptions;
        toastr.clear()
        setTimeout(() => toastr.success(`Solución ${name ? name: ''} eliminada`), 300)
    }

    appendObjTo = (thatArray, newObj) => {
        const frozenObj = Object.freeze(newObj);
        return Object.freeze(thatArray.concat(frozenObj));
    }

    callbackFunction = (childData) => {
        this.setState({ newModels: childData.Models})
    }

    render() {
        const { Fixes, Fixes_types, Models, oldModels} = this.state;
        return (
            <>
                <AdminHeader {...this.props}/>
                <Container className="mt--7" fluid> 
                    <div>
                        <Row>
                            <div className="col">
                                <Card className="shadow">
                                    <CardHeader className="border-0">
                                        <Row>
                                            <div className="col-6">
                                                <h3 className="mb-0 d-inline">Soluciones</h3>
                                            </div>
                                            <div className="col-6">
                                                <Button type="button" onClick={() => this.addNewItem()} style={{ padding: '0.3rem 0.7rem' }} className="float-right btn btn-outline-success btn-circle">
                                                    <i className="fa fa-plus"></i>
                                                </Button>
                                            </div>
                                        </Row>
                                    </CardHeader>
                                    {
                                        this.state.loading ? 
                                        <div className='spinner-table' ><CircleSpinner
                                                size={30}
                                                color="#11cdef"
                                                loading={this.state.loading}
                                            /></div>
                                        :
                                        <Table className="align-items-center table-flush" responsive>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th className='itemCenter' scope="col">Soluci&oacute;n</th>
                                                    <th className='itemCenter' scope="col">Tipo</th>
                                                    <th className='itemCenter' scope="col">Modelos</th>
                                                    <th className='itemCenter' scope="col">Descripci&oacute;n</th>
                                                    <th className='itemCenter' scope="col" />
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    Fixes ? 
                                                    Fixes.map((item, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td className='itemCenter'>{item.fix ? item.fix: <p>-</p>}</td>
                                                                <td className='itemCenter'>{item.type ? item.type: <p>-</p>}</td>
                                                                <td className='itemCenter'>  
                                                                    <Button className='btn btn-info btn-sm' onClick={e => this.seeModels(item)}>Modelos</Button>
                                                                </td>
                                                                <td className='itemCenter'>{item.description ? item.description: <p>-</p>}</td>
                                                                <td className="text-right">
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle
                                                                            className="btn-icon-only text-light"
                                                                            href="#"
                                                                            role="button"
                                                                            size="sm"
                                                                            color=""
                                                                            onClick={e => e.preventDefault()}>
                                                                            <i className="fas fa-ellipsis-v" />
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu-arrow" right>
                                                                            <DropdownItem
                                                                                onClick={() => this.editItem(item)}
                                                                            >
                                                                                <i className="fas fa-edit" />
                                                                                Editar
                                                                            </DropdownItem>
                                                                            <DropdownItem
                                                                                onClick={() => this.deleteItem(item)}
                                                                            >
                                                                                <i className="fas fa-trash" />
                                                                                Eliminar
                                                                            </DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </Table>
                                    }
                                    </Card>
                                </div>
                            </Row>
                    </div>
                </Container>

                {/* MODAL NO DATA */}
                <div>
                <Modal isOpen={this.state.modalNoData} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>NO DATA</ModalHeader>
                    <ModalBody>
                        No hay soluciones registradas
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                    </ModalFooter>
                </Modal>
                </div>

                {/* MODAL NEW */}
                <div>
                <Modal isOpen={this.state.modalNew} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>Agregar una nueva soluci&oacute;n</ModalHeader>
                    <Form onSubmit={this.submitAddItem}>
                    <ModalBody>
                        <FormGroup row>
                            <Label for="fix" sm={4}>Nombre soluci&oacute;n</Label>
                            <Col sm={8}>
                            <Input 
                                type="text"
                                name="fix"
                                id="fix" 
                                placeholder="Ingresa el nombre de la solución"
                                onChange={this.handleAddItem}
                                value={this.state.fix}
                                required />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="name" sm={4}>Tipo de soluci&oacute;n</Label>
                            <Col sm={8}>
                            <Input 
                                type="select"
                                name="id_fix_type"
                                id="id_fix_type"
                                onChange={this.handleAddItem}
                                value={this.state.id_fix_type}>
                                {
                                    Fixes_types === [] ? <option>1</option> :
                                    Fixes_types.map((item, key) => {
                                        return(
                                            <option key={key} value={item.id_fix_type}>{item.type}</option>
                                        )
                                    })
                                }
                            </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="description" sm={4}>Modelos Compatibles</Label>
                            <Col sm={8}>
                                <MultiSelect 
                                    Models={Models} 
                                    parentCallback = {this.callbackFunction}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="description" sm={4}>Descripci&oacute;n de la solución</Label>
                            <Col sm={8}>
                            <Input 
                                type="textarea"
                                name="description"
                                id="description" 
                                placeholder="Ingrese la descripción de la solución"
                                onChange={this.handleAddItem}
                                value={this.state.description}
                                required />
                            </Col>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                        <Button color="success" type="submit" disabled={this.state.submit} >Crear</Button>
                    </ModalFooter>
                    </Form>
                </Modal>
                </div>
                
                {/* MODAL UPDATE ITEM */}
                <div>
                <Modal isOpen={this.state.modalEdit} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>
                        Editar soluci&oacute;n {this.state.item ? this.state.item.fix : ''}
                    </ModalHeader>
                    <Form onSubmit={this.submitUpdateData}>
                    <ModalBody>
                        <FormGroup row>
                            <Label for="fix" sm={4}>Nombre Soluci&oacute;n</Label>
                            <Col sm={8}>
                            <Input 
                                type="text"
                                name="fix"
                                id="fix" 
                                placeholder="Ingresa el nombre de la solución"
                                onChange={this.handleUpdateItem}
                                value={this.state.fix}
                                required />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="name" sm={4}>Tipo de soluci&oacute;n</Label>
                            <Col sm={8}>
                            <Input 
                                type="select"
                                name="id_fix_type"
                                id="id_fix_type"
                                onChange={this.handleUpdateItem}
                                value={this.state.id_fix_type}>
                                {
                                    Fixes_types === [] ? <option>1</option> :
                                    Fixes_types.map((item, key) => {
                                        return(
                                            <option key={key} value={item.id_fix_type}>{item.type}</option>
                                        )
                                    })
                                }
                            </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="description" sm={4}>Modelos Compatibles</Label>
                            <Col sm={8}>
                                <MultiSelect 
                                    Models={Models} 
                                    parentCallback = {this.callbackFunction}
                                    oldModels = { oldModels }
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="description" sm={4}>Descripci&oacute;n de la soluci&oacute;n</Label>
                            <Col sm={8}>
                            <Input 
                                type="textarea"
                                name="description"
                                id="description" 
                                placeholder="Ingrese la descripción de la solución"
                                onChange={this.handleUpdateItem}
                                value={this.state.description}
                                required />
                            </Col>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                        <Button color="success" type="submit" disabled={this.state.submit}>Actualizar</Button>
                    </ModalFooter>
                    </Form>
                </Modal>
                </div>

                <div>
                {/* MODAL DELETE */}
                <Modal isOpen={this.state.modalDelete} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>
                        Eliminar {this.state.item ? this.state.item.fix : 'Solución'}
                    </ModalHeader>
                    <ModalBody>
                        ¿Deseas eliminar esta soluci&oacute;n
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                        <Button color="danger" onClick={this.submitDeleteData} disabled={this.state.submit}>Eliminar</Button>
                    </ModalFooter>
                </Modal>
                </div>

                <div>
                {/* MODAL NO MODELS */}
                <Modal isOpen={this.state.modalModels} toggle={this.goModels} className={this.props.className}>
                    <ModalHeader toggle={this.goModels} className='ModalHeaderTitle'>
                       No hay modelos creados
                    </ModalHeader>
                    <ModalBody>
                        Ir a crear modelo
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.goModels}>Crear</Button>
                    </ModalFooter>
                </Modal>
                </div>

                <div>
                {/* MODAL COMPATIBLES MODELS */}
                <Modal isOpen={this.state.modalCompatible} toggle={this.closeModalCompatibility} className={this.props.className}  size="sm"> 
                    <ModalHeader toggle={this.closeModalCompatibility} className='ModalHeaderTitle'>
                        Modelos compatibles {this.state.item ? this.state.item.spare_part : ''}
                    </ModalHeader>
                    <ModalBody>
                        <ListGroup>
                            { 
                                !this.state.item ? ''
                                : 
                                this.state.item.models.map((item, index) => (
                                    <ListGroupItem key= {index}>{item.model}</ListGroupItem>
                                ))
                            }
                        </ListGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.closeModalCompatibility}>Cerrar</Button>
                    </ModalFooter>
                </Modal>
                </div>
            </>
        );
    }
}

export default Fixes;