import { appFetch } from './fetch';
import host from './host';
import auth from './auth';

export const getCardsInfo  = async () => {
    const url = `${host}/reports/cards`;
    return await appFetch({ url, auth: auth.getToken(), method: 'GET' });
};

export const downloadReport  = async () => {
    const url = `${host}/reports/report`;
    return await appFetch({ url, auth: auth.getToken(), method: 'GET' });
};