import { appFetch } from './fetch';
import host from './host';
import auth from './auth';

export const getDevices  = async () => {
    const url = `${host}/devices/get_all?dashboard=${true}`;
    return await appFetch({ url, auth: auth.getToken(), method: 'GET' });
};

export const createDevices = async data => {
    const url = `${host}/device/create`;
    return await appFetch({ url, auth: auth.getToken(), body: JSON.stringify(data), method: 'POST' })
}

export const updateDevices = async (id, data) => {
    const url = `${host}/device/update/${id}`;
    return await appFetch({ url, auth: auth.getToken(), body: JSON.stringify(data), method: 'PUT' })
}

export const devicesTypes = async () => {
    const url = `${host}//devices/type`;
    return await appFetch({ url, auth: auth.getToken(), method: 'GET' });
};