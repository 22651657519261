import React from "react";
import { Link } from "react-router-dom";
import { NavbarBrand, Navbar, Container, } from "reactstrap";

// Import login styles
import '../../assets/css/login/login.css';

const LoginNavbar = () => {
    return (
        <>
            <Navbar
                className="navbar-top navbar-horizontal navbar-dark"
                expand="md">
                <Container className="px-4">
                    <NavbarBrand className='login-navbar-logo' to="/" tag={Link}>
                        <img src={require("../../assets/img/login/logo.png")} alt="Logo Horbit" />
                    </NavbarBrand>
                </Container>
            </Navbar>
        </>
    );
}

export default LoginNavbar;