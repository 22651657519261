import React, { Component } from 'react';
import {
    Card, CardHeader, Container, Row, Button,
    Modal, ModalBody, ModalHeader, ModalFooter,
    Form, FormGroup, Input, Label, Col
} from "reactstrap";
import axios from 'axios';
import moment from 'moment'
import AdminHeader from "../../Shared/Headers/AdminHeader";
import '../../../assets/css/dashboard/dashboard.css';
import { getWarehouse } from './../../../api/warehouses';
import { getClients } from './../../../api/clients';
import { getServiceCenter } from './../../../api/serviceCenters'
import { getUsers } from './../../../api/users'
import auth from "./../../../api/auth.js";
import Charts from './../../Shared/UI_Elements/Charts'

// TOAST
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

const toastOptions = {
    positionClass: 'toast-top-center',
    hideDuration: 300,
    timeOut: 3000,
    progressBar: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
}

const typeReports = [
    {
        name: 'Informe cantidad equipos reparados',
        type: 1
    },
    {
        name: 'Informe cantidad equipos reparados por técnico',
        type: 2
    },
    {
        name: 'Informe de faturación',
        type: 3
    },
    {
        name: 'Informe de reingresos',
        type: 4
    },
    {
        name: 'Informe detallado % de falla',
        type: 5
    },
    {
        name: 'Informe detallado uso de repuestos',
        type: 6
    },
    {
        name: 'Informe de inventario por bodega',
        type: 7
    }
]

class ReportsClass extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            submit: true,
            item: null,
            selected: false,
            type: 1,
            start_time: '2020-01-01',
            end_time:  moment(new Date()).format('YYYY-MM-DD'),
            url: '',
            data: null,
            titleChart: '',
            id_select: 1,

            modalNoData: false,
            modalDownloadCSV: false,

            Services_center: [],
            Clients: [],
            Users: [],
            Warehouses: [],
            newSelect: [],
        
            modalWarehouse: false,
            modalServiceCenter: false,
            modalClients: false,

            auth: auth.getToken()
        };

        this.closeModal = this.closeModal.bind(this);

        this.handleChange = this.handleChange.bind(this);

        this.renderSwitch = this.renderSwitch.bind(this);

        this.research = this.research.bind(this);
        
        this.addModalDownload = this.addModalDownload.bind(this);

        this.downloadCSV = this.downloadCSV.bind(this);

        this.goComponent = this.goComponent.bind(this);
    }

    async fetchData() {
        
        const resultWarehouse = await getWarehouse()
        let newSelect = []
        if(resultWarehouse.response.status === 200 ) {
            if(resultWarehouse.response.Warehouses.length === 0) {
                this.setState({ modalWarehouse: true })
                return;
            }
            else {
                let Warehouses = [...resultWarehouse.response.Warehouses] 
                this.setState({ 
                    Warehouses: Warehouses,
                    id_warehouse: resultWarehouse.response.Warehouses[0].id_warehouse
                })
            }
        }
        const resultCliens= await getClients()
        if(resultCliens.response.status === 200 ) {
            if(resultCliens.response.Clients.length === 0) {
                this.setState({ modalClients: true })
                return;
            }
            else {
                let Clients = [...resultCliens.response.Clients, { id_client: '', name: 'N/A'} ]
                this.setState({ 
                    Clients, id_client: resultCliens.response.Clients[0].id_client
                })
            }
        }
        const resultService = await getServiceCenter()
        if(resultService.response.status === 200 ) {
            if(resultService.response.Services_center.length === 0) {
                this.setState({ modalServiceCenter: true })
                return;
            }
            else {
                let ServiceCenter = [...resultService.response.Services_center, { id_service_center: '', name: 'N/A'} ]
                this.setState({ 
                    Services_center:ServiceCenter,
                    id_service_center: resultService.response.Services_center[0].id_service_center
                })
            }
        }
        const resultUsers = await getUsers()
        if(resultUsers.response.status === 200 ) {
            let Users = await resultUsers.response.Users.filter(item => item.id_role === 4 )
            Users = [...Users, { id_user: '', name: 'N/A'} ]
            this.setState({ 
                Users, id_user: resultUsers.response.Users[0].id_user
            })    
        } 
        newSelect = [...resultService.response.Services_center,...resultCliens.response.Clients]
        this.setState({ loading: false, newSelect })
    }

    componentDidMount() {
        this.fetchData();
    }


    closeModal() {
        this.setState({
            modalNoData: false,
            submit: true,
            item: null,
            modalDownloadCSV: false
        })
        this.research();
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    selectReport(e) {
        e.preventDefault(); 
        this.setState({ selected:true })
    }

    async generateGraphic(e) {
        e.preventDefault();
        await this.setState({ url: ''})
        const { id_client, id_service_center, start_time, end_time, id_user, id_select, newSelect } = this.state;
        let { type } = this.state;
        type = parseInt(type)
        let url
        switch(type) {
            case 1:
                url = `/reports/repaired?start_time=${start_time}&end_time=${end_time}&id_client=${id_client}&id_service_center=${id_service_center}`;
                await this.fecthDataChart(url, type);
                this.setState({ url})
                return;
            case 2:
                url = `/reports/diagnosed?start_time=${start_time}&end_time=${end_time}&id_user=${id_user}`;
                await this.fecthDataChart(url, type);
                this.setState({ url})
                return;
            case 3:
                let item = newSelect[id_select ? id_select : 1]
                url = `/reports/billing?start_time=${start_time}&end_time=${end_time}&id_client=${item.id_client ? item.id_client : ''}&id_service_center=${item.id_service_center ? item.id_service_center : ''}`;
                await this.fecthDataChart(url, type);
                this.setState({ url})
                return;
            case 4:
                url = `/reports/warranty?start_time=${start_time}&end_time=${end_time}&id_client=${id_client}`;
                await this.fecthDataChart(url, type);
                this.setState({ url})
                return;
            case 5:
                url = `/reports/failures?start_time=${start_time}&end_time=${end_time}&id_client=${id_client}`;
                await this.fecthDataChart(url, type);
                this.setState({ url})
                return;
            case 6:
                url = `/reports/spare_parts?start_time=${start_time}&end_time=${end_time}&id_client=${id_client}`;
                await this.fecthDataChart(url, type);
                this.setState({ url})
                return;
            default:
                return '';
        }
    }

    async addModalDownload(e) {
        e.preventDefault();
        const { Warehouses, id_warehouse } = this.state;
        let item = Warehouses.filter(item => item.id_warehouse === id_warehouse)
        await this.setState({ item })
        this.setState({ modalDownloadCSV: true})
    }

    renderSwitch(type) {
        const { Services_center, Clients, Users, Warehouses, newSelect } = this.state;
        type = parseInt(type);
        switch(type) {
            case 1:
                return (
                    <Form onSubmit={e => this.generateGraphic(e)}>
                        <Row form>
                            <Col md={3}>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <Label for="fecha inicio" className="mr-sm-2">Fecha inicio</Label>
                                    <Input 
                                        bsSize="sm"
                                        type="date"
                                        name="start_time" 
                                        id="start_time"
                                        onChange={this.handleChange}
                                        value = {this.state.start_time}
                                        placeholder="fecha inicio"/>
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <Label for="fecha fin" className="mr-sm-2">Fecha fin</Label>
                                    <Input 
                                        bsSize="sm"
                                        type="date"
                                        name="end_time" 
                                        id="end_time"
                                        onChange={this.handleChange}
                                        value = {this.state.end_time}
                                        placeholder="Fecha final"/>
                                </FormGroup>    
                            </Col>
                            <Col md={3}>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <Label for="centro de servcio" className="mr-sm-2">Centro de servicio</Label>
                                    <Input
                                        bsSize="sm"
                                        type="select"
                                        name="id_service_center"
                                        id="id_service_center"
                                        onChange={this.handleChange}
                                        value={this.state.id_service_center}
                                        required>
                                        {
                                            Services_center === [] ? <option>1</option> :
                                                Services_center.map((item, key) => {
                                                    return (
                                                        <option key={key} value={item.id_service_center}>{item.name}</option>
                                                    )
                                                })
                                        }
                                    </Input>
                                </FormGroup> 
                            </Col>
                            <Col md={3}>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                <Label for="centro de servcio" className="mr-sm-2">Clientes</Label>
                                    <Input
                                        bsSize="sm"
                                        type="select"
                                        name="id_client"
                                        id="id_client"
                                        onChange={this.handleChange}
                                        value={this.state.id_client}
                                        required>
                                        {
                                            Clients === [] ? <option>1</option> :
                                                Clients.map((item, key) => {
                                                    return (
                                                        <option key={key} value={item.id_client}>{item.name}</option>
                                                    )
                                                })
                                        }
                                    </Input>
                                </FormGroup> 
                            </Col>
                        </Row>
                        <br/>
                        <Row form>
                            <Col>
                                <Button color='primary'>Generar</Button>
                            </Col>
                        </Row>
                    </Form>
                );
            case 2: 
                return (
                    <Form onSubmit={e => this.generateGraphic(e)}>
                        <Row form>
                            <Col md={3}>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <Label for="fecha inicio" className="mr-sm-2">Fecha inicio</Label>
                                    <Input 
                                        bsSize="sm"
                                        type="date"
                                        name="start_time" 
                                        id="start_time"
                                        onChange={this.handleChange}
                                        value = {this.state.start_time}
                                        placeholder="fecha inicio"/>
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <Label for="fecha fin" className="mr-sm-2">Fecha fin</Label>
                                    <Input 
                                        bsSize="sm"
                                        type="date"
                                        name="end_time" 
                                        id="end_time"
                                        onChange={this.handleChange}
                                        value = {this.state.end_time}
                                        placeholder="Fecha final"/>
                                </FormGroup>    
                            </Col>
                            <Col md={3}>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <Label for="centro de servcio" className="mr-sm-2">Técnico</Label>
                                    <Input
                                        bsSize="sm"
                                        type="select"
                                        name="id_user"
                                        id="id_user"
                                        onChange={this.handleChange}
                                        value={this.state.id_user}
                                        required>
                                        {
                                            Users === [] ? <option>1</option> :
                                            Users.map((item, key) => {
                                                    return (
                                                        <option key={key} value={item.id_user}>{item.name}</option>
                                                    )
                                                })
                                        }
                                    </Input>
                                </FormGroup> 
                            </Col>
                        </Row>
                        <br/>
                        <Row form>
                            <Col>
                                <Button color='primary' >Generar</Button>
                            </Col>
                        </Row>
                    </Form>
                );
            case 3: 
                return (
                    <Form onSubmit={e => this.generateGraphic(e)}>
                        <Row form>
                            <Col md={3}>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <Label for="fecha inicio" className="mr-sm-2">Fecha inicio</Label>
                                    <Input 
                                        bsSize="sm"
                                        type="date"
                                        name="start_time" 
                                        id="start_time"
                                        onChange={this.handleChange}
                                        value = {this.state.start_time}
                                        placeholder="fecha inicio"/>
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <Label for="fecha fin" className="mr-sm-2">Fecha fin</Label>
                                    <Input 
                                        bsSize="sm"
                                        type="date"
                                        name="end_time" 
                                        id="end_time"
                                        onChange={this.handleChange}
                                        value = {this.state.end_time}
                                        placeholder="Fecha final"/>
                                </FormGroup>    
                            </Col>
                            <Col md={6}>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <Label for="centro de servcio" className="mr-sm-2">Centro de servicio o cliente</Label>
                                    <Input
                                        bsSize="sm"
                                        type="select"
                                        name="id_select"
                                        id="id_select"
                                        onChange={this.handleChange}
                                        value={this.state.id_select}
                                        required>
                                        {
                                            newSelect === [] ? <option>1</option> :
                                                newSelect.map((item, key) => {
                                                    return (
                                                        <option key={key} value={key}>
                                                            {item.id_service_center ? 'Centro de servicio': 'Cliente'} - {item.name}
                                                        </option>
                                                    )
                                                })
                                        }
                                    </Input>
                                </FormGroup> 
                            </Col>
                        </Row>
                        <br/>
                        <Row form>
                            <Col>
                                <Button color='primary' >Generar</Button>
                            </Col>
                        </Row>
                    </Form>
                );
            case 4: 
                return (
                    <Form onSubmit={e => this.generateGraphic(e)}>
                        <Row form>
                            <Col md={3}>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <Label for="fecha inicio" className="mr-sm-2">Fecha inicio</Label>
                                    <Input 
                                        bsSize="sm"
                                        type="date"
                                        name="start_time" 
                                        id="start_time"
                                        onChange={this.handleChange}
                                        value = {this.state.start_time}
                                        placeholder="fecha inicio"/>
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <Label for="fecha fin" className="mr-sm-2">Fecha fin</Label>
                                    <Input 
                                        bsSize="sm"
                                        type="date"
                                        name="end_time" 
                                        id="end_time"
                                        onChange={this.handleChange}
                                        value = {this.state.end_time}
                                        placeholder="Fecha final"/>
                                </FormGroup>    
                            </Col>
                            <Col md={3}>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                <Label for="centro de servcio" className="mr-sm-2">Clientes</Label>
                                    <Input
                                        bsSize="sm"
                                        type="select"
                                        name="id_client"
                                        id="id_client"
                                        onChange={this.handleChange}
                                        value={this.state.id_client}
                                        required>
                                        {
                                            Clients === [] ? <option>1</option> :
                                                Clients.map((item, key) => {
                                                    return (
                                                        <option key={key} value={item.id_client}>{item.name}</option>
                                                    )
                                                })
                                        }
                                    </Input>
                                </FormGroup> 
                            </Col>
                        </Row>
                        <br/>
                        <Row form>
                            <Col>
                                <Button color='primary' >Generar</Button>
                            </Col>
                        </Row>
                    </Form>
                );
            case 5: 
                return (
                    <Form onSubmit={e => this.generateGraphic(e)}>
                        <Row form>
                            <Col md={3}>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <Label for="fecha inicio" className="mr-sm-2">Fecha inicio</Label>
                                    <Input 
                                        bsSize="sm"
                                        type="date"
                                        name="start_time" 
                                        id="start_time"
                                        onChange={this.handleChange}
                                        value = {this.state.start_time}
                                        placeholder="fecha inicio"/>
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <Label for="fecha fin" className="mr-sm-2">Fecha fin</Label>
                                    <Input 
                                        bsSize="sm"
                                        type="date"
                                        name="end_time" 
                                        id="end_time"
                                        onChange={this.handleChange}
                                        value = {this.state.end_time}
                                        placeholder="Fecha final"/>
                                </FormGroup>    
                            </Col>
                            <Col md={3}>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                <Label for="centro de servcio" className="mr-sm-2">Clientes</Label>
                                    <Input
                                        bsSize="sm"
                                        type="select"
                                        name="id_client"
                                        id="id_client"
                                        onChange={this.handleChange}
                                        value={this.state.id_client}
                                        required>
                                        {
                                            Clients === [] ? <option>1</option> :
                                                Clients.map((item, key) => {
                                                    return (
                                                        <option key={key} value={item.id_client}>{item.name}</option>
                                                    )
                                                })
                                        }
                                    </Input>
                                </FormGroup> 
                            </Col>
                        </Row>
                        <br/>
                        <Row form>
                            <Col>
                                <Button color='primary' >Generar</Button>
                            </Col>
                        </Row>
                    </Form>
                );
            case 6: 
                return (
                    <Form onSubmit={e => this.generateGraphic(e)}>
                        <Row form>
                            <Col md={3}>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <Label for="fecha inicio" className="mr-sm-2">Fecha inicio</Label>
                                    <Input 
                                        bsSize="sm"
                                        type="date"
                                        name="start_time" 
                                        id="start_time"
                                        onChange={this.handleChange}
                                        value = {this.state.start_time}
                                        placeholder="fecha inicio"/>
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <Label for="fecha fin" className="mr-sm-2">Fecha fin</Label>
                                    <Input 
                                        bsSize="sm"
                                        type="date"
                                        name="end_time" 
                                        id="end_time"
                                        onChange={this.handleChange}
                                        value = {this.state.end_time}
                                        placeholder="Fecha final"/>
                                </FormGroup>    
                            </Col>
                            <Col md={3}>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                <Label for="centro de servcio" className="mr-sm-2">Clientes</Label>
                                    <Input
                                        bsSize="sm"
                                        type="select"
                                        name="id_client"
                                        id="id_client"
                                        onChange={this.handleChange}
                                        value={this.state.id_client}
                                        required>
                                        {
                                            Clients === [] ? <option>1</option> :
                                                Clients.map((item, key) => {
                                                    return (
                                                        <option key={key} value={item.id_client}>{item.name}</option>
                                                    )
                                                })
                                        }
                                    </Input>
                                </FormGroup> 
                            </Col>
                        </Row>
                        <br/>
                        <Row form>
                            <Col>
                                <Button color='primary' >Generar</Button>
                            </Col>
                        </Row>
                    </Form>
                );
            case 7: 
                return (
                    <Form onSubmit={e => this.addModalDownload(e)}>
                        <Row form>
                            <Col md={3}>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                <Label for="centro de servcio" className="mr-sm-2">Bodegas</Label>
                                    <Input
                                        bsSize="sm"
                                        type="select"
                                        name="id_warehouse"
                                        id="id_warehouse"
                                        onChange={this.handleChange}
                                        value={this.state.id_warehouse}
                                        required>
                                        {
                                            Warehouses === [] ? <option>1</option> :
                                                Warehouses.map((item, key) => {
                                                    return (
                                                        <option key={key} value={item.id_warehouse}>{item.name}</option>
                                                    )
                                                })
                                        }
                                    </Input>
                                </FormGroup> 
                            </Col>
                        </Row>
                        <br/>
                        <Row form>
                            <Col>
                                <Button color='primary' >Generar</Button>
                            </Col>
                        </Row>
                    </Form>
                );
            default:
              return '';
        }
    }

    async fecthDataChart(url, type) {
        await axios({
            url: `${process.env.REACT_APP_API_URL}${url}`, //your url
            // url: `https://test.gprog.co/api${url}`, //your url

            // url: `https://api.icee.gprog.co/api${url}`, //your url
            method: 'GET',
            headers: {
                'dash-authorization': this.state.auth,
                'Content-Type': 'application/json'
            },
        }).then(response => {
            if (response.status === 204 || response.status === 401) {
                auth.removeUser();
                auth.removeToken();
                window.location.reload();
                alert('NO AUTORIZADO')
                return response
            }
            if(response.status === 200) {
                let data
                let titleChart
                let dataTooltip = null
                switch (type) {
                    case 1:
                        if(response.data.repaired_devices.length === 0) {
                            alert('No hay datos para esta selección')
                            break;
                        }
                        data = this.convertDataChart(response.data.repaired_devices, 'Equipos reparados')
                        titleChart = 'Informe cantidad equipos reparados'
                        break;
                
                    case 2:
                        if(response.data.diagnosed_devices_by_user.length === 0) {
                            alert('No hay datos para esta selección')
                            break;
                        }
                        data = this.convertDataChart(response.data.diagnosed_devices_by_user, 'Equipos reparados por técnico')
                        titleChart = 'Informe cantidad equipos reparados por técnico'
                        break;
                    case 3:
                        if(parseInt(response.data.billing) === 0) {
                            alert('No hay datos para esta selección')
                            break;
                        }
                        let dataChart = [{
                            status: 'Facturación',
                            count: response.data.billing
                        }]
                        console.log(dataChart);
                        data = this.convertDataChart(dataChart, 'Informe facturación')
                        titleChart = 'Total facturado'
                        break;
                    case 4:
                        if(response.data.warranty_devices.length === 0) {
                            alert('No hay datos para esta selección')
                            break;
                        }
                        data = this.convertDataChart(response.data.warranty_devices, 'Reingresos')
                        titleChart = 'Informe de reingresos'
                        break;
                    case 5:
                        if(response.data.failure_devices.length === 0) {
                            alert('No hay datos para esta selección')
                            break;
                        }
                        data = this.convertDataChart(response.data.failure_devices, 'Porcentaje de falla')
                        titleChart = 'Informe detallado % de falla'
                        break;
                    case 6:
                        if(response.data.spare_parts_usage.length === 0) {
                            alert('No hay datos para esta selección')
                            break;
                        }
                        data = this.convertDataChart(response.data.spare_parts_usage, 'Uso de repuestos')
                        titleChart = 'Informe detallado uso de repuestos'
                        break;
                    default:
                        data = null
                        break;
                }
                this.setState({ data, titleChart, dataTooltip })
                return;
            }
            else alert('error en el server')
        })
        .catch(error => {
            alert(error.message ? error.message : 'error en el servidor')
        })
    }

    convertDataChart(dataChartFetch, label) {
        let labels = []
        let dataChart = []
        let dataTooltip = []
    
        for(let item of dataChartFetch) {
          labels.push(item.status)
          dataChart.push(item.count)
          dataTooltip.push(item.data)
        }

    
        let data = {
            labels: labels,
            datasets: [
                {
                    label: label ? label : 'Equipos',
                    data: dataChart,
                    backgroundColor: [
                        "#46BFBD",
                        "#F7464A",
                        "#FDB45C",
                        "#36A2EB",
                        "#4D5360",
                        '#FF6384',
                        '#252850',
                        '#FFCE56',
                        "#929FB1",
                        "#2D572C",
                        "#7D2181",
                        "#00FFFF",
                        "#0D84AA",
                        "#967CB0",
                        "#AD1084",
                        "#AD83F5",
                        "#51E004",
                        "#80205C",
                        "#AE22AE",
                        "#25B453",
                        "#9F1120",
                        "#781681",
                        "#B02D18",
                        "#AC6DE1",
                        "#B1775C",
                        "#AECB0F",
                        "#C87EF0",
                        "#FA0282",
                        "#A2E10C",
                        "#3BDA23",
                        "#6D2293",
                        "#15B93A",
                        "#935A6D",
                        "#C00A21",
                        "#75C50F",
                        "#87DAA6",
                        "#DCB95F"
                    ],
                    hoverBackgroundColor: [
                        "#46BFBD",
                        "#F7464A",
                        "#FDB45C",
                        "#36A2EB",
                        "#4D5360",
                        '#FF6384',
                        '#252850',
                        '#FFCE56',
                        "#929FB1",
                        "#2D572C",
                        "#7D2181",
                        "#00FFFF",
                        "#0D84AA",
                        "#967CB0",
                        "#AD1084",
                        "#AD83F5",
                        "#51E004",
                        "#80205C",
                        "#AE22AE",
                        "#25B453",
                        "#9F1120",
                        "#781681",
                        "#B02D18",
                        "#AC6DE1",
                        "#B1775C",
                        "#AECB0F",
                        "#C87EF0",
                        "#FA0282",
                        "#A2E10C",
                        "#3BDA23",
                        "#6D2293",
                        "#15B93A",
                        "#935A6D",
                        "#C00A21",
                        "#75C50F",
                        "#87DAA6",
                        "#DCB95F"
                    ],
                }
            ],
            dataTooltip: dataTooltip
        }
        return data
    }

    research() {
        const { Users, Clients, Services_center, Warehouses } = this.state;
        this.setState({ 
            selected: false, url: '', type: 1,
            id_client: Clients[0].id_client,
            id_user: Users[0].id_user,
            id_service_center: Services_center[0].id_service_center,
            id_warehouse: Warehouses[0].id_warehouse,
        })
    }

    downloadCSV() {
        const { id_warehouse } = this.state;
        axios({
            url: `${process.env.REACT_APP_API_URL}/reports/warehouse_inventory/${id_warehouse}`, //your url
            // url: `https://test.gprog.co/api/reports/warehouse_inventory/${id_warehouse}`, //your url
            headers: { 'Access-Control-Allow-Origin': '*', 'dash-authorization': this.state.auth  },
            method: 'GET',
            responseType: 'blob', // important
        }).then(response => {
            if (response.status === 204 || response.status === 401) {
                auth.removeUser();
                auth.removeToken();
                window.location.reload();
                alert('NO AUTORIZADO')
                return response
            }
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Informe.csv'); //or any other extension
            document.body.appendChild(link);
            link.click();
            this.closeModal()
            this.toatSucess()
        })
        .catch(error => {
            alert(error.message ? error.message : 'Error en el servidor')
        })
    }

    toatSucess() {
        toastr.options = toastOptions;
        toastr.clear()
        setTimeout(() => toastr.success(`Descarga exitosa`), 300)
    }

    goComponent(component) {
        this.props.history.push(`/admin/${component}`);  
    }


    render() {
        const { selected, type, data, url, titleChart, item } = this.state;
        return (
            <>
                <AdminHeader {...this.props} />
                <Container className="mt--7" fluid>
                    <div>
                        <Row>
                            <div className="col">
                                <Card className="shadow">
                                    <CardHeader className="border-0">
                                        <Row>
                                            <div className="col-6">
                                                <h3 className="mb-0 d-inline">Reportes</h3>
                                            </div>
                                        </Row>
                                    </CardHeader>
                                    <Card style={{ marginLeft: '5%', marginBottom: '3%' }}>
                                        <Form inline onSubmit={e => this.selectReport(e)}>
                                            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                                <Label for="exampleEmail" className="mr-sm-2">Seleccionar informe</Label>
                                                <Input
                                                    type="select"
                                                    name="type"
                                                    id="type"
                                                    onChange={this.handleChange}
                                                    value={this.state.type}
                                                    required
                                                    disabled={selected}>
                                                    {
                                                        typeReports === [] ? <option>1</option> :
                                                            typeReports.map((item, key) => {
                                                                return (
                                                                    <option key={key} value={item.type}>{item.name}</option>
                                                                )
                                                            })
                                                    }
                                                </Input>
                                            </FormGroup>
                                            <Button color='primary' type='submit' disabled={selected}>Elegir</Button>
                                        </Form>
                                        <Row>
                                            <div className="col-6">
                                                <Button color='primary' onClick={this.research}><i className="fas fa-redo"></i></Button>
                                            </div>
                                        </Row>
                                    </Card>
                                </Card>
                                <Card style={{padding: '0 0 0 5%'}}>
                                    { 
                                        selected ? 
                                        this.renderSwitch(type) : ''
                                        
                                    }
                                </Card>
                                <Card style={{padding: '0 0 0 5%'}}>
                                    { 
                                        url !== '' ? 
                                        <Charts  
                                            data={data}
                                            title={titleChart}/> : ''
                                    }
                                </Card>
                            </div>
                        </Row>
                    </div>
                </Container>
                <div>
                    {/* MODAL NO DATA */}
                    <Modal isOpen={this.state.modalNoData} toggle={this.closeModal} className={this.props.className}>
                        <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>NO DATA</ModalHeader>
                        <ModalBody>
                            No hay {`${this.state.type === 'incomes' ? 'ingresos registrados' : 'salidas registradas'}`}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                        </ModalFooter>
                    </Modal>
                </div>
                <div>
                    {/* MODAL DONWLOAD CSV */}
                    <Modal isOpen={this.state.modalDownloadCSV} toggle={this.closeModal} className={this.props.className}>
                        <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>Descargar Información por Bodega</ModalHeader>
                        <ModalBody>
                            ¿Descargar el inventario para la bodega { item ? item.name : ''}?
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                            <Button color="primary" onClick={this.downloadCSV}>Descargar</Button>
                        </ModalFooter>
                    </Modal>
                </div>

                <div>
                {/* MODAL NO WEAREHOUSES */}
                <Modal isOpen={this.state.modalWarehouse} toggle={() => this.goComponent('warehouse')} className={this.props.className}>
                    <ModalHeader toggle={() => this.goComponent('warehouse')} className='ModalHeaderTitle'>
                       No hay bodegas creadas
                    </ModalHeader>
                    <ModalBody>
                        Ir a crear bodega
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.goComponent('warehouse')}>Crear</Button>
                    </ModalFooter>
                </Modal>
                </div>


                
                <div>
                {/* MODAL NO SERVICE CENTER */}
                <Modal isOpen={this.state.modalServiceCenter} toggle={() => this.goComponent('services_centers')} className={this.props.className}>
                    <ModalHeader toggle={() => this.goComponent('services_centers')} className='ModalHeaderTitle'>
                       No hay centros de servicios creados
                    </ModalHeader>
                    <ModalBody>
                        Ir a crear centro de servicio
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.goComponent('services_centers')}>Crear</Button>
                    </ModalFooter>
                </Modal>
                </div>

                <div>
                {/* MODAL NO CLIENTS */}
                <Modal isOpen={this.state.modalClients} toggle={() => this.goComponent('clients')} className={this.props.className}>
                    <ModalHeader toggle={() => this.goComponent('clients')} className='ModalHeaderTitle'>
                       No hay clientes creados
                    </ModalHeader>
                    <ModalBody>
                        Ir a crear cliente
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.goComponent('clients')}>Crear</Button>
                    </ModalFooter>
                </Modal>
                </div>
            </>
        );
    }
}

export default ReportsClass;