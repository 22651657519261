import { appFetch } from './fetch';
import host from './host';
import auth from './auth';

export const getUsers  = async () => {
    const url = `${host}/users/get_all`;
    return await appFetch({ url, auth: auth.getToken(), method: 'GET' });
};

export const createUser = async data => {
    const url = `${host}/users/create`;
    return await appFetch({ url, auth: auth.getToken(), body: JSON.stringify(data), method: 'POST' })
}

export const updateUser = async (id, data) => {
    const url = `${host}/users/update/${id}`;
    return await appFetch({ url, auth: auth.getToken(), body: JSON.stringify(data), method: 'PUT' })
}

export const getUsersInfo = async () => {
    const url = `${host}/users/get_info_users`;
    return await appFetch({ url, auth: auth.getToken(), method: 'GET'})
}