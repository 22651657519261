import React, { Component } from 'react';

import AdminHeader from "../../Shared/Headers/AdminHeader";
import { Card, CardHeader, Container, Row,  Button, CardBody, Input, Table,
    Label, Form, FormGroup, Col,
    // DropdownMenu,DropdownItem, UncontrolledDropdown, DropdownToggle,
    Modal, ModalBody, ModalHeader, ModalFooter, 
} from "reactstrap";
import './../../../assets/css/dashboard/dashboard.css';
import { SearchSparePart } from './../../Shared/UI_Elements/SearchSparePart';
import { getWarehouse } from './../../../api/warehouses'
import { getUsers } from './../../../api/users'
import { createTransfer } from './../../../api/trasnfer'

class Transactions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            submit: false,

            value: 0,
            quantity: 0,
            
            actualItem: '',
            actualPrice: 0,
            actualQuantity: 0,

            item: '',

            modalNoData: false,
            modalCreate: false,
            modalPending: false,
            modalNoResorces: false,

            modalWarehouse: false,
            
            Warehouses: [],
            id_warehouse: '',
            Users: [],
            id_user: '',

            counter: 0,
            
        }
        this.closeModal = this.closeModal.bind(this);
        this.handleChangeOption = this.handleChangeOption.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeForm = this.handleChangeForm.bind(this);
        this.saveForm = this.saveForm.bind(this)
        this.goComponent = this.goComponent.bind(this)
        this.filterEmptySpareParts = this.filterEmptySpareParts.bind(this);
    }

    goBack() {
        this.props.history.goBack();
    }

    async fetchData() {
        const resultWarehouse = await getWarehouse()
        if(resultWarehouse.response.status === 200 ) {
            if(resultWarehouse.response.Warehouses.length === 0) {
                this.setState({ modalWarehouse: true })
                return;
            }
            else {
                this.setState({
                    Warehouses: resultWarehouse.response.Warehouses,
                    id_warehouse: resultWarehouse.response.Warehouses[0].id_warehouse
                })
            }
        }
        
        const resultUsers = await getUsers()
        if(resultUsers.response.status === 200) this.setState({ Users: resultUsers.response.Users, id_user: resultUsers.response.Users[0].id_user})
    }

    componentDidMount() {
        this.fetchData();  
    }

    closeModal() {
        this.setState({
            modalNoData: false,
            modalCreate: false,
            modalPending: false,
            modalNoResorces: false,
            items: [],

            submit: false,
            counter: 0,
        })
    }

    handleChange = idx => e => {
        const { name, value } = e.target;
        const items = [...this.state.items];
        let actualItem = this.state.actualItem;
        let actualPrice = this.state.actualPrice;
        let actualQuantity = this.state.actualQuantity;
        if(name === 'spare_part') {
            actualItem = value;
            this.setState({ actualItem })
        }
        if(name === 'quantity' ) {
            actualQuantity = value;
            this.setState({ actualQuantity })
        }
        else {
            actualPrice = value;
            this.setState({ actualPrice })
        }  
        
        items[idx] = {
            id_spare_part: this.state.actualId,
            spare_part: actualItem,
            price: parseFloat(actualPrice),
            quantity: actualQuantity
        };
        this.setState({ items });
    };

    handleAddRow = async () => {
        const item = {
            spare_part: "",
            price: 0
        };
        await this.delay(300);
        await this.setState({
            items: [...this.state.items, item]
        });
    };

    delay = ms => new Promise(res => setTimeout(res, ms));

    handleRemoveRow = () => {
        this.setState({
            items: this.state.items.slice(0, -1)
        });
    };

    handleChangeForm(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleRemoveSpecificRow = (idx) => () => {
        const items = [...this.state.items]
        items.splice(idx, 1)
        this.setState({ items })
    }

    handleChangeOption = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value === "ingreso" ? this.state.checked = true : this.state.checked = false });
    }

    async saveForm(e) {
        e.preventDefault();
        this.setState({ submit: true})

        let newItems = await this.state.items.filter(this.filterEmptySpareParts);
        let data = {
            id_warehouse_from: this.state.id_warehouse,
            id_user_to: this.state.id_user,
            spare_parts: newItems
        }
        if(data.spare_parts.length === 0 ) {
            alert('No se puede hacer transferencia, no hay repuestos seleccionados')
            this.setState({ submit: false })
            return;
        }
        createTransfer(data)
        .then( res => {
            this.setState({ submit: false})
            if(res.response.status === 400) {
                this.setState({ modalPending: true})
                return;
            }
            if(res.response.status === 406) {
                this.setState({ modalNoResorces: true})
                return;
            }
            if(res.response.status === 200) {
                this.setState({ modalCreate: true })
                setTimeout(() => { this.props.history.push('/admin/transfers');; }, 2000);
            }
            else {
                alert(res.response.message)
            }
        })
        .catch( err => {
            alert(err)
        })
    }

    callbackFunction = async (childData) => {
        let idx = childData.idx;
        let value = childData.choosenValue;
        const items = [...this.state.items];

        await this.setState({
            actualItem: value.spare_part,
            actualPrice: value.price
        })
        if(!value.spare_part) return;

        items[idx] = {
            id_spare_part: value.id_spare_part,
            spare_part: value.spare_part,
            price: value.price
        };
        await this.setState({
            items,
            actualItem: value.spare_part,
            actualPrice: value.price,
            actualId: value.id_spare_part,
            actualQuantity: 0
        })
    }

    successRequest() {
        this.props.history.goBack();
    }

    filterEmptySpareParts(obj) {
        let { counter } = this.state; 
        if ('id_spare_part' in obj) {
            return true;
        } else {
            counter++;
            return false;
        }
    }

    goComponent(component) {
        this.props.history.push(`/admin/${component}`);  
    }

    render() {
        const { Warehouses, Users } = this.state;
        return (
            <>
            <AdminHeader {...this.props}/>
            <Container className="mt--7" fluid> 
                <div>
                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <Form onSubmit={this.saveForm}
                                    onKeyPress={event => {
                                    if (event.which === 13 || event.key === 'Enter') {
                                        event.preventDefault();
                                        }
                                    }}>
                                <CardHeader className="border-0">
                                    <Row>
                                        <div className="col-6">
                                            <Button type="button" 
                                                onClick={() => this.goBack()} 
                                                style={{ padding: '0.3rem 0.8rem ', marginRight:'1rem' }} 
                                                className="float-left btn btn-outline-info btn-circle">
                                                <i className="fas fa-arrow-left"></i>
                                            </Button>
                                            <h3 className="mb-0 d-inline">Nueva Transferencia</h3>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody style={{margin: '0% 3% 0 3%'}}> 
                                    <FormGroup row>
                                        <Label for="id_model" sm={4}>Bodegas</Label>
                                        <Col sm={8}>
                                        <Input 
                                            type="select"
                                            name="id_warehouse"
                                            id="id_warehouse"
                                            onChange={this.handleChangeForm}
                                            value={this.state.id_warehouse}>
                                            {
                                                Warehouses === [] ? <option>1</option> :
                                                Warehouses.map((item, key) => {
                                                    return(
                                                        <option key={key} value={item.id_warehouse}>{item.name}</option>
                                                    )
                                                })
                                            }
                                        </Input>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="id_model" sm={4}>Usuarios</Label>
                                        <Col sm={8}>
                                        <Input 
                                            type="select"
                                            name="id_user"
                                            id="id_user"
                                            onChange={this.handleChangeForm}
                                            value={this.state.id_user}>
                                            {
                                                Users === [] ? <option>1</option> :
                                                Users.map((item, key) => {
                                                    return(
                                                        <option key={key} value={item.id_user}>{item.name}</option>
                                                    )
                                                })
                                            }
                                        </Input>
                                        </Col>
                                    </FormGroup>
                                </CardBody>
                                <CardBody>
                                    <div>
                                        <div className="container">
                                            <div className="row clearfix">
                                                <div className="col-md-12 column">
                                                    <Table
                                                        className="align-items-center table-bordered table-hover" 
                                                        id="recTable"
                                                        responsive >
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th className="text-center">N°</th>
                                                                <th className="text-center">Repuesto</th>
                                                                <th className="text-center">Cantidad</th>
                                                                <th />
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.items.map((item, idx) => (
                                                                <tr id="addr0" key={idx}>
                                                                    <td className='cellTableCreate'>{idx}</td>
                                                                    <td className='cellTableCreate'>
                                                                        <SearchSparePart 
                                                                            parentCallback = {this.callbackFunction}
                                                                            inputProps={ this.state.items, idx }> 
                                                                        </SearchSparePart>
                                                                    </td>
                                                                    <td className='cellTableCreate'>
                                                                        <Input
                                                                            type="number" 
                                                                            id="quantity"
                                                                            name="quantity"
                                                                            placeholder="Ingrese la cantidad"
                                                                            min='0'
                                                                            max='100'
                                                                            step='1'
                                                                            value={this.state.items[idx].quantity }
                                                                            onChange={this.handleChange(idx)}
                                                                            className="form-control"
                                                                        />
                                                                    </td>
                                                                    <td className='cellTableCreate itemCenter'>
                                                                        <Button
                                                                            className="btn btn-outline-danger btn-sm"
                                                                            onClick={this.handleRemoveSpecificRow(idx)}>
                                                                            X
                                                                        </Button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                    <div style={{marginTop: '1rem'}}>
                                                    <Button onClick={this.handleAddRow} className="btn btn-primary">
                                                        Agregar Ítem
                                                    </Button>
                                                    <Button 
                                                        type="submit" disabled={this.state.submit}
                                                        style={{ padding: '0.3rem 0.7rem' }} 
                                                        className="float-right btn btn-success btn-circle"
                                                        size="lg">
                                                            GUARDAR
                                                    </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </CardBody>
                                    
                                </Form>
                                </Card>
                            </div>
                        </Row>
                    </div>
                </Container>
                <div>
                {/* MODAL SUCESS CREATE */}
                <Modal isOpen={this.state.modalCreate} toggle={this.successRequest} className={this.props.className}>
                    <ModalHeader toggle={this.successRequest} className='ModalHeaderTitle'>Éxito</ModalHeader>
                    <ModalBody>
                        Transferencia hecha correctamente
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={() => this.successRequest() }>Cerrar</Button>
                    </ModalFooter>
                </Modal>
                </div>

                <div>
                {/* MODAL TRANSFERENCIA ACTIVE */}
                <Modal isOpen={this.state.modalPending} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>Advertencia</ModalHeader>
                    <ModalBody>
                        Este usuario tiene una transferencia pendiente
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.closeModal() }>Cerrar</Button>
                    </ModalFooter>
                </Modal>
                </div>

                <div>
                {/* MODAL NO RECURSOS ACTIVE */}
                <Modal isOpen={this.state.modalNoResorces} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>Advertencia</ModalHeader>
                    <ModalBody>
                        No se puede crear una transferencia con ítems inexistentes
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.closeModal() }>Cerrar</Button>
                    </ModalFooter>
                </Modal>
                </div>
                <div>
                {/* MODAL NO WEAREHOUSES */}
                <Modal isOpen={this.state.modalWarehouse} toggle={() => this.goComponent('warehouse')} className={this.props.className}>
                    <ModalHeader toggle={() => this.goComponent('warehouse')} className='ModalHeaderTitle'>
                       No hay bodegas creadas
                    </ModalHeader>
                    <ModalBody>
                        Ir a crear bodega
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.goComponent('warehouse')}>Crear</Button>
                    </ModalFooter>
                </Modal>
                </div>
            </>
        );
    }
}


export default Transactions;