import React from 'react';
import { Field, reduxForm } from 'redux-form'
// import PropTypes from 'prop-types';
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    // Row,
    Col
  } from "reactstrap";


const validate = values => {
    const error = {};
    if(!values.username){
        error.username = 'Este campo es requerido'
    }
    if(!values.password) {
        error.password = 'Este campo es requerido'
    }
    return error;
};

const FieldForm = ({input, meta, ...props}) => {
    return (<div>
        <Input {...props} {...input} />
        {
            meta.touched && meta.error && <span>{meta.error}</span>
        }
    </div>)
};

const LoginForm = (props) => {
    const { handleSubmit , submitting } = props;
    return (
        <>
            <Col lg="5" md="7" className="login-box">
                <Card className="bg-secondary shadow border-0 box-login">
                    <CardBody className="px-lg-4 py-lg-4">
                        <div className="text-login-container text-center text-muted mb-3">
                            <small>Ingresa con tus credenciales</small>
                        </div>
                        <Form onSubmit={handleSubmit}>
                            <FormGroup className="mb-3">
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fas fa-user"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                        <Field
                                            placeholder="Usuario"
                                            name="username"
                                            type="text"
                                            component={FieldForm} 
                                        />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <InputGroup size="lg" className=" input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-lock-circle-open" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                        <Field
                                            placeholder="Contrase&ntilde;a"
                                            name="password"
                                            type="password"
                                            component={FieldForm}
                                        />
                                </InputGroup>
                            </FormGroup>
                            {/* <div className="custom-control custom-control-alternative custom-checkbox">
                                <input
                                    className="custom-control-input"
                                    id=" customCheckLogin"
                                    type="checkbox"
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor=" customCheckLogin"
                                >
                                    <span className="text-muted">Recuerdame</span>
                                </label>
                            </div> */}
                            <div className="text-center">
                                {
                                    !submitting ?
                                    <Button className="my-4" color="primary" type="submit" disabled={submitting}>Ingresa</Button>
                                    : <Button className="my-4" color="primary" type="submit" disabled={submitting}>..Cargando</Button>
                                }
                            </div> 
                        </Form>
                    </CardBody>
                </Card>
                {/* <Row className="mt-2">
                    <Col xs="6">
                        <a
                            className="text-light"
                            href="/"
                            onClick={e => e.preventDefault()}
                        >
                            <small>¿Olvidaste tu contrase&ntilde;a?</small>
                        </a>
                    </Col>
                    <Col className="text-right" xs="6">
                        <small style={{ color: '#ced4da' }}>&copy; GPROG</small>
                    </Col>
                </Row> */}
            </Col>
        </>
    )
}

export default reduxForm({
    form: 'loginForm',
    validate
})(LoginForm)