import { appFetch } from './fetch';
import host from './host';
import auth from './auth';

export const getClients  = async () => {
    const url = `${host}/clients/get_all`;
    return await appFetch({ url, auth: auth.getToken(), method: 'GET' });
};

export const createClients = async data => {
    const url = `${host}/client/create`;
    return await appFetch({ url, auth: auth.getToken(), body: JSON.stringify(data), method: 'POST' })
}

export const updateClients = async (id, data) => {
    const url = `${host}/client/update/${id}`;
    return await appFetch({ url, auth: auth.getToken(), body: JSON.stringify(data), method: 'PUT' })
}

export const getPricingPlans = async (id, data) => {
    const url = `${host}/pricing_plans/get_all`;
    return await appFetch({ url, auth: auth.getToken(), method: 'GET' });
}