import React, { Component } from 'react';
import { Card, CardHeader, Container, Row,  Button, Table,
    DropdownMenu,DropdownItem, UncontrolledDropdown, DropdownToggle,
    Modal, ModalBody, ModalHeader, ModalFooter, 
    // Form, Label, FormGroup, Input, Col,
} from "reactstrap";
import AdminHeader from "../../Shared/Headers/AdminHeader";
import { CircleSpinner } from 'react-spinners-kit';
import CurrencyFormat from 'react-currency-format';
import '../../../assets/css/dashboard/dashboard.css';
import { getPricesList, updatePricesList } from './../../../api/pricesList';

// TOAST
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

const toastOptions = {
    positionClass: 'toast-top-center',
    hideDuration: 300,
    timeOut: 3000,
    progressBar: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
}

class Price_list extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            submit: true,
            Price_list: [],
            price: null,

            modalNoData: false,
            modalNew: false,
            modalEdit:false,
            modalDelete: false,

            id: '',
            PricesListname: '',
    
        };

        this.closeModal = this.closeModal.bind(this);

        this.addPricesList = this.addPricesList.bind(this);

        this.deleteItem = this.deleteItem.bind(this);
        this.submitDeleteItem = this.submitDeleteItem.bind(this);
        this.deletePricesListData = this.deletePricesListData.bind(this);
    }

    async fetchData() {
        const result = await getPricesList();
        if(result.response.status === 500) {
            alert('Error en el servidor');
            this.setState({ Price_list: [], loading: false})
        }
        if(result.response.status === 404) {
            this.setState({ Price_list: result.response.Price_list, loading: false, modalNoData: true})
        }
        else {
            let modalNoData = false;
            if(result.response.Price_list.length <= 0) modalNoData = true;
            this.setState({ Price_list: result.response.Price_list, loading: false, modalNoData})
        }        
    }
    
    componentDidMount() {
        this.fetchData();  
    }

    addPricesList() {
        this.props.history.push('/admin/create_price_list');   
    }

    closeModal() {
        this.setState({
            modalNoData: false,
            modalNew: false,
            modalEdit:false,
            modalDelete: false,
            submit: true,            
            PricesListname: '',
            id: ''
        })
    }

    appendObjTo = (thatArray, newObj) => {
        const frozenObj = Object.freeze(newObj);
        return Object.freeze(thatArray.concat(frozenObj));
    }

    deleteItem(item) {
        this.setState({ modalDelete: true, id: item.id_price_list })
    }

    submitDeleteItem(e) {
        e.preventDefault();
        let { id } = this.state;
        let data = {
            id_status: 2
        }
        updatePricesList(id,data)
        .then( res => {
            if(res.response.status !== 200) {
                alert(res.response.message);
            }
            else {
                this.deletePricesListData(res.response.price_list);
                this.toastDeletePricesList(res.response.price_list.name)
                this.closeModal();
            }
        })
        .catch( err =>{
            alert(err)
        });
    }

    async deletePricesListData(data) {
        var id = data.id_PricesList;
        let { Price_list } = this.state;
        try {
            let index = Price_list.findIndex(Price_list => Price_list.id_PricesList === id);
            if(index !== -1) Price_list.splice(index, 1);
            else this.setState({ Price_list })
        }
        catch {
            window.location.reload();
        }
    }

    toastDeletePricesList(name) {
        toastr.options = toastOptions;
        toastr.clear()
        setTimeout(() => toastr.success(`Lista de precio ${name ? name: ''} eliminada`), 300)
    }

    render() {
        const { Price_list } = this.state;
        return (
            <>
                <AdminHeader {...this.props}/>
                <Container className="mt--7" fluid> 
                    <div>
                        <Row>
                            <div className="col">
                                <Card className="shadow">
                                    <CardHeader className="border-0">
                                        <Row>
                                            <div className="col-6">
                                                <h3 className="mb-0 d-inline">Lista de precios</h3>
                                            </div>
                                            <div className="col-6">
                                                <Button type="button" onClick={() => this.addPricesList()} style={{ padding: '0.3rem 0.7rem' }} className="float-right btn btn-outline-success btn-circle">
                                                    <i className="fa fa-plus"></i>
                                                </Button>
                                            </div>
                                        </Row>
                                    </CardHeader>
                                    {
                                        this.state.loading ? 
                                        <div className='spinner-table'><CircleSpinner
                                                size={30}
                                                color="#11cdef"
                                                loading={this.state.loading}
                                            /></div>
                                        :
                                        <Table className="align-items-center table-flush" responsive>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th className='itemCenter' scope="col">Nombre</th>
                                                    <th className='itemCenter' scope="col">Tipo de plan</th>
                                                    <th className='itemCenter' scope="col">Mano de Obra</th>
                                                    {/* <th className='itemCenter' scope="col">Estado</th> */}
                                                    <th className='itemCenter' scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    Price_list ? 
                                                    Price_list.map((item, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td className='itemCenter'>{item.name ? item.name: <p>-</p>}</td>
                                                                <td className='itemCenter'>{item.pricing_plan ? item.pricing_plan: <p>-</p>}</td>
                                                                <td className='itemCenter'>{item.workforce  ?  <CurrencyFormat value={item.workforce} displayType={'text'} decimalScale={2} thousandSeparator={true} suffix={' COP'} /> : <p>-</p>}</td>
                                                                {/* <td className='itemCenter'>{item.status ? item.status: <p>-</p>}</td> */}
                                                                <td className="text-right">
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle
                                                                            className="btn-icon-only text-light"
                                                                            href="#"
                                                                            role="button"
                                                                            size="sm"
                                                                            color=""
                                                                            onClick={e => e.preventDefault()}>
                                                                            <i className="fas fa-ellipsis-v" />
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu-arrow" right>
                                                                            {/* <DropdownItem
                                                                                onClick={() => this.editPricesList(item)}
                                                                            >
                                                                                <i className="fas fa-edit" />
                                                                                Editar
                                                                            </DropdownItem> */}
                                                                            <DropdownItem
                                                                                onClick={() => this.deleteItem(item)}
                                                                            >
                                                                                <i className="fas fa-trash" />
                                                                                Eliminar
                                                                            </DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </Table>
                                    }
                                    </Card>
                                </div>
                            </Row>
                    </div>
                </Container>
                <div>
                {/* MODAL NO DATA */}
                <Modal isOpen={this.state.modalNoData} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>NO DATA</ModalHeader>
                    <ModalBody>
                        No hay listas de precios registrados
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                    </ModalFooter>
                </Modal>
                </div>
        
                <div>
                {/* MODAL DELETE */}
                <Modal isOpen={this.state.modalDelete} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>
                        Eliminar {Price_list ? Price_list.name : 'Lista de precio'}
                    </ModalHeader>
                    <ModalBody>
                        ¿Deseas eliminar esta Lista de precio?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                        <Button color="danger" onClick={this.submitDeleteItem}>Eliminar</Button>
                    </ModalFooter>
                </Modal>
                </div>
            </>
        );
    }
}

export default Price_list;