import { appFetch } from './fetch';
import host from './host';
import auth from './auth';

export const getSpareParts  = async () => {
    const url = `${host}/spare_parts/get_all`;
    return await appFetch({ url, auth: auth.getToken(), method: 'GET' });
};

export const createSparePart = async data => {
    const url = `${host}/spare_part/create`;
    return await appFetch({ url, auth: auth.getToken(), body: JSON.stringify(data), method: 'POST' })
}

export const updateSparePart = async (id, data) => {
    const url = `${host}/spare_part/update/${id}`;
    return await appFetch({ url, auth: auth.getToken(), body: JSON.stringify(data), method: 'PUT' })
}

export const searchSparePart = async (query) => {
    const url = `${host}/spare_part/get_one/${query}`;
    return await appFetch({ url, auth: auth.getToken(), method: 'GET' });
};