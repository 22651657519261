import React, { Component } from 'react';
import { Card, CardHeader, Container, Row,  Button, Table,
    DropdownMenu,DropdownItem, UncontrolledDropdown, DropdownToggle,
    Modal, ModalBody, ModalHeader, ModalFooter, 
    Form, Label, FormGroup, Input, Col
} from "reactstrap";
import AdminHeader from "../../Shared/Headers/AdminHeader";
import './../../../assets/css/dashboard/dashboard.css';
import { CircleSpinner } from 'react-spinners-kit';
import { getWarehouse, createWarehouse, updateWarehouse } from './../../../api/warehouses';
import { getServiceCenter } from './../../../api/serviceCenters'
import { getUsers } from './../../../api/users'
// import auth from './../../../api/auth'
// TOAST
import toastr from 'toastr';
// import 'toastr/build/toastr.min.css';

const toastOptions = {
    positionClass: 'toast-top-center',
    hideDuration: 300,
    timeOut: 3000,
    progressBar: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
}

class Warehouses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            submit: false,
            Warehouses: null,
            user: null,

            ServiceCenters: [],
            id_service_center: '',

            modalNoData: false,
            modalNew: false,
            modalEdit:false,
            modalDelete: false,
            modalServiceCenter: false,

            name: '',
            id: '',

            Users: [],
            id_user: ''
        };

        this.closeModal = this.closeModal.bind(this);

        this.addNewItem = this.addNewItem.bind(this);
        this.handleAddItem = this.handleAddItem.bind(this);
        this.submitAddItem = this.submitAddItem.bind(this);
        this.NewItem = this.NewItem.bind(this);

        this.editItem = this.editItem.bind(this);
        this.submitUpdateData = this.submitUpdateData.bind(this);
        this.handleUpdateItem = this.handleUpdateItem.bind(this);

        this.deleteItem = this.deleteItem.bind(this);
        this.submitDeleteData = this.submitDeleteData.bind(this);
        this.deleteItemData = this.deleteItemData.bind(this);

        this.goServiceCenter = this.goServiceCenter.bind(this);
    }

    async fetchData() {
        const resultService = await getServiceCenter()
        if(resultService.response.status === 200) {
            if(resultService.response.Services_center.length === 0) {
                this.setState({modalServiceCenter: true })
                return;
            }
            else {
                this.setState({ 
                    ServiceCenters: resultService.response.Services_center,
                    id_service_center: resultService.response.Services_center[0].id_service_center
                })
            }
        }
        const resultUsers = await getUsers()
        if(resultUsers.response.status === 200) {
            this.setState({ 
                Users: resultUsers.response.Users,
                id_user: resultUsers.response.Users[0].id_user
            })
        }
        const result = await getWarehouse();
        if(result.response.status === 500) {
            alert('Error en el servidor');
            this.setState({ Warehouses: [], loading: false})
        }
        if(result.response.status === 404) {
            this.setState({ 
                Warehouses: result.response.Warehouses, 
                loading: false, modalNoData: true
            })
        }
        else {
            let modalNoData = false;
            let length = result.response.Warehouses ? result.response.Warehouses.length : 0;
            if(length === 0) modalNoData = true;
            this.setState({ 
                Warehouses: result.response.Warehouses, 
                loading: false, modalNoData: modalNoData,
                id_user: resultUsers.response.Users[0].id_user})
        }        
    }
    
    componentDidMount() {
        this.fetchData();  
    }

    closeModal() {
        this.setState({
            modalNoData: false,
            modalNew: false,
            modalEdit:false,
            modalDelete: false,
            modalServiceCenter: false,

            submit: false,
            name: '',
            id: '',
            id_user: '',
            id_warehouse: ''
        })
    }

    addNewItem() {
        this.setState({modalNew: true})
    }

    handleAddItem(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    submitAddItem(e) {
        e.preventDefault();
        this.setState({ submit: true})
        let data = {
            name: this.state.name,
            // id_user: this.state.id_user ? this.state.id_user : '5b35df80-6e14-11ea-84e5-ddc10e87329d',
            id_service_center: this.state.id_service_center
        }
        createWarehouse(data)
        .then( res => {
            if(res.response.status !== 200) {
                alert(res.response.message);
            }
            else {
                this.closeModal();
                this.NewItem(res.response.warehouse);
                this.toastCreateItem(res.response.warehouse.name);
            }
        })
        .catch( err =>{
            alert(err)
        });
    }

    toastCreateItem(name) {
        toastr.options = toastOptions;
        toastr.clear()
        setTimeout(() => toastr.success(`Bodega ${name ? name : ''} creada`), 300)
    }

    async NewItem(item) {
        if(!this.state.Warehouses) this.state.Warehouses = [];
        let newItem = this.appendObjTo(this.state.Warehouses, item);
        this.setState({ Warehouses: newItem })
    }

    editItem(item) {
        this.setState({
            modalEdit: true, 
            id: item.id_warehouse, 
            name: item.name,
            // id_user:item.id_user,
            id_service_center:item.id_service_center
        })
    }

    handleUpdateItem(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    submitUpdateData(e) {
        e.preventDefault();
        this.setState({ submit: true })
        const { id, name, id_service_center  } = this.state;  
        let data = { name, id_service_center }
        updateWarehouse(id, data)
            .then( res => {
                if(res.response.status !== 200) {
                    alert(res.response.message);
                }
                else {
                    this.toastUpdateItem(res.response.warehouse.name)
                    this.updateItemData(res.response.warehouse);
                    this.closeModal();
                }
            })
            .catch( err =>{
                alert(err)
            });
    }

    toastUpdateItem(name) {
        toastr.options = toastOptions;
        toastr.clear()
        setTimeout(() => toastr.success(`Bodega ${name ? name : ''} actualizada`), 300)
    }

    async updateItemData(data) {
        try {
            var id = data.id_warehouse;
            let { Warehouses } = this.state; 
            let index = Warehouses.findIndex(item => item.id_warehouse === id);
            if(index !== -1){
                Warehouses[index].name = data.name;
                Warehouses[index].id_user = data.id_user;
                // Warehouses[index].user = data.user;
                // Warehouses[index].id_user = data.id_user;
                Warehouses[index].status = data.status;
                Warehouses[index].id_status = data.id_status;
                Warehouses[index].service_center = data.service_center;
                Warehouses[index].id_service_center = data.id_service_center;
                this.setState({ Warehouses })
            }
            else this.setState({ Warehouses })
        } catch (error) {
            window.location.reload();
        }
        
    }

    deleteItem(item) {
        this.setState({modalDelete: true, item})
    }

    submitDeleteData(e) {
        e.preventDefault();
        this.setState({submit: true})
        const { item } = this.state;  
        let data = {
            id_status: 2
        }
        updateWarehouse(item.id_warehouse, data)
            .then( res => {
                if(res.response.status !== 200) {
                    alert(res.response.message);
                }
                else {
                    this.toastDeleteItem(res.response.warehouse.name);
                    this.deleteItemData(res.response.warehouse);
                    this.closeModal();
                }
            })
            .catch( err =>{
                alert(err)
            });
    }

    async deleteItemData(data) {
        var id = data.id_warehouse;      
        let { Warehouses } = this.state;
        try {
            let index = Warehouses.findIndex(item => item.id_warehouse === id);
            if(index !== -1) Warehouses.splice(index, 1);
            else this.setState({ Warehouses })
        }
        catch {
            window.location.reload();
        }
    }

    toastDeleteItem(name) {
        toastr.options = toastOptions;
        toastr.clear()
        setTimeout(() => toastr.success(`bodega ${name ? name: ''} eliminada`), 300)
    }

    appendObjTo = (thatArray, newObj) => {
        const frozenObj = Object.freeze(newObj);
        return Object.freeze(thatArray.concat(frozenObj));
    }
    
    goServiceCenter() {
        this.props.history.push('/admin/services_centers');  
    }

    render() {
        const { Warehouses, ServiceCenters } = this.state;
        return (
            <>
                <AdminHeader {...this.props}/>
                <Container className="mt--7" fluid> 
                    <div>
                        <Row>
                            <div className="col">
                                <Card className="shadow">
                                    <CardHeader className="border-0">
                                        <Row>
                                            <div className="col-6">
                                                <h3 className="mb-0 d-inline">Bodegas</h3>
                                            </div>
                                            <div className="col-6">
                                                <Button type="button" onClick={() => this.addNewItem()} style={{ padding: '0.3rem 0.7rem' }} className="float-right btn btn-outline-success btn-circle">
                                                    <i className="fa fa-plus"></i>
                                                </Button>
                                            </div>
                                        </Row>
                                    </CardHeader>
                                    {
                                        this.state.loading ? 
                                        <div className='spinner-table'><CircleSpinner
                                                size={30}
                                                color="#11cdef"
                                                loading={this.state.loading}
                                            /></div>
                                        :
                                        <Table className="align-items-center table-flush" responsive>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th className='itemCenter' scope="col">Bodega</th>
                                                    <th className='itemCenter' scope="col">Centro de servicio</th>
                                                    {/* <th className='itemCenter' scope="col">Usuario</th> */}
                                                    {/* <th className='itemCenter' scope="col">Estado</th> */}
                                                    <th className='itemCenter' scope="col" />
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    Warehouses ? 
                                                    Warehouses.map((item, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td className='itemCenter'>{item.name ? item.name : <p>-</p>}</td>
                                                                <td className='itemCenter'>{item.service_center ? item.service_center : <p>-</p>}</td>
                                                                {/* <td className='itemCenter'>{item.user ? item.user : <p>-</p>}</td> */}
                                                                {/* <td className='itemCenter'>{item.status ? item.status : <p>-</p>}</td> */}
                                                                <td className="text-right">
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle
                                                                            className="btn-icon-only text-light"
                                                                            href="#"
                                                                            role="button"
                                                                            size="sm"
                                                                            color=""
                                                                            onClick={e => e.preventDefault()}>
                                                                            <i className="fas fa-ellipsis-v" />
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu-arrow" right>
                                                                            <DropdownItem
                                                                                onClick={() => this.editItem(item)}
                                                                            >
                                                                                <i className="fas fa-edit" />
                                                                                Editar
                                                                            </DropdownItem>
                                                                            <DropdownItem
                                                                                onClick={() => this.deleteItem(item)}
                                                                            >
                                                                                <i className="fas fa-trash" />
                                                                                Eliminar
                                                                            </DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </Table>
                                    }
                                    </Card>
                                </div>
                            </Row>
                    </div>
                </Container>

                {/* MODAL NO DATA */}
                <div>
                <Modal isOpen={this.state.modalNoData} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>NO DATA</ModalHeader>
                    <ModalBody>
                        No hay bodegas registradas
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                    </ModalFooter>
                </Modal>
                </div>

                {/* MODAL NEW */}
                <div>
                <Modal isOpen={this.state.modalNew} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>Agregar una nueva bodega</ModalHeader>
                    <Form onSubmit={this.submitAddItem}>
                    <ModalBody>
                        <FormGroup row>
                            <Label for="failure" sm={4}>Nombre bodega</Label>
                            <Col sm={8}>
                            <Input 
                                type="text"
                                name="name"
                                id="name" 
                                placeholder="Ingresa el nombre de la bodega"
                                onChange={this.handleAddItem}
                                value={this.state.name}
                                required />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="id_model" sm={4}>Centro de servicios</Label>
                            <Col sm={8}>
                            <Input 
                                type="select"
                                name="id_service_center"
                                id="id_service_center"
                                onChange={this.handleAddItem}
                                value={this.state.id_service_center}>
                                {
                                    ServiceCenters === [] ? <option>1</option> :
                                    ServiceCenters.map((item, key) => {
                                        return(
                                            <option key={key} value={item.id_service_center}>{item.name}</option>
                                        )
                                    })
                                }
                            </Input>
                            </Col>
                        </FormGroup>
                        {/* <FormGroup row>
                            <Label for="id_model" sm={4}>Usuario Bodega</Label>
                            <Col sm={8}>
                            <Input 
                                type="select"
                                name="id_user"
                                id="id_user"
                                onChange={this.handleAddItem}
                                value={this.state.id_user}>
                                {
                                    Users === [] ? <option>1</option> :
                                    Users.map((item, key) => {
                                        return(
                                            <option key={key} value={item.id_user}>{item.name}</option>
                                        )
                                    })
                                }
                            </Input>
                            </Col>
                        </FormGroup> */}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                        <Button color="success" type="submit" disabled={this.state.submit} >Crear</Button>
                    </ModalFooter>
                    </Form>
                </Modal>
                </div>

                {/* MODAL UPDATE ITEM */}
                <div>
                <Modal isOpen={this.state.modalEdit} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>
                        Editar bodega {this.state.item ? this.state.item.name : ''}
                    </ModalHeader>
                    <Form onSubmit={this.submitUpdateData}>
                    <ModalBody>
                    <FormGroup row>
                            <Label for="failure" sm={4}>Nombre bodega</Label>
                            <Col sm={8}>
                            <Input 
                                type="text"
                                name="name"
                                id="name" 
                                placeholder="Ingresa el nombre de la bodega"
                                onChange={this.handleUpdateItem}
                                value={this.state.name}
                                required />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="id_model" sm={4}>Centro de servicios</Label>
                            <Col sm={8}>
                            <Input 
                                type="select"
                                name="id_service_center"
                                id="id_service_center"
                                onChange={this.handleUpdateItem}
                                value={this.state.id_service_center}>
                                {
                                    ServiceCenters === [] ? <option>1</option> :
                                    ServiceCenters.map((item, key) => {
                                        return(
                                            <option key={key} value={item.id_service_center}>{item.name}</option>
                                        )
                                    })
                                }
                            </Input>
                            </Col>
                        </FormGroup>
                        {/* <FormGroup row>
                            <Label for="id_model" sm={4}>Usuario Bodega</Label>
                            <Col sm={8}>
                            <Input 
                                type="select"
                                name="id_user"
                                id="id_user"
                                onChange={this.handleUpdateItem}
                                value={this.state.id_user}>
                                {
                                    Users === [] ? <option>1</option> :
                                    Users.map((item, key) => {
                                        return(
                                            <option key={key} value={item.id_user}>{item.name}</option>
                                        )
                                    })
                                }
                            </Input>
                            </Col>
                        </FormGroup> */}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                        <Button color="success" type="submit" disabled={this.state.submit}>Actualizar</Button>
                    </ModalFooter>
                    </Form>
                </Modal>
                </div>

                <div>
                {/* MODAL DELETE */}
                <Modal isOpen={this.state.modalDelete} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>
                        Eliminar {this.state.item ? this.state.item.name : 'bodega'}
                    </ModalHeader>
                    <ModalBody>
                        ¿Deseas eliminar esta bodega?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                        <Button color="danger" onClick={this.submitDeleteData} disabled={this.state.submit}>Eliminar</Button>
                    </ModalFooter>
                </Modal>
                </div>

                <div>
                {/* MODAL NO SERVICE CENTERS */}
                <Modal isOpen={this.state.modalServiceCenter} toggle={this.goServiceCenter} className={this.props.className}>
                    <ModalHeader toggle={this.goServiceCenter} className='ModalHeaderTitle'>
                       No hay centros de servicios creados
                    </ModalHeader>
                    <ModalBody>
                        Ir a crear centro de servicio
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.goServiceCenter}>Crear</Button>
                    </ModalFooter>
                </Modal>
                </div>
            </>
        );
    }
}

export default Warehouses;