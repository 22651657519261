import { appFetch } from './fetch';
import host from './host';
import auth from './auth';

export const getCauses  = async () => {
    const url = `${host}/causes/get_all`;
    return await appFetch({ url, auth: auth.getToken(), method: 'GET' });
};

export const createCause = async data => {
    const url = `${host}/cause/create`;
    return await appFetch({ url, auth: auth.getToken(), body: JSON.stringify(data), method: 'POST' })
}

export const updateCause = async (id, data) => {
    const url = `${host}/cause/update/${id}`;
    return await appFetch({ url, auth: auth.getToken(), body: JSON.stringify(data), method: 'PUT' })
}