import React, { Component } from 'react';
import { Card, CardHeader, Container, Row,  Button, Table,
    DropdownMenu,DropdownItem, UncontrolledDropdown, DropdownToggle,
    Modal, ModalBody, ModalHeader, ModalFooter, Progress, 
    Form, Label, FormGroup, Input, Col
} from "reactstrap";
import AdminHeader from "../../Shared/Headers/AdminHeader";
import { CircleSpinner } from 'react-spinners-kit';
import axios from 'axios';
import './../../../assets/css/dashboard/dashboard.css';
import { getDevices, createDevices, updateDevices } from '../../../api/devices';
import { getWarehouse } from './../../../api/warehouses';
import { getClients } from './../../../api/clients';
import { getServiceCenter } from './../../../api/serviceCenters';
import auth from "./../../../api/auth.js";

// TOAST
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

const toastOptions = {
    positionClass: 'toast-top-center',
    hideDuration: 300,
    timeOut: 3000,
    progressBar: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
}


class Devices extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            submit: false,
            Devices: [],

            item: null,

            modalNoData: false,
            modalNew: false,
            modalEdit:false,
            modalDelete: false,
            modalFacturada: false,
            modalWarehouse: false,
            modalServiceCenter: false,
            modalClients: false,

            device: '',
            id_status: '',
            id: '',

            loaded:0,

            display: false,

            Warehouses: [],
            id_warehouse: '',

            Clients: [],
            id_client: '',

            Services_center: [],
            id_service_center: '',


            user: auth.getUser()
        };

        this.closeModal = this.closeModal.bind(this);

        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.addMassiveBatch = this.addMassiveBatch.bind(this);
        this.submitAddBatch = this.submitAddBatch.bind(this);

        this.addNewItem = this.addNewItem.bind(this);
        this.handleAddItem = this.handleAddItem.bind(this);
        this.submitAddItem = this.submitAddItem.bind(this);

        this.editItem = this.editItem.bind(this);
        this.submitUpdateData = this.submitUpdateData.bind(this);
        this.handleUpdateItem = this.handleUpdateItem.bind(this);

        this.deleteItem = this.deleteItem.bind(this);
        this.submitDeleteData = this.submitDeleteData.bind(this);
        this.deleteItemData = this.deleteItemData.bind(this);

        this.facturado = this.facturado.bind(this);
        this.submitfacturado = this.submitfacturado.bind(this);

        this.handleChangeForm = this.handleChangeForm.bind(this);

        this.goComponent = this.goComponent.bind(this);
    }

    async fetchData() {
        const resultWarehouse = await getWarehouse()
        if(resultWarehouse.response.status === 200 ) {
            if(resultWarehouse.response.Warehouses.length === 0) {
                this.setState({ modalWarehouse: true })
                return;
            }
            else {
                this.setState({
                    Warehouses: resultWarehouse.response.Warehouses,
                    id_warehouse: resultWarehouse.response.Warehouses[0].id_warehouse
                })
            }
        }
        
        const resultCliens= await getClients()
        if(resultCliens.response.status === 200 ) {
            if(resultCliens.response.Clients.length === 0) {
                this.setState({ modalClients: true })
                return;
            }
            else {
                this.setState({ 
                    Clients: resultCliens.response.Clients,
                    id_client: resultCliens.response.Clients[0].id_client
                })
            }
        }
        
        const resultService = await getServiceCenter()
        if(resultService.response.status === 200 ) {
            if(resultService.response.Services_center.length === 0) {
                this.setState({ modalServiceCenter: true })
                return;
            }
            else {
                this.setState({ 
                    Services_center: resultService.response.Services_center,
                    id_service_center: resultService.response.Services_center[0].id_service_center
                })
            }
        }
        
        const result = await getDevices();
        if(result.response.status === 500) {
            alert('Error en el servidor');
            this.setState({ Devices: [], loading: false})
        }
        if(result.response.status === 404) {
            this.setState({ Devices: result.response.Devices, loading: false, modalNoData: true})
        }
        else {
            let modalNoData = false;
            let length = result.response.Devices ? result.response.Devices.length : 0;
            if(length === 0) modalNoData = true;
            this.setState({ Devices: result.response.Devices, loading: false, modalNoData: modalNoData})
        }        
    }
    
    componentDidMount() {
        this.fetchData();  
    }

    addMassiveBatch() {
        this.setState({ modalNew: true })
    }

    checkMimeType = (event) => {
        //getting file object
        let files = event.target.files
        //define message container
        let err = ''
        // list allow mime type
        const types = [
            'text/csv', 'text/xls', 'text/xlsx', 'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
        // loop access array
        for (var i = 0; i < files.length; i++) {
            // compare file type find doesn't matach
            if (types.every(type => files[i].type !== type)) {
                // create error message and assign to container   
                err += files[i].type + ' is not a supported format\n';
            }
        };

        if (err !== '') { // if message not same old that mean has error 
            event.target.value = null // discard selected file
            this.toastWrongExtension()
            return false;
        }
        return true;
    }

    onChangeHandler=event=>{
        if( this.checkMimeType(event) ){ 
            this.setState({
                selectedFile: event.target.files[0],
                loaded: 0,
            })
        }
    }

    submitAddBatch(e) {
        e.preventDefault();
        let file = new FormData();
        file.append('devices', this.state.selectedFile)
        this.setState({ submit: true, display: true})
        let authToken = auth.getToken()
        // UPLOAD CSV
        axios.post(`${process.env.REACT_APP_API_URL}/devices/create/csv`, file, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'dash-authorization': authToken,
            },
            onUploadProgress: ProgressEvent => {
                this.setState({
                    loaded: (ProgressEvent.loaded / ProgressEvent.total * 100)
                })
            },
            params: {
                id_warehouse: this.state.id_warehouse,
                id_user: this.state.user.id_user,
                id_client: this.state.id_client,
                id_service_center: this.state.id_service_center
            }
        })
        .then(async res => { 
            if (res.status === 204 || res.status === 401) {
                auth.removeUser();
                auth.removeToken();
                window.location.reload();
                alert('NO AUTORIZADO')
                return res
            }
            if(res.status !== 200 ) alert(res.message)
            else {
                this.setState({ submit: false, selectedFile: null})
                await this.sleep(2000)
                this.setState({ modalCreate: true, modalNew: false, display: false})
            }
        })
        .catch(error => {
            alert(error)
        })
        
    }

    closeModal() {
        this.setState({
            modalNoData: false,
            modalNew: false,
            modalEdit:false,
            modalDelete: false,
            submit: false,
            modalFacturada: false,
            modalCreate: false,

            item: null,

            device: '',
            hide: true,
            loaded:0
        })
    }

    addNewItem() {
        this.setState({modalNew: true})
    }

    handleAddItem(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleChangeForm(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    submitAddItem(e) {
        e.preventDefault();
        this.setState({ submit: true})
        createDevices({device: this.state.device})
        .then( res => {
            if(res.response.status !== 200) {
                alert(res.response.message);
            }
            else {
                this.NewItem(res.response.device);
                this.toastCreateItem();
                this.closeModal();
            }
        })
        .catch( err =>{
            alert(err)
        });
    }

    toastCreateItem() {
        toastr.options = toastOptions;
        toastr.clear()
        setTimeout(() => toastr.success(`Modelo creado`), 300)
    }

    async NewItem(item) {
        if(!this.state.Devices) this.state.Devices = [];
        let newItem = this.appendObjTo(this.state.Devices, item);
        this.setState({ Devices: newItem })
    }


    editItem(item) {
        this.setState({modalEdit: true, id: item.id_model, item,
            device: item.device, description: item.description })
    }

    handleUpdateItem(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    submitUpdateData(e) {
        e.preventDefault();
        this.setState({ submit: true})
        const { id, device } = this.state;   
        updateDevices(id, { device: device })
            .then( res => {
                if(res.response.status !== 200) alert(res.response.message);
                else {
                    this.toastUpdateItem()
                    this.updateItemData(res.response.device);
                    this.closeModal();
                }
            })
            .catch( err =>{
                alert(err)
            });
    }

   

    deleteItem(item) {
        this.setState({modalDelete: true, item})
    }

    submitDeleteData(e) {
        e.preventDefault();
        this.setState({ submit: true})
        const { item } = this.state;  
        let data = {
            id_status: 2
        }
        updateDevices(item.id_model, data)
            .then( res => {
                if(res.response.status !== 200) {
                    alert(res.response.message);
                }
                else {
                    this.toastDeleteItem();
                    this.deleteItemData(res.response.device);
                    this.closeModal();
                }
            })
            .catch( err =>{
                alert(err)
            });
    }

    async deleteItemData(data) {
        var id = data.id_model;
        let { Devices } = this.state;
        let index = Devices.findIndex(fail => fail.id_model === id);
        if(index !== -1) Devices.splice(index, 1);
        else this.setState({ Devices })
    }

    toastDeleteItem(name) {
        toastr.options = toastOptions;
        toastr.clear()
        setTimeout(() => toastr.success(`Modelo ${name ? name: ''} eliminado`), 300)
    }

    facturado(item) {
        this.setState({ modalFacturada: true, item})
    }

    submitfacturado(e) {
        e.preventDefault();
        const { item } = this.state;
        let data = {
            factured: true
        }
        this.setState({ submit: true})
        updateDevices(item.id_device, data)
        .then( res => {
            if(res.response.status !== 200) {
                alert(res.response.message);
            }
            else {
                this.toastUpdateItem()
                this.updateItemData(res.response.device)
                this.closeModal();
            }
        })
        .catch( err =>{
            alert(err)
        });
    }

    toastUpdateItem() {
        toastr.options = toastOptions;
        toastr.clear()
        setTimeout(() => toastr.success(`Dispositivo actualizado`), 300)
    }

    async updateItemData(data) {
        try {
            var id = data.id_device;
            let { Devices } = this.state; 
            let index = parseInt(Devices.findIndex(item => item.id_device === id));
            if(index !== -1){
                Devices[index].serial = data.serial;
                Devices[index].ptid = data.ptid;
                Devices[index].dispatched = data.dispatched;
                Devices[index].id_batch = data.id_batch;
                Devices[index].batch = data.batch;
                Devices[index].model = data.model;
                Devices[index].status = data.status;
                Devices[index].dispatched = data.dispatched;
                Devices[index].factured = data.factured;
                this.setState({ Devices })
            }
            else{
                this.setState({ Devices })
            }
        } catch (error) {
            window.location.reload();
        }
    }

    appendObjTo = (thatArray, newObj) => {
        const frozenObj = Object.freeze(newObj);
        return Object.freeze(thatArray.concat(frozenObj));
    }

    goComponent(component) {
        this.props.history.push(`/admin/${component}`);  
    }

    render() {
        const { Devices, Warehouses, Clients, Services_center, user } = this.state;
        return (
            <>
                <AdminHeader {...this.props}/>
                <Container className="mt--7" fluid> 
                    <div>
                        <Row>
                            <div className="col">
                                <Card className="shadow">
                                    <CardHeader className="border-0">
                                        <Row>
                                            <div className="col-6">
                                                <h3 className="mb-0 d-inline">Dispositivos</h3>
                                            </div>
                                            {/* <div className="col-6">
                                                <Button type="button" onClick={() => this.addMassiveBatch()} style={{ padding: '0.3rem 0.7rem' }} className="float-right btn btn-outline-success btn-circle">
                                                    <i className="fa fa-plus"></i>
                                                </Button>
                                            </div> */}
                                        </Row>
                                    </CardHeader>
                                    {
                                        this.state.loading ? 
                                        <div className='spinner-table'><CircleSpinner
                                                size={30}
                                                color="#11cdef"
                                                loading={this.state.loading}
                                            /></div>
                                        :
                                        <Table className="align-items-center table-flush" responsive>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th className='itemCenter' scope="col">Serial</th>
                                                    <th className='itemCenter' scope="col">Modelo</th>
                                                    <th className='itemCenter' scope="col">Lote</th>
                                                    <th className='itemCenter' scope="col">PTID</th>
                                                    <th className='itemCenter' scope="col">Facturado</th>
                                                    <th className='itemCenter' scope="col">Despachado</th>
                                                    <th className='itemCenter' scope="col">Estado</th>
                                                    <th scope="col" />
                                                    {/* {
                                                        user.id_role!==3 ? '' :<th scope="col" />
                                                        
                                                    } */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    Devices ? 
                                                    Devices.map((item, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td className='itemCenter'>{item.serial ? item.serial: <p>-</p>}</td>
                                                                <td className='itemCenter'>{item.model ? item.model: <p>-</p>}</td>
                                                                <td className='itemCenter'>{item.batch ? item.batch: <p>-</p>}</td>
                                                                <td className='itemCenter'>{item.ptid ? item.ptid: <p>-</p>}</td>
                                                                <td className='itemCenter'>
                                                                    {item.factured ? 
                                                                        <i className="fas fa-check-square"></i>
                                                                        : <i className="fas fa-window-close"></i>}
                                                                </td>
                                                                <td className='itemCenter'>
                                                                    {item.dispatched ? 
                                                                        <i className="fas fa-check-square"></i>
                                                                        : <i className="fas fa-window-close"></i>}
                                                                </td>
                                                                <td className='itemCenter'>{item.status ? item.status: <p>-</p>}</td>
                                                                {
                                                                    user.id_role !== 3 ?
                                                                        <td className="text-right">
                                                                            <UncontrolledDropdown>
                                                                                <DropdownToggle
                                                                                    className="btn-icon-only text-light"
                                                                                    href="#"
                                                                                    role="button"
                                                                                    size="sm"
                                                                                    color=""
                                                                                    onClick={e => e.preventDefault()}>
                                                                                    <i className="fas fa-ellipsis-v" />
                                                                                </DropdownToggle>
                                                                                <DropdownMenu className="dropdown-menu-arrow" right>
                                                                                    <DropdownItem
                                                                                        onClick={() => this.facturado(item)}
                                                                                    >
                                                                                        <i className="fas fa-edit" />
                                                                                Marcar como facturado
                                                                            </DropdownItem>
                                                                                </DropdownMenu>
                                                                            </UncontrolledDropdown>
                                                                        </td>
                                                                        : <td className="text-right"></td>
                                                                }
                                                                
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </Table>
                                    }
                                    </Card>
                                </div>
                            </Row>
                    </div>
                </Container>

                {/* MODAL NO DATA */}
                <div>
                <Modal isOpen={this.state.modalNoData} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>NO DATA</ModalHeader>
                    <ModalBody>
                        No hay modelos registradas
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                    </ModalFooter>
                </Modal>
                </div>

                <div>
                {/* MODAL NEW */}
                <Modal isOpen={this.state.modalNew} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>Carga Masiva Lotes</ModalHeader>
                    <Form onSubmit={this.submitAddBatch}>
                    <ModalBody>
                        <FormGroup row>
                            <Col sm={8}>
                                <Input type="file" name="file" onChange={this.onChangeHandler} required/>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="id_model" sm={4}>Centros de servicio</Label>
                            <Col sm={8}>
                                <Input
                                    type="select"
                                    name="id_service_center"
                                    id="id_service_center"
                                    onChange={this.handleChangeForm}
                                    value={this.state.id_service_center}
                                    required>
                                    {
                                        Services_center === [] ? <option>1</option> :
                                            Services_center.map((item, key) => {
                                                return (
                                                    <option key={key} value={item.id_service_center}>{item.name}</option>
                                                )
                                            })
                                    }
                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="id_model" sm={4}>Bodegas</Label>
                            <Col sm={8}>
                                <Input
                                    type="select"
                                    name="id_warehouse"
                                    id="id_warehouse"
                                    onChange={this.handleChangeForm}
                                    value={this.state.id_warehouse}
                                    required>
                                    {
                                        Warehouses === [] ? <option>1</option> :
                                            Warehouses.map((item, key) => {
                                                return (
                                                    <option key={key} value={item.id_warehouse}>{item.name}</option>
                                                )
                                            })
                                    }
                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="id_model" sm={4}>Clientes</Label>
                            <Col sm={8}>
                                <Input
                                    type="select"
                                    name="id_client"
                                    id="id_client"
                                    onChange={this.handleChangeForm}
                                    value={this.state.id_client}
                                    required>
                                    {
                                        Clients === [] ? <option>1</option> :
                                            Clients.map((item, key) => {
                                                return (
                                                    <option key={key} value={item.id_client}>{item.name}</option>
                                                )
                                            })
                                    }
                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup> 
                            <Progress 
                                max="100" 
                                color="success" 
                                value={this.state.loaded} 
                                style={{height: '20px', display: !this.state.display ? 'none' : ''}}>
                                    {Math.round(this.state.loaded,2) }%
                            </Progress>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                        <Button color="success" type="submit" disabled={this.state.submit}>Crear</Button>
                    </ModalFooter>
                    </Form>
                </Modal>
                </div>
                
                {/* MODAL UPDATE ITEM */}
                <div>
                <Modal isOpen={this.state.modalEdit} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>
                        Editar Modelo {this.state.item ? this.state.item.device : ''}
                    </ModalHeader>
                    <Form onSubmit={this.submitUpdateData}>
                    <ModalBody>
                        <FormGroup row>
                            <Label for="device" sm={4}>Nombre Modelo</Label>
                            <Col sm={8}>
                            <Input 
                                type="text"
                                name="device"
                                id="device" 
                                placeholder="Ingresa el nombre del modelo"
                                onChange={this.handleUpdateItem}
                                value={this.state.device}
                                required />
                            </Col>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                        <Button color="success" type="submit" disabled={this.state.submit}>Actualizar</Button>
                    </ModalFooter>
                    </Form>
                </Modal>
                </div>

                <div>
                {/* MODAL DELETE */}
                <Modal isOpen={this.state.modalDelete} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>
                        Eliminar {this.state.item ? this.state.item.device : 'Falla'}
                    </ModalHeader>
                    <ModalBody>
                        ¿Deseas eliminar esta falla?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                        <Button color="danger" onClick={this.submitDeleteData} disabled={this.state.submit}>Eliminar</Button>
                    </ModalFooter>
                </Modal>
                </div>

                <div>
                {/* MODAL SUCESS CREATE */}
                <Modal isOpen={this.state.modalCreate} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>Éxito</ModalHeader>
                    <ModalBody>
                        Carga masiva subida correctamente
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={ () => { this.closeModal() }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>
                </div>


                <div>
                {/* MODAL FACTURADA */}
                <Modal isOpen={this.state.modalFacturada} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>
                        Marcar como facturado
                    </ModalHeader>
                    <ModalBody>
                        ¿Desea marcar este dispostivo como facturado?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                        <Button color="success" onClick={this.submitfacturado} disabled={this.state.submit}>Actualizar</Button>
                    </ModalFooter>
                </Modal>
                </div>

                <div>
                {/* MODAL NO WEAREHOUSES */}
                <Modal isOpen={this.state.modalWarehouse} toggle={() => this.goComponent('warehouse')} className={this.props.className}>
                    <ModalHeader toggle={() => this.goComponent('warehouse')} className='ModalHeaderTitle'>
                       No hay bodegas creadas
                    </ModalHeader>
                    <ModalBody>
                        Ir a crear bodega
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.goComponent('warehouse')}>Crear</Button>
                    </ModalFooter>
                </Modal>
                </div>


                
                <div>
                {/* MODAL NO SERVICE CENTER */}
                <Modal isOpen={this.state.modalServiceCenter} toggle={() => this.goComponent('services_centers')} className={this.props.className}>
                    <ModalHeader toggle={() => this.goComponent('services_centers')} className='ModalHeaderTitle'>
                       No hay centros de servicios creados
                    </ModalHeader>
                    <ModalBody>
                        Ir a crear centro de servicio
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.goComponent('services_centers')}>Crear</Button>
                    </ModalFooter>
                </Modal>
                </div>

                <div>
                {/* MODAL NO CLIENTS */}
                <Modal isOpen={this.state.modalClients} toggle={() => this.goComponent('clients')} className={this.props.className}>
                    <ModalHeader toggle={() => this.goComponent('clients')} className='ModalHeaderTitle'>
                       No hay clientes creados
                    </ModalHeader>
                    <ModalBody>
                        Ir a crear cliente
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.goComponent('clients')}>Crear</Button>
                    </ModalFooter>
                </Modal>
                </div>
            </>
        );
    }
}

export default Devices;