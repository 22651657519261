import React, { Component } from 'react'
import { Pie } from "react-chartjs-2";

class Charts extends Component {
	
	
	render() {
		return (
			<div>
				{
					this.props.data ? 
					<Pie 
						data={this.props.data} 
						options={{ 
							maintainAspectRatio: true, 
							title: { display: true, text: this.props.title ? this.props.title : ''},
							tooltips: {
								mode: 'label',
								callbacks: {
									label: (tooltipItem, data) => {										
										return data.dataTooltip[tooltipItem.index] ? data.dataTooltip[tooltipItem.index] : ''
									},
									title: (tooltipItem, data) => {
										return `${data.labels[tooltipItem[0].index]}`;
									},
									afterLabel: (tooltipItem, data) => {
										let dataset = data.datasets[tooltipItem.datasetIndex];
										let currentValue = dataset.data[tooltipItem.index];
										return `TOTAL ${currentValue}`;	
									}
								}
							}
						}}/>
					: ''
				}
			</div>
		)
	}
}

export default Charts;