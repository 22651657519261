import React, { Component } from 'react';
import { Card, CardHeader, Container, Row,  Button, Table,
    Modal, ModalBody, ModalHeader, ModalFooter, 
    Label, FormGroup, Input, Col
} from "reactstrap";
import AdminHeader from "../../Shared/Headers/AdminHeader";
import { CircleSpinner } from 'react-spinners-kit';
import '../../../assets/css/dashboard/dashboard.css';
import { getInventory } from '../../../api/inventary';

class ListInventaryClass extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            submit: true,
            Inventory: [],
            price: null,
            item: null,

            modalNoData: false,
            modalSpareParts: false,

            type: 'incomes'
    
        };

        this.closeModal = this.closeModal.bind(this);

        this.goAddInventory = this.goAddInventory.bind(this);

        this.onChangeOption = this.onChangeOption.bind(this);

        this.seeSpareParts = this.seeSpareParts.bind(this);

        this.closeModalCompatibility = this.closeModalCompatibility.bind(this);

    }

    async fetchData() {
        const { type } = this.state;
        const result = await getInventory(type);
        if(result.response.status === 500) {
            alert('Error en el servidor');
            this.setState({ Inventory: [], loading: false})
        }
        if(result.response.status === 404) {
            this.setState({ Inventory: result.response.In_out_comes, loading: false, modalNoData: true})
        }
        else {
            let modalNoData = false;
            if(result.response.In_out_comes.length <= 0) modalNoData = true;
            this.setState({ Inventory: result.response.In_out_comes, loading: false, modalNoData})
        }        
    }
    
    componentDidMount() {
        this.fetchData();  
    }

    goAddInventory() {
        this.props.history.push('/admin/new-inventory');   
    }

    closeModal() {
        this.setState({
            modalNoData: false,
            submit: true,
            item: null
        })
    }

    async onChangeOption(event) {
        await this.setState({
            [event.target.name]: event.target.value
        });
        this.fetchData()
    }

    seeSpareParts(item) {
        this.setState({ modalSpareParts: true, item})
    }

    closeModalCompatibility() {
        this.setState({ modalSpareParts: false})
    }

    render() {
        const { Inventory } = this.state;
        return (
            <>
                <AdminHeader {...this.props}/>
                <Container className="mt--7" fluid> 
                    <div>
                        <Row>
                            <div className="col">
                                <Card className="shadow">
                                    <CardHeader className="border-0">
                                        <Row>
                                            <div className="col-6">
                                                <h3 className="mb-0 d-inline">Inventarios</h3>
                                            </div>
                                            <div className="col-6">
                                                <Button type="button" onClick={() => this.goAddInventory()} style={{ padding: '0.3rem 0.7rem' }} className="float-right btn btn-outline-success btn-circle">
                                                    <i className="fa fa-plus"></i>
                                                </Button>
                                            </div>
                                        </Row>
                                        <FormGroup row>
                                            <Label for="tipo" sm={3}>Tipo de inventario</Label>
                                            <Col sm={6}>
                                                <Input 
                                                    type="select" 
                                                    name="type" 
                                                    id="type"
                                                    onChange={this.onChangeOption}>
                                                        <option value='incomes'>Ingresos</option>
                                                        <option value='outcomes'>Salidas</option>
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                    </CardHeader>
                                    {
                                        this.state.loading ? 
                                        <div className='spinner-table'><CircleSpinner
                                                size={30}
                                                color="#11cdef"
                                                loading={this.state.loading}
                                            /></div>
                                        :
                                        <Table className="align-items-center table-flush" responsive>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th className='itemCenter' scope="col">Consecutivo</th>
                                                    <th className='itemCenter' scope="col">Responsable</th>
                                                    <th className='itemCenter' scope="col">Ítems</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    Inventory ? 
                                                    Inventory.map((item, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td className='itemCenter'>{item.consecutive ? item.consecutive: <p>-</p>}</td>
                                                                <td className='itemCenter'>{item.user ? item.user: <p>-</p>}</td>
                                                                <td className='itemCenter'>  
                                                                    <Button 
                                                                    className='btn btn-info btn-sm' 
                                                                    onClick={e => this.seeSpareParts(item)}>
                                                                        Ítems</Button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </Table>
                                    }
                                    </Card>
                                </div>
                            </Row>
                    </div>
                </Container>
                <div>
                {/* MODAL NO DATA */}
                <Modal isOpen={this.state.modalNoData} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>NO DATA</ModalHeader>
                    <ModalBody>
                        No hay {`${this.state.type === 'incomes' ? 'ingresos registrados' : 'salidas registradas' }`}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                    </ModalFooter>
                </Modal>
                </div>
                <div>
                {/* MODAL COMPATIBLES MODELS */}
                <Modal isOpen={this.state.modalSpareParts} toggle={this.closeModalCompatibility} className={this.props.className}  size="sm"> 
                    <ModalHeader toggle={this.closeModalCompatibility} className='ModalHeaderTitle'>
                        Ítems {this.state.type === 'incomes' ? 'de entrada' : 'de salida'}
                    </ModalHeader>
                    <ModalBody>
                        {
                        this.state.item ?
                        <Table className="align-items-center" responsive>
                            <tbody>
                                {
                                this.state.item.spare_parts.map((item, key) => {
                                    return (
                                        <tr key={key}>
                                            <td className='itemCenter'>{item.spare_part ? item.spare_part : <p>-</p>}</td>
                                            <td className='itemCenter'>{item.quantity ? item.quantity : <p>-</p>}</td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </Table>: <p></p>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.closeModalCompatibility}>Cerrar</Button>
                    </ModalFooter>
                </Modal>
                </div>
            </>
        );
    }
}

export default ListInventaryClass;