import React from "react";
import { Route, Switch, withRouter } from 'react-router-dom';
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from './../components/Shared/Navbar/AdminNavbar.jsx';
import AdminFooter from './../components/Shared/Footer/AdminFooter.jsx';
import Sidebar from './../components/Shared/Sidebar/Sidebar.jsx';
// import 
// import routes from '../routes/routes.js';
import auth from "./../api/auth.js";
import { newRoutes} from './../routes/routesSidebar.js'

class Admin extends React.Component {
  constructor () {
    super()
    this.state = { 
      newRoutes: [] 
    }
  }
  
  async componentDidMount() {
    document.title = "ICEE | Dashboard";
    
    // New Routes
    let idRole = auth.getUser().id_role ? auth.getUser().id_role : 1;
    let routesSidebar = await newRoutes(idRole)
    this.setState({newRoutes:routesSidebar  })
  }

  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        let ClassComponent = prop.component;
        let path = prop.layout + prop.path;
        return (
          <Route exact path={path} key={key} render={props => <ClassComponent {...props}/>} />
        );
      } else {
        return null;
      }
    });
  };

  get_brand = (routes, path) => {
    var brand_name;
    for(let route of routes) {  
      if(route.layout+route.path === path) {
        return brand_name=route.name.toUpperCase();
      }
      else {
        brand_name='DASHBOARD';
      }
    }
    return brand_name;
  } 

  render() {
    const { newRoutes } = this.state;
    return (
      <>
        <Sidebar
          {...this.props}
          routes={newRoutes}
          logo={{
            innerLink: "/admin/",
            imgSrc: require('./../assets/img/brand/logo.png'),
            imgAlt: 'ICEE Logo'
          }}
        />
        <div className="main-content" ref="mainContent">
          <AdminNavbar
            {...this.props}
            brandText={this.get_brand(newRoutes, this.props.location.pathname)}
            user={this.user}
          />
          <Switch>{this.getRoutes(newRoutes)}</Switch>
          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </> 
    );
  }
}

export default withRouter(Admin);