import { appFetch } from './fetch';
import host from './host';
import auth from './auth';

export const getModels  = async () => {
    const url = `${host}/models/get_all`;
    return await appFetch({ url, auth: auth.getToken(), method: 'GET' });
};

export const createModels = async data => {
    const url = `${host}/model/create`;
    return await appFetch({ url, auth: auth.getToken(), body: JSON.stringify(data), method: 'POST' })
}

export const updateModels = async (id, data) => {
    const url = `${host}/model/update/${id}`;
    return await appFetch({ url, auth: auth.getToken(), body: JSON.stringify(data), method: 'PUT' })
}