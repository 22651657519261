import { appFetch } from './fetch';
import host from './host';
import auth from './auth';

export const getServiceCenter = async () => {
    const url = `${host}/services_center/get_all`;
    return await appFetch({ url, auth: auth.getToken(), method: 'GET' });
};

export const createServiceCenter = async data => {
    const url = `${host}/service_center/create`;
    return await appFetch({ url, auth: auth.getToken(), body: JSON.stringify(data), method: 'POST' })
}

export const updateServiceCenter = async (id, data) => {
    const url = `${host}/service_center/update/${id}`;
    return await appFetch({ url, auth: auth.getToken(), body: JSON.stringify(data), method: 'PUT' })
}