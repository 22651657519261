import { appFetch } from './fetch';
import host from './host';
import auth from './auth';

export const getBatches  = async () => {
    const url = `${host}/batches/dash/get_all`;
    return await appFetch({ url, auth: auth.getToken(), method: 'GET' });
};

export const createBatch = async data => {
    const url = `${host}/batch/create`;
    return await appFetch({ url, auth: auth.getToken(), body: JSON.stringify(data), method: 'POST' })
}

export const updateBatch = async (id, data) => {
    const url = `${host}/batch/update/${id}`;
    return await appFetch({ url, auth: auth.getToken(), body: JSON.stringify(data), method: 'PUT' })
}

export const getRemision = async (id) => {
    const url = `${host}/reports/remision/${id}`;
    return await appFetch({ url, auth: auth.getToken(), method: 'GET'  })
}
