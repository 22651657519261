import React, { Component } from "react";
import { Multiselect } from 'react-widgets';
import 'react-widgets/dist/css/react-widgets.css';
  
class MultiSelect extends Component {
    constructor() {
        super();
        this.state = {
            Models: [],
            newModels: []
        };
        this.onChange = this.onChange.bind(this);
    }
    
    async componentDidMount() {
        this.setState({ Models: this.props.Models })
    }

    onChange(value) {
        this.props.parentCallback({ Models: value })
    }

    render() {
        let { Models } = this.state;
        return (
            <>
               <Multiselect
                    data={ Models }
                    valueField='id_model'
                    textField='model'
                    placeholder= 'Modelos compatibles'
                    messages = {{ 
                        emptyList: 'No hay más items',
                    }}
                    onChange={value => this.onChange(value)}
                    defaultValue={this.props.oldModels ? this.props.oldModels : []}
                />
            </>
        );
    }
}

export { MultiSelect }