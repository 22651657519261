import React, { Component } from 'react';
import { Card, CardHeader, Container, Row,  Button, Table,
    Modal, ModalBody, ModalHeader, ModalFooter
} from "reactstrap";
import AdminHeader from "../../Shared/Headers/AdminHeader";
import { CircleSpinner } from 'react-spinners-kit';
import '../../../assets/css/dashboard/dashboard.css';
import { getTransfers } from './../../../api/trasnfer';
import moment from 'moment'
import 'moment/locale/es';

// TOAST
// import toastr from 'toastr';
// import 'toastr/build/toastr.min.css';

// const toastOptions = {
//     positionClass: 'toast-top-center',
//     hideDuration: 300,
//     timeOut: 3000,
//     progressBar: true,
//     showEasing: 'swing',
//     hideEasing: 'linear',
//     showMethod: 'fadeIn',
//     hideMethod: 'fadeOut',
// }

class ListTransactionClass extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            submit: true,
            Transfers: [],
            price: null,
            item: null,

            modalNoData: false,
            modalSpareParts: false,
    
        };

        this.closeModal = this.closeModal.bind(this);

        this.goAddInventory = this.goAddInventory.bind(this);

        this.onChangeOption = this.onChangeOption.bind(this);

        this.seeSpareParts = this.seeSpareParts.bind(this);

        this.closeModalCompatibility = this.closeModalCompatibility.bind(this);

    }

    async fetchData() {
        const result = await getTransfers();
        if(result.response.status === 500) {
            alert('Error en el servidor');
            this.setState({ Transfers: [], loading: false})
        }
        if(result.response.status === 404) {
            this.setState({ Transfers: result.response.Transfers, loading: false, modalNoData: true})
        }
        else {
            let modalNoData = false;
            if(result.response.Transfers.length <= 0) modalNoData = true;
            this.setState({ Transfers: result.response.Transfers, loading: false, modalNoData})
        }        
    }
    
    componentDidMount() {
        this.fetchData();  
        moment.locale('es')
    }

    goAddInventory() {
        this.props.history.push('/admin/new-transfer');   
    }

    closeModal() {
        this.setState({
            modalNoData: false,
            submit: true,
            item: null
        })
    }

    async onChangeOption(event) {
        await this.setState({
            [event.target.name]: event.target.value
        });
        this.fetchData()
    }

    seeSpareParts(item) {
        this.setState({ modalSpareParts: true, item})
    }

    closeModalCompatibility() {
        this.setState({ modalSpareParts: false})
    }

    render() {
        const { Transfers } = this.state;
        return (
            <>
                <AdminHeader {...this.props}/>
                <Container className="mt--7" fluid> 
                    <div>
                        <Row>
                            <div className="col">
                                <Card className="shadow">
                                    <CardHeader className="border-0">
                                        <Row>
                                            <div className="col-6">
                                                <h3 className="mb-0 d-inline">Transferencias</h3>
                                            </div>
                                            <div className="col-6">
                                                <Button type="button" onClick={() => this.goAddInventory()} style={{ padding: '0.3rem 0.7rem' }} className="float-right btn btn-outline-success btn-circle">
                                                    <i className="fa fa-plus"></i>
                                                </Button>
                                            </div>
                                        </Row>
                                    </CardHeader>
                                    {
                                        this.state.loading ? 
                                        <div className='spinner-table'><CircleSpinner
                                                size={30}
                                                color="#11cdef"
                                                loading={this.state.loading}
                                            /></div>
                                        :
                                        <Table className="align-items-center table-flush" responsive>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th className='itemCenter' scope="col">Consecutivo</th>
                                                    <th className='itemCenter' scope="col">Origen</th>
                                                    <th className='itemCenter' scope="col">Destino</th>
                                                    <th className='itemCenter' scope="col">Ítems</th>
                                                    <th className='itemCenter' scope="col">Estado</th>
                                                    <th className='itemCenter' scope="col">Fecha</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    Transfers ? 
                                                    Transfers.map((item, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td className='itemCenter'>{item.consecutive ? item.consecutive: <p>-</p>}</td>
                                                                <td className='itemCenter'>{item.id_user_from ? item.user_from: item.warehouse_from}</td>
                                                                <td className='itemCenter'>{item.id_user_to ? item.user_to: item.warehouse_to}</td>
                                                                <td className='itemCenter'>  
                                                                    <Button 
                                                                    className='btn btn-info btn-sm' 
                                                                    onClick={e => this.seeSpareParts(item)}>
                                                                        Ítems</Button>
                                                                </td>
                                                                <td className='itemCenter'>{item.status ? item.status : <p>-</p>}</td>
                                                                <td className='itemCenter'>{item.created_at ? moment(item.created_at).format('MMMM DD YYYY, h:mm:ss a'): <p>-</p>}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </Table>
                                    }
                                    </Card>
                                </div>
                            </Row>
                    </div>
                </Container>
                <div>
                {/* MODAL NO DATA */}
                <Modal isOpen={this.state.modalNoData} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>NO DATA</ModalHeader>
                    <ModalBody>
                        No hay {`${this.state.type === 'incomes' ? 'ingresos registrados' : 'salidas registradas' }`}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                    </ModalFooter>
                </Modal>
                </div>
                <div>
                {/* MODAL COMPATIBLES MODELS */}
                <Modal isOpen={this.state.modalSpareParts} toggle={this.closeModalCompatibility} className={this.props.className}  size="sm"> 
                    <ModalHeader toggle={this.closeModalCompatibility} className='ModalHeaderTitle'>
                        Ítems transferidos
                    </ModalHeader>
                    <ModalBody>
                        {
                        this.state.item ?
                        <Table className="align-items-center" responsive>
                            <tbody>
                                {
                                this.state.item.spare_parts.map((item, key) => {
                                    return (
                                        <tr key={key}>
                                            <td className='itemCenter'>{item.spare_part ? item.spare_part : <p>-</p>}</td>
                                            <td className='itemCenter'>{item.quantity ? item.quantity : <p>-</p>}</td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </Table>: <p></p>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.closeModalCompatibility}>Cerrar</Button>
                    </ModalFooter>
                </Modal>
                </div>
            </>
        );
    }
}

export default ListTransactionClass;