import React, { Component } from 'react';

import AdminHeader from "../../Shared/Headers/AdminHeader";
import { Card, CardHeader, Container, Row,  Button, CardBody, Input, Table,
    Label, Form, FormGroup, Col,
    // DropdownMenu,DropdownItem, UncontrolledDropdown, DropdownToggle,
    Modal, ModalBody, ModalHeader, ModalFooter, 
} from "reactstrap";
import '../../../assets/css/dashboard/dashboard.css';
// import { Autocomplete } from './../../Shared/UI_Elements/Autocomplete';
import { createPricesList } from './../../../api/pricesList';
import { SearchSparePart } from './../../Shared/UI_Elements/SearchSparePart';

class CreatePriceList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            showTable: false,
            submit: false,

            irreparable: 0,
            value: 0,
            
            actualItem: '',
            actualPrice: 0,

            item: '',

            counter: 0,

            modalNoData: false,
            modalCreate: false,

            namePriceList: ''
            
        }
        this.handleChangeOption = this.handleChangeOption.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeForm = this.handleChangeForm.bind(this);
        this.handleName = this.handleName.bind(this);
        this.saveForm = this.saveForm.bind(this);
        this.successRequest = this.successRequest.bind(this);
        this.filterEmptySpareParts = this.filterEmptySpareParts.bind(this);
    }

    goBack() {
        this.props.history.goBack();
    }

    handleChange = idx => e => {
        const { name, value } = e.target;
        const items = [...this.state.items];
        
        let actualItem = this.state.actualItem;
        let actualPrice = this.state.actualPrice;

        if(name === 'spare_part') {
            actualItem = value;
            this.setState({ actualItem })
        }
        else {
            actualPrice = value;
            this.setState({ actualPrice })
        }

        items[idx] = {
            id_spare_part: this.state.actualId,
            spare_part: actualItem,
            price: parseFloat(actualPrice)
        };
        this.setState({ items });
    };

    handleAddRow = async () => {
        const item = {
            spare_part: "",
            price: 0
        };
        await this.delay(300);
        await this.setState({
            items: [...this.state.items, item]
        });
    };

    delay = ms => new Promise(res => setTimeout(res, ms));

    handleRemoveRow = () => {
        this.setState({
            items: this.state.items.slice(0, -1)
        });
    };

    handleChangeForm(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleName(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleRemoveSpecificRow = (idx) => () => {
        const items = [...this.state.items]
        items.splice(idx, 1)
        this.setState({ items })
    }

    handleChangeOption = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value === "demand" ? this.state.showTable = true : this.state.showTable = false });
    }

    saveForm(e) {
        e.preventDefault();
        this.setState({submit: true})
        let { items , namePriceList, irreparable ,value, showTable } = this.state;
        let id_pricing_plan = showTable ? 2 : 1;
        
        // Se eleimanan los items emptys
        let newItems = items.filter(this.filterEmptySpareParts);
        
        let data = {
            name: namePriceList,
            id_pricing_plan,
            workforce: value,
            spare_parts: newItems,
            irreparable
        }
        if(data.id_pricing_plan === 2 && data.spare_parts.length===0 ) {
            alert('No se puede crear lista de precios bajo demanda, no hay repuestos seleccionados')
            this.setState({ submit: false })
            return
        }
        createPricesList(data)
        .then( res => {
            this.setState({ submit: false})
            if(res.response.status === 200) {
                this.setState({ modalCreate: true, counter: 0 })
                setTimeout(() => { this.props.history.goBack(); }, 2000);
            }
            else {
                alert(res.response.message)
            }
        })
        .catch( err => {
            alert(err)
        })
    }

    callbackFunction = async (childData) => {
        let idx = childData.idx;
        let value = childData.choosenValue;
        const items = [...this.state.items];
        await this.setState({
            actualItem: value.spare_part,
            actualPrice: value.price
        })

        if(!value.spare_part) return;

        items[idx] = {
            id_spare_part: value.id_spare_part,
            spare_part: value.spare_part,
            price: value.price
        };
        
        await this.setState({
            items,
            actualItem: value.spare_part,
            actualPrice: value.price,
            actualId: value.id_spare_part
        })
    }

    successRequest() {
        this.props.history.goBack();
    }

    filterEmptySpareParts(obj) {
        let { counter } = this.state; 
        if ('id_spare_part' in obj) {
            return true;
        } else {
            counter++;
            return false;
        }
    }

    render() {
        const { showTable } = this.state;
        return (
            <>
            <AdminHeader {...this.props}/>
            <Container className="mt--7" fluid> 
                <div>
                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <Form onSubmit={this.saveForm}
                                    onKeyPress={event => {
                                    if (event.which === 13 || event.key === 'Enter') {
                                        event.preventDefault();
                                        }
                                    }}>
                                <CardHeader className="border-0">
                                    <Row>
                                        <div className="col-6">
                                            <Button type="button" 
                                                onClick={() => this.goBack()} 
                                                style={{ padding: '0.3rem 0.8rem ', marginRight:'1rem' }} 
                                                className="float-left btn btn-outline-info btn-circle">
                                                <i className="fas fa-arrow-left"></i>
                                            </Button>
                                            <h3 className="mb-0 d-inline">Crear Lista de precios</h3>
                                        </div>
                                    </Row>
                                </CardHeader>

                                <CardBody style={{marginLeft: '2rem'}} >
                                <div>
                                    <Row>
                                        <Label for="priceList">Nombre Lista de precio</Label>
                                        <Col>
                                        <Input 
                                            type="text"
                                            name="namePriceList"
                                            id="namePriceList" 
                                            placeholder="Ingresa el nombre Lista de precio"
                                            onChange={this.handleChangeForm}
                                            value={this.state.namePriceList}
                                            required />
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <div className="col-6">
                                            <Label>
                                                <Input
                                                    type="radio"
                                                    name="integral"
                                                    value="integral"
                                                    checked={showTable ? false : true}
                                                    onChange={this.handleChangeOption}
                                                />{" "}
                                                Integral
                                            </Label>
                                            <br />
                                            <Label>
                                                <Input
                                                    type="radio"
                                                    name="demand"
                                                    value="demand"
                                                    checked={showTable ? true : false}
                                                    onChange={this.handleChangeOption}
                                                />{" "}
                                                Bajo Demanda
                                            </Label>
                                        </div>
                                        <div className="col-6">
                                            {/* <Button type="submit" disabled={this.state.submit} //onClick={() => this.addItem()} 
                                                    style={{ padding: '0.3rem 0.7rem' }} 
                                                    className="float-right btn btn-success btn-circle"
                                                    size="lg">
                                                GUARDAR
                                            </Button> */}
                                        </div>
                                    </Row>
                                </div>
                                </CardBody>
                                <CardBody>
                                
                                    <Row>
                                    <Col md={6}>
                                    <FormGroup row>
                                        <Label for="value" sm={4}>{showTable ? 'Mano de obra' :'Reparación'} </Label>
                                        <Col sm={8}>
                                            <Input
                                                type="number" 
                                                name="value" 
                                                id="value" 
                                                placeholder={showTable ? 'Mano de obra' :'Reparación' }
                                                min='0'
                                                max='100000000'
                                                step='1000'
                                                onChange={this.handleChangeForm}
                                                value={this.state.value}
                                                required />
                                        </Col>
                                    </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                    <FormGroup row>
                                        <Label for="irreparable" sm={4}>Irreparable</Label>
                                        <Col sm={8}>
                                            <Input
                                                type="number" 
                                                name="irreparable" 
                                                id="irreparable" 
                                                placeholder="Ingrese el valor"
                                                min='0'
                                                max='10000000'
                                                step='1000'
                                                onChange={this.handleChangeForm}
                                                value={this.state.irreparable}
                                                required />
                                        </Col>
                                    </FormGroup>
                                    </Col>
                                    </Row>
                                </CardBody>

                                { showTable === false ? null: 

                                <CardBody>
                                    <div>
                                        <div className="container">
                                            <div className="row clearfix">
                                                <div className="col-md-12 column">
                                                    <Table
                                                        className="align-items-center table-bordered table-hover" 
                                                        id="recTable"
                                                        responsive >
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th className="text-center">N°</th>
                                                                <th className="text-center">Repuesto</th>
                                                                <th className="text-center">Valor</th>
                                                                <th />
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.items.map((item, idx) => (
                                                                <tr id="addr0" key={idx}>
                                                                    <td className='cellTableCreate'>{idx}</td>
                                                                    <td className='cellTableCreate'>
                                                                        {/* <Autocomplete 
                                                                            parentCallback = {this.callbackFunction}
                                                                            inputProps={ this.state.items, idx }
                                                                            
                                                                        ></Autocomplete> */}
                                                                        <SearchSparePart
                                                                            parentCallback={this.callbackFunction}
                                                                            inputProps={ this.state.items, idx }
                                                                        />       
                                                                    </td>
                                                                    <td className='cellTableCreate'>
                                                                        <Input
                                                                            type="number" 
                                                                            id="price"
                                                                            name="price"
                                                                            placeholder="Ingrese el valor"
                                                                            min='0'
                                                                            max='10000000'
                                                                            step='1000'
                                                                            value={this.state.items[idx].price }
                                                                            onChange={this.handleChange(idx)}
                                                                            className="form-control"
                                                                        />
                                                                    </td>
                                                                    <td className='cellTableCreate itemCenter'>
                                                                        <Button
                                                                            className="btn btn-outline-danger btn-sm"
                                                                            onClick={this.handleRemoveSpecificRow(idx)}>
                                                                            X
                                                                        </Button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                    <div style={{marginTop: '1rem'}}>
                                                    <Button onClick={this.handleAddRow} className="btn btn-primary">
                                                        Agregar Ítem
                                                    </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </CardBody>
                                    }
                                    <Button
                                        type="submit" disabled={this.state.submit}
                                        style= {{margin: '0 2% 2% 0%'}}
                                        className="float-right btn btn-success btn-circle"
                                        size="lg">
                                        GUARDAR
                                    </Button>
                            </Form>
                                </Card>
                            </div>
                        </Row>
                    </div>
                </Container>

                <div>
                {/* MODAL NO DATA */}
                <Modal isOpen={this.state.modalNoData} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>NO DATA</ModalHeader>
                    <ModalBody>
                        No hay Respuestos registrados
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                    </ModalFooter>
                </Modal>
                </div>

                <div>
                {/* MODAL SUCESS CREATE */}
                <Modal isOpen={this.state.modalCreate} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>Éxito</ModalHeader>
                    <ModalBody>
                        Lista de precios creada correctamente
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={this.successRequest}>Cerrar</Button>
                    </ModalFooter>
                </Modal>
                </div>
            </>
        );
    }
}


export default CreatePriceList;