import React, { Component } from 'react';
import { Card, CardHeader, Container, Row,  Button, Table,
    DropdownMenu,DropdownItem, UncontrolledDropdown, DropdownToggle,
    Modal, ModalBody, ModalHeader, ModalFooter,Form, Label, ListGroup, ListGroupItem,
    FormGroup, Input, Col } from "reactstrap";
import AdminHeader from "../../Shared/Headers/AdminHeader";
import { MultiSelect } from './../../Shared/UI_Elements/Multiselect';
import { CircleSpinner } from 'react-spinners-kit';
import '../../../assets/css/dashboard/dashboard.css';
import { getSpareParts, createSparePart, updateSparePart } from './../../../api/spareParts';
import { getModels } from './../../../api/models';
import { defaultPriceLst } from './../../../api/pricesList';
import CurrencyFormat from 'react-currency-format';
// TOAST
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

const toastOptions = {
    positionClass: 'toast-top-center',
    hideDuration: 300,
    timeOut: 3000,
    progressBar: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
}

class SpareParts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            submit: false,
            Spare_parts: [],
            item: null,

            modalNoData: false,
            modalNew: false,
            modalEdit:false,
            modalDelete: false,
            modalCompatible: false,
            modalModels: false,

            hide: true,

            id: '',
            spare_part: '',
            model: '',
            price: '',
            id_model: 'bb136760-5dbb-11ea-a49c-9398bb9fd418',
            id_status: 1,

            Models: [],

            newModels: [],
            oldModels: [],

            id_price_list: ''
        };

        this.closeModal = this.closeModal.bind(this);
        this.closeModalCompatibility = this.closeModalCompatibility.bind(this);

        this.addItem = this.addItem.bind(this);
        this.handleAddItem = this.handleAddItem.bind(this);
        this.submitAddItem = this.submitAddItem.bind(this);
        this.handleUpdateItem = this.handleUpdateItem.bind(this);

        this.seeModels = this.seeModels.bind(this);

        this.submitUpdateItem = this.submitUpdateItem.bind(this);

        this.editItem = this.editItem.bind(this);

        this.deleteItem = this.deleteItem.bind(this);
        this.submitDeleteItem = this.submitDeleteItem.bind(this);
        this.deleteItemData = this.deleteItemData.bind(this);
        this.callbackFunction = this.callbackFunction.bind(this);

        this.goModels = this.goModels.bind(this);
    }   

    async fetchData() {
        const requestModels= await getModels();
        if(requestModels.response.status === 200) {
            if(requestModels.response.Models.length === 0) {
                this.setState({modalModels: true })
                return;
            }
            this.setState({ 
                Models: requestModels.response.Models, 
                id_model: requestModels.response.Models[0].id_model})
        }
        const defaultPrice = await defaultPriceLst();
        if(defaultPrice.response.status = 200) {
            if(!defaultPrice.response.price_list) {
                this.setState({ id_price_list: ''})
            }
            else {
                let id_price_list = defaultPrice.response.price_list.id_price_list 
                ? defaultPrice.response.price_list.id_price_list : '';
                this.setState({ id_price_list })
            }
        }
        const result = await getSpareParts();
        if(result.response.status === 500) {
            alert('Error en el servidor');
            this.setState({ loading: false})
        }
        if(result.response.status === 404) {
            this.setState({ SpareParts: result.response.Spare_parts, loading: false, modalNoData: true})
        }
        else {
            let modalNoData = false;
            if(result.response.Spare_parts.length <= 0) modalNoData = true;
            this.setState({ SpareParts: result.response.Spare_parts, 
                loading: false, modalNoData
            })
        }        
    }
    
    componentDidMount() {
        this.fetchData();  
    }

    addItem() {
        this.setState({modalNew: true})
    }

    handleAddItem(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    seeModels(item) {
        this.setState({ modalCompatible: true, item:item})
    }

    async submitAddItem(e) {
        e.preventDefault();
        let spare_part = await this.capitalizeFirstLetter(this.state.spare_part)
        let data = {
            spare_part,
            models: this.state.newModels,
            price: this.state.price,
            id_price_list: this.state.id_price_list,
        }
        if(data.models.length <= 0) {
            alert('Agrega modelos compatibles')
            return;
        }
        this.setState({ submit: true})
        createSparePart(data)
            .then(res => {
                if (res.response.status !== 200) {
                    alert(res.response.message);
                }
                else {
                    this.NewItem(res.response.spare_part);
                    this.toastCreateItem();
                    this.closeModal();
                }
            })
            .catch(err => {
                alert(err)
            });
    }

    toastCreateItem() {
        toastr.options = toastOptions;
        toastr.clear()
        setTimeout(() => toastr.success(`Repuesto creado`), 300)
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    async NewItem(sparePart) {
        if(!this.state.SpareParts) this.state.SpareParts = [];
        let newItem = this.appendObjTo(this.state.SpareParts, sparePart);
        this.setState({ SpareParts: newItem })
    }

    closeModal() {
        this.setState({
            modalNoData: false,
            modalNew: false,
            modalEdit:false,
            modalDelete: false,
            submit: false,
            modalModels: false,
            
            item: null,
            id: '',
            spare_part: '',
            model: '',
            price: '',
            id_model: 'bb136760-5dbb-11ea-a49c-9398bb9fd418',
            id_status: 1,

            sparePart: null,
            hide: true
        })
    }
    
    closeModalCompatibility() {
        this.setState({ modalCompatible: false})
    }

    async editItem(item) {
        this.setState({ id: item.id_spare_part, 
            spare_part: item.spare_part,
            price: item.price,
            id_model: item.id_model, 
            oldModels: item.models,
            item, modalEdit: true })
    }

    handleUpdateItem(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    submitUpdateItem(e) {
        e.preventDefault();
        this.setState({ submit: true})
        const { id, spare_part, id_model, model, id_price_list, newModels } = this.state;
        let data = { spare_part, id_model, model, id_price_list, models: newModels}
        updateSparePart(id, data)
            .then( res => {
                if(res.response.status !== 200) {
                    alert(res.response.message);
                    this.setState({ submit: false})
                }
                else {
                    this.toastUpdateItem()
                    this.updateItemData(res.response.spare_part);
                    this.closeModal();
                }
            })
            .catch( err =>{
                alert(err)
            });
    }

    toastUpdateItem() {
        toastr.options = toastOptions;
        toastr.clear()
        setTimeout(() => toastr.success(`repuesto actualizado`), 300)
    }

    async updateItemData(data) {
        try {
            var id = data.id_spare_part;
            let { SpareParts } = this.state; 
            let index = SpareParts.findIndex(item => item.id_spare_part === id);
            if(index !== -1){
                SpareParts[index].spare_part = data.spare_part;
                SpareParts[index].model = data.model;
                SpareParts[index].id_model = data.id_model;
                SpareParts[index].price = data.price;
                SpareParts[index].models = data.models;
                this.setState({ SpareParts })
            }
            else this.setState({ SpareParts })
        } catch (error) {
            window.location.reload();
        }
    }

    deleteItem(item) {
        this.setState({ modalDelete: true, id:item.id_spare_part, item})
    }

    submitDeleteItem(e) {
        e.preventDefault();
        this.setState({ submit: true})
        let { id, item, id_price_list } = this.state;
        let data = { id_status: 2, id_price_list }
        updateSparePart(id,data)
        .then( res => {
            if(res.response.status !== 200) alert(res.response.message);
            else {
                this.deleteItemData(item);
                this.toastDeleteUser(item.spare_part)
                this.closeModal();
            }
        })
        .catch( err =>{
            alert(err)
        });
    }

    async deleteItemData(data) {
        try {
            var id = data.id_spare_part;
            let { SpareParts } = this.state;
            let index = SpareParts.findIndex(item => item.id_spare_part === id);
            if(index !== -1) SpareParts.splice(index, 1);
            else this.setState({ SpareParts })
        } catch (error) {
            window.location.reload();
        }
    }

    appendObjTo = (thatArray, newObj) => {
        const frozenObj = Object.freeze(newObj);
        return Object.freeze(thatArray.concat(frozenObj));
    }

    toastDeleteUser(name) {
        toastr.options = toastOptions;
        toastr.clear()
        setTimeout(() => toastr.success(`repuesto ${name ? name: ''} eliminado`), 300)
    }

    callbackFunction = (childData) => {
        this.setState({ newModels: childData.Models})
    }

    goModels() {
        this.props.history.push('/admin/models');  
    }

    render() {
        const { SpareParts, Models, oldModels } = this.state;
        return (
            <>
                <AdminHeader {...this.props}/>
                <Container className="mt--7" fluid> 
                    <div>
                        <Row>
                            <div className="col">
                                <Card className="shadow">
                                    <CardHeader className="border-0">
                                        <Row>
                                            <div className="col-6">
                                                <h3 className="mb-0 d-inline">Repuestos</h3>
                                            </div>
                                            <div className="col-6">
                                                <Button type="button" onClick={() => this.addItem()} style={{ padding: '0.3rem 0.7rem' }} className="float-right btn btn-outline-success btn-circle">
                                                    <i className="fa fa-plus"></i>
                                                </Button>
                                            </div>
                                        </Row>
                                    </CardHeader>
                                    {
                                        this.state.loading ? 
                                        <div className='spinner-table'><CircleSpinner
                                                size={30}
                                                color="#11cdef"
                                                loading={this.state.loading}
                                            /></div>
                                        :
                                        <Table className="align-items-center table-flush" responsive>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th className='itemCenter' scope="col">Repuesto</th>
                                                    <th className='itemCenter' scope="col">Modelos</th>
                                                    <th className='itemCenter' scope="col">Precio</th>
                                                    {/* <th className='itemCenter' scope="col">Estado</th> */}
                                                    <th className='itemCenter' scope="col" />
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    SpareParts ? 
                                                    SpareParts.map((item, key) => {
                                                        return (
                                                            <tr key={key} style={{zIndex: 0}}>
                                                                <td className='itemCenter'>{item.spare_part ? item.spare_part: <p>-</p>}</td>
                                                                <td className='itemCenter'>  
                                                                    <Button className='btn btn-info btn-sm' onClick={e => this.seeModels(item)}>Modelos</Button>
                                                                </td>
                                                                <td className='itemCenter'>{item.price  ?  <CurrencyFormat value={item.price} displayType={'text'} decimalScale={2} thousandSeparator={true} suffix={' COP'} /> : <p>-</p>}</td>
                                                                {/* <td className='itemCenter'>{item.status ? item.status: <p>-</p>}</td> */}
                                                                <td className="text-right">
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle
                                                                            className="btn-icon-only text-light"
                                                                            href="#"
                                                                            role="button"
                                                                            size="sm"
                                                                            color=""
                                                                            onClick={e => e.preventDefault()}>
                                                                            <i className="fas fa-ellipsis-v" />
                                                                        </DropdownToggle>
                                                                        <DropdownMenu className="dropdown-menu-arrow" right>
                                                                            <DropdownItem
                                                                                onClick={() => this.editItem(item)}
                                                                            >
                                                                                <i className="fas fa-edit" />
                                                                                Editar
                                                                            </DropdownItem>
                                                                            <DropdownItem
                                                                                onClick={() => this.deleteItem(item)}
                                                                            >
                                                                                <i className="fas fa-trash" />
                                                                                Eliminar
                                                                            </DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </Table>
                                    }
                                    </Card>
                                </div>
                            </Row>
                    </div>
                </Container>
                <div>
                {/* MODAL NO DATA */}
                <Modal isOpen={this.state.modalNoData} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>NO DATA</ModalHeader>
                    <ModalBody>
                        No hay Respuestos registrados
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                    </ModalFooter>
                </Modal>
                </div>
                <div>
                {/* MODAL NEW */}
                <Modal isOpen={this.state.modalNew} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>Agregar Nuevo Repuesto</ModalHeader>
                    <Form onSubmit={this.submitAddItem}>
                    <ModalBody>
                        <FormGroup row>
                            <Label for="name" sm={4}>Nombre repuesto</Label>
                            <Col sm={8}>
                            <Input 
                                type="text"
                                name="spare_part"
                                id="spare_part" 
                                placeholder="Ingresa el nombre del repuesto"
                                onChange={this.handleAddItem}
                                value={this.state.spare_part}
                                required />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="description" sm={4}>Modelos Compatibles</Label>
                            <Col sm={8}>
                                <MultiSelect 
                                    Models={Models} 
                                    parentCallback = {this.callbackFunction}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="price" sm={4}>Precio repuesto</Label>
                            <Col sm={8}>
                            <Input 
                                type="number" 
                                name="price" 
                                id="price" 
                                placeholder="Precio del repuesto"
                                min='0'
                                max='1000000'
                                step='1000'
                                value={this.state.price}
                                onChange={this.handleAddItem} />
                            </Col>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                        <Button color="success" type="submit" disabled={this.state.submit}>Crear</Button>
                    </ModalFooter>
                    </Form>
                </Modal>
                </div>
                <div>
               
                {/* MODAL UPDATE SPARE PART */}
                <Modal isOpen={this.state.modalEdit} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>Editar repuesto {this.state.item ? this.state.item.spare_part : ''}</ModalHeader>
                    <Form onSubmit={this.submitUpdateItem}>
                    <ModalBody>
                        <FormGroup row>
                            <Label for="spare_part" sm={4}>Nombre repuesto</Label>
                            <Col sm={8}>
                            <Input 
                                type="text"
                                name="spare_part"
                                id="spare_part" 
                                placeholder="Ingresa el nombre del repuesto"
                                onChange={this.handleUpdateItem}
                                value={this.state.spare_part}
                                required />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="description" sm={4}>Modelos Compatibles</Label>
                            <Col sm={8}>
                                <MultiSelect 
                                    Models={Models} 
                                    parentCallback = {this.callbackFunction}
                                    oldModels = { oldModels }
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="price" sm={4}>Precio repuesto</Label>
                            <Col sm={8}>
                            <Input 
                                type="number" 
                                name="price" 
                                id="price" 
                                placeholder="Precio del repuesto"
                                min='0'
                                max='1000000'
                                step='1000'
                                value={this.state.price}
                                onChange={this.handleUpdateItem} />
                            </Col>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                        <Button color="success" type="submit" disabled={this.state.submit}>Actualizar</Button>
                    </ModalFooter>
                    </Form>
                </Modal>
                </div>
                <div>
                {/* MODAL DELETE */}
                <Modal isOpen={this.state.modalDelete} toggle={this.closeModal} className={this.props.className}>
                    <ModalHeader toggle={this.closeModal} className='ModalHeaderTitle'>
                        Eliminar el repuesto {this.state.item ? this.state.item.spare_part : ''}
                    </ModalHeader>
                    <ModalBody>
                        ¿Deseas eliminar este repuesto?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}>Cerrar</Button>
                        <Button color="danger" onClick={this.submitDeleteItem}>Eliminar</Button>
                    </ModalFooter>
                </Modal>
                </div>

                <div>
                {/* MODAL COMPATIBLES MODELS */}
                <Modal isOpen={this.state.modalCompatible} toggle={this.closeModalCompatibility} className={this.props.className}  size="sm"> 
                    <ModalHeader toggle={this.closeModalCompatibility} className='ModalHeaderTitle'>
                        Modelos compatibles {this.state.item ? this.state.item.spare_part : ''}
                    </ModalHeader>
                    <ModalBody>
                        <ListGroup>
                            { 
                                !this.state.item ? ''
                                : 
                                this.state.item.models.map((item, index) => (
                                    <ListGroupItem key= {index}>{item.model}</ListGroupItem>
                                ))
                            }
                        </ListGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.closeModalCompatibility}>Cerrar</Button>
                    </ModalFooter>
                </Modal>
                </div>

                <div>
                {/* MODAL NO MODELS */}
                <Modal isOpen={this.state.modalModels} toggle={this.goModels} className={this.props.className}>
                    <ModalHeader toggle={this.goModels} className='ModalHeaderTitle'>
                       No hay modelos creados
                    </ModalHeader>
                    <ModalBody>
                        Ir a crear modelo
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.goModels}>Crear</Button>
                    </ModalFooter>
                </Modal>
                </div>
            </>
        );
    }
}

export default SpareParts;