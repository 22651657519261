import React, { Component } from "react";
import Chart from "chart.js";
import { Line, Bar, Pie } from "react-chartjs-2";
import axios from 'axios';
import auth from './../../../api/auth'
// reactstrap components
import {  Card, CardHeader, CardBody, CardTitle, NavItem,
  NavLink, Nav, Container, Row, Col, Button } from "reactstrap";

// core components
import { 
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2
} from '../../../constants/chart.jsx';

import AdminHeader from "../../Shared/Headers/AdminHeader";
import { getCardsInfo, downloadReport } from './../../../api/index'

class Index extends Component {

  constructor(props) { 
    super(props)
    this.state = {
      repaired_devices: 0,
      total_batches: 0,
      total_devices: 0,
      total_clients: 0,

      dataChart: {},

      activeNav: 1,
      chartExample1Data: "data1"
    }
  }

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };
  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  async fetchData() {
    const result = await getCardsInfo();
    if(result.response.status === 200) {
      let dataChart = await this.convertDataChart(result.response.diagnosed_devices)
      this.setState({ 
        repaired_devices: result.response.repaired_devices,
        total_batches: result.response.total_batches,
        total_devices: result.response.total_devices,
        total_clients: result.response.total_clients,
        dataChart:dataChart
      })
    }
  }

  componentDidMount() {
    this.fetchData();  
  }

  async downloadReport(e) {
    await downloadReport()
    let authToken = auth.getToken()
    axios({
      url: `${process.env.REACT_APP_API_URL}/reports/report`, //your url
      headers: {'Access-Control-Allow-Origin': '*',  'dash-authorization': authToken},
      method: 'GET',
      responseType: 'blob', // important
    }).then(response => {
      if (response.status === 204 || response.status === 401) {
        auth.removeUser();
        auth.removeToken();
        window.location.reload();
        alert('NO AUTORIZADO')
        return response
    }
       const url = window.URL.createObjectURL(new Blob([response.data]));
       const link = document.createElement('a');
       link.href = url;
       link.setAttribute('download', 'Informe.csv'); //or any other extension
       document.body.appendChild(link);
       link.click();
    })
    .catch(error => {
      alert(error.message ? error.message : 'Error en el servidor')
    })
  }

  convertDataChart(diagnosed_devices) {
    let labels = []
    let dataChart = []

    for(let item of diagnosed_devices) {
      labels.push(item.status)
      dataChart.push(item.count)
    }

    let data = {
      labels: labels,
      datasets: [
        {
          label: "Diagnosticos",
          data: dataChart
        }
      ]
    }
    return data
  }

  render() {
    const { repaired_devices, total_batches, total_devices, total_clients, dataChart } = this.state;
    return (
      <>
        <AdminHeader {...this.props} />
        <Container className="mt--7" fluid>
          <div className="header-body">
              {/* Card stats */}
              <Row>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Dispositivos Reparados
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            { repaired_devices }
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="fas fa-chart-bar" />
                          </div>
                        </Col>
                      </Row>
                      {/* <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          <i className="fa fa-arrow-up" /> 3.48%
                        </span>{" "}
                        <span className="text-nowrap">Desde el ultimo mes</span>
                      </p> */}
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Cantidad de lotes
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            { total_batches }
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="fas fa-chart-pie" />
                          </div>
                        </Col>
                      </Row>
                      {/* <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-danger mr-2">
                          <i className="fas fa-arrow-down" /> 3.48%
                        </span>{" "}
                        <span className="text-nowrap">Desde la ultima semana</span>
                      </p> */}
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Total Dispositivos
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {  total_devices}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                            <i className="fas fa-percent" />
                          </div>
                        </Col>
                      </Row>
                      {/* <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          <i className="fas fa-arrow-up" /> 12%
                        </span>{" "}
                        <span className="text-nowrap">Desde el ultimo año</span>
                      </p> */}
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Clientes Totales
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            { total_clients }
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                            <i className="fas fa-users" />
                          </div>
                        </Col>
                      </Row>
                      {/* <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-warning mr-2">
                          <i className="fas fa-arrow-down" /> 1.10%
                        </span>{" "}
                        <span className="text-nowrap">Since yesterday</span>
                      </p> */}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
            <br/>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="4">
              <Card className="bg-gradient-default shadow">
                <Button className='btn btn-success' onClick={e =>this.downloadReport()}>Descargar Informe</Button>
              </Card>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="7">
              <Card className="bg-gradient-default shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-light ls-1 mb-1">
                        Reporte de Reparaciones
                      </h6>
                    </div>
                    <div className="col">
                      {/* <Nav className="justify-content-end" pills>
                        <NavItem>
                          <NavLink
                            className={classnames("py-2 px-3", {
                              active: this.state.activeNav === 1
                            })}
                            href="#pablo"
                            onClick={e => this.toggleNavs(e, 1)}
                          >
                            <span className="d-none d-md-block">Mes</span>
                            <span className="d-md-none">M</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames("py-2 px-3", {
                              active: this.state.activeNav === 2
                            })}
                            data-toggle="tab"
                            href="#pablo"
                            onClick={e => this.toggleNavs(e, 2)}
                          >
                            <span className="d-none d-md-block">Semana</span>
                            <span className="d-md-none">S</span>
                          </NavLink>
                        </NavItem>
                      </Nav> */}
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  {/* Chart */}
                  <div className="chart">
                    {/* <Line
                      data={chartExample1[this.state.chartExample1Data]}
                      options={chartExample1.options}
                      getDatasetAtEvent={e => console.log(e)}
                    /> */}
                    <Line
                      data={dataChart}
                      options={chartExample2.options}
                    ></Line>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="5">
              <Card className="shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-muted ls-1 mb-1">
                        Indicadores
                      </h6>
                      <h2 className="mb-0">Terminales diagnosticadas</h2>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  {/* Chart */}
                  <div className="chart">
                    <Bar
                      data={dataChart}
                      options={chartExample2.options}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Index;
