import Index from '../components/Dashboard/index/Index';
import Users from './../components/Dashboard/users/Users';
import Causes from './../components/Dashboard/causes/Causes.jsx';
import Failures from './../components/Dashboard/failures/Failures.jsx';
import Fixes from './../components/Dashboard/fixes/Fixes.jsx';
import Models from './../components/Dashboard/models/Models.jsx';
import SpareParts from '../components/Dashboard/spareParts/SpareParts.jsx';
import PricesList from './../components/Dashboard/pricesLists/PricesList.jsx';
import CreatePriceList from '../components/Dashboard/pricesLists/CreatePriceList.jsx';
import Clients from './../components/Dashboard/clients/Clients.jsx';
import Warehouses from './../components/Dashboard/warehouses/Warehouses';
import ServiceCenters from './../components/Dashboard/serviceCenters/ServiceCenters';
import Batches from './../components/Dashboard/batches/Batches';
import Transactions from './../components/Dashboard/transactions/Transactions';
import Inventory from './../components/Dashboard/inventary/Inventary';
import ListInventary from './../components/Dashboard/inventary/ListInventary';
import Devices from '../components/Dashboard/devices/Devices';
import ListTransactionsClass from '../components/Dashboard/transactions/ListTransactions';
import ReportsClass from '../components/Dashboard/reports/Reports';

var routes;

export const newRoutes = async (id_role) => {
    id_role = parseInt(id_role);
    if(!id_role) id_role = 1;
    if(id_role >= 4 || id_role < 1 )id_role = 2;

    if(id_role === 1) {
      routes = [
        {
          path: "/",
          name: "Dashboard",
          icon: "fas fa-home text-blue",
          component: Index,
          layout: "/admin",
          sidebar: true
        },
        {
          path: "/users",
          name: "Usuarios",
          icon: "ni ni-circle-08 text-blue",
          component: Users,
          layout: "/admin",
          sidebar: true
        },
        {
          path: "/models",
          name: "Modelos",
          icon: "fas fa-tablet-alt text-primary",
          component: Models,
          layout: "/admin",
          sidebar: true
        },
        {
          path: "/spare_parts",
          name: "Repuestos",
          icon: "fas fa-boxes text-blue",
          component: SpareParts,
          layout: "/admin",
          sidebar: true
        },
        {
          path: "/prices_lists",
          name: "Lista de Precios",
          icon: "fas fa-list-ul text-blue",
          component: PricesList,
          layout: "/admin",
          sidebar: true
        },
        {
          path: "/clients",
          name: "Clientes",
          icon: "fas fa-users text-blue",
          component: Clients,
          layout: "/admin",
          sidebar: true
        },
        {
          path: "/services_centers",
          name: "Centros de Servicio",
          icon: "fas fa-store text-blue",
          component: ServiceCenters,
          layout: "/admin",
          sidebar: true
        },
        {
          path: "/warehouse",
          name: "Bodegas",
          icon: "fas fa-warehouse text-blue",
          component: Warehouses,
          layout: "/admin",
          sidebar: true
        },
        {
          path: "/batches",
          name: "Lotes",
          icon: "fas fa-stream text-blue",
          component: Batches,
          layout: "/admin",
          sidebar: true
        },
        {
          path: "/devices",
          name: "Dispositivos",
          icon: "fas fa-columns text-blue",
          component: Devices,
          layout: "/admin",
          sidebar: true
        },
        {
          path: "/transfers",
          name: "Transferencias",
          icon: "fas fa-exchange-alt text-blue",
          component: ListTransactionsClass,
          layout: "/admin",
          sidebar: true
        },
        {
          path: "/new-transfer",
          name: "Nueva Transferencias",
          icon: "fas fa-exchange-alt text-blue",
          component: Transactions,
          layout: "/admin",
          sidebar: false
        },
        {
          path: "/inventory",
          name: "Inventario",
          icon: "fas fa-dolly-flatbed text-blue",
          component: ListInventary,
          layout: "/admin",
          sidebar: true
        },
        {
          path: "/new-inventory",
          name: "Inventario",
          icon: "fas fa-dolly-flatbed text-blue",
          component: Inventory,
          layout: "/admin",
          sidebar: false
        },
        {
          path: "/reports",
          name: "Reportes",
          icon: "fas fa-chart-pie text-blue",
          component: ReportsClass,
          layout: "/admin",
          sidebar: true
        },
        {
          path: "/failures",
          name: "Fallas",
          icon: "fas fa-tag text-primary",
          component: Failures,
          layout: "/admin",
          sidebar: true
        },
        {
          path: "/causes",
          name: "Causas",
          icon: "fas fa-tag text-primary",
          component: Causes,
          layout: "/admin",
          sidebar: true
        },
        {
          path: "/fixes",
          name: "Soluciones",
          icon: "fas fa-tag text-primary",
          component: Fixes,
          layout: "/admin",
          sidebar: true
        },
        {
          path: "/create_price_list",
          name: "Crear Lista de precios",
          icon: "fas fa-cog text-blue hidden",
          component: CreatePriceList,
          layout: "/admin",
          sidebar: false
        }
      ];
    }
    
    if(id_role === 2) { 
      routes = [
        {
          path: "/",
          name: "Dashboard",
          icon: "fas fa-home text-blue",
          component: Index,
          layout: "/admin",
          sidebar: true
        },
        // {
        //   path: "/models",
        //   name: "Modelos",
        //   icon: "fas fa-tablet-alt text-primary",
        //   component: Models,
        //   layout: "/admin",
        //   sidebar: true
        // },
        // {
        //   path: "/spare_parts",
        //   name: "Repuestos",
        //   icon: "fas fa-boxes text-blue",
        //   component: SpareParts,
        //   layout: "/admin",
        //   sidebar: true
        // },
        {
          path: "/batches",
          name: "Lotes",
          icon: "fas fa-stream text-blue",
          component: Batches,
          layout: "/admin",
          sidebar: true
        },
        {
          path: "/devices",
          name: "Dispositivos",
          icon: "fas fa-columns text-blue",
          component: Devices,
          layout: "/admin",
          sidebar: true
        },
        // {
        //   path: "/prices_lists",
        //   name: "Lista de Precios",
        //   icon: "fas fa-list-ul text-blue",
        //   component: PricesList,
        //   layout: "/admin",
        //   sidebar: true
        // },
        {
          path: "/transfers",
          name: "Transferencias",
          icon: "fas fa-exchange-alt text-blue",
          component: ListTransactionsClass,
          layout: "/admin",
          sidebar: true
        },
        {
          path: "/new-transfer",
          name: "Nueva Transferencias",
          icon: "fas fa-exchange-alt text-blue",
          component: Transactions,
          layout: "/admin",
          sidebar: false
        },
        {
          path: "/inventory",
          name: "Inventario",
          icon: "fas fa-dolly-flatbed text-blue",
          component: ListInventary,
          layout: "/admin",
          sidebar: true
        },
        {
          path: "/new-inventory",
          name: "Inventario",
          icon: "fas fa-dolly-flatbed text-blue",
          component: Inventory,
          layout: "/admin",
          sidebar: false
        },
        {
          path: "/reports",
          name: "Reportes",
          icon: "fas fa-chart-pie text-blue",
          component: ReportsClass,
          layout: "/admin",
          sidebar: true
        },
      ]
    }

    if(id_role === 3) { 
        routes = [
          {
            path: "/",
            name: "Dashboard",
            icon: "fas fa-home text-blue",
            component: Index,
            layout: "/admin",
            sidebar: true
          },
          // {
          //   path: "/models",
          //   name: "Modelos",
          //   icon: "fas fa-tablet-alt text-primary",
          //   component: Models,
          //   layout: "/admin",
          //   sidebar: true
          // },
          
          // {
          //   path: "/spare_parts",
          //   name: "Repuestos",
          //   icon: "fas fa-boxes text-blue",
          //   component: SpareParts,
          //   layout: "/admin",
          //   sidebar: true
          // },
          {
            path: "/batches",
            name: "Lotes",
            icon: "fas fa-stream text-blue",
            component: Batches,
            layout: "/admin",
            sidebar: true
          },
          {
            path: "/transfers",
            name: "Transferencias",
            icon: "fas fa-exchange-alt text-blue",
            component: ListTransactionsClass,
            layout: "/admin",
            sidebar: true
          },
          {
            path: "/new-transfer",
            name: "Nueva Transferencias",
            icon: "fas fa-exchange-alt text-blue",
            component: Transactions,
            layout: "/admin",
            sidebar: false
          },
         
          {
            path: "/devices",
            name: "Dispositivos",
            icon: "fas fa-columns text-blue",
            component: Devices,
            layout: "/admin",
            sidebar: true
          },

          // {
          //   path: "/prices_lists",
          //   name: "Lista de Precios",
          //   icon: "fas fa-list-ul text-blue",
          //   component: PricesList,
          //   layout: "/admin",
          //   sidebar: true
          // }
        ]
    }

    return routes;
}
