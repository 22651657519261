import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { ProfileNavbar } from './ProfileNavbar.jsx';
import { Form, Navbar, Container
  // FormGroup, InputGroupAddon, InputGroupText, Input, InputGroup, 
} from "reactstrap";
import auth from "../../../api/auth.js";

class AdminNavbar extends React.Component {
  handlerLogout = () => {
    auth.removeUser();
    auth.removeToken();
  }
  
  render() {
    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <Link
              className="h3 mb-0 text-white text-uppercase d-none d-lg-inline-block"
              to={this.props.location.pathname}
            >
              {/* {this.props.brandText} */}
            </Link>
            <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
              {/* <FormGroup className="mb-0">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Search" type="text" />
                </InputGroup>
              </FormGroup> */}
            </Form>
            <ProfileNavbar user_lar={this.user_local} {...this.props}/>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default AdminNavbar;
